import React, { useState, useMemo } from 'react';
import c from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import NoContent from '@/components/public/NoContent';
import { NormalTitle, NoMore, SwitchBtn, BrokerItemB } from '@/reconstruction/components/public';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import type { RecommendPros } from '../../../types';
import styles from './index.module.less';

const Billboard: React.FC<RecommendPros> = ({ recommend = [] }) => {
    const sliceNum = 6;

    // swiper
    const [recommendSwiper, setRecommendSwiper] = useState<any>(undefined);
    // 当前显示页
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const spliceArr = (arr: any[], num: number) => {
        const newArr = [...arr]; // 因为splice会改变原数组，要深拷贝一下
        const list = [];
        for (let i = 0; i < newArr.length; ) {
            list.push(newArr.splice(i, num));
        }
        return list;
    };

    // 数据分成两页， 每页 sliceNum 条
    const recommendList = useMemo(() => {
        const cloneList = cloneDeep(recommend || []).map((item) => {
            const { age, license = [] } = item;
            if (age) {
                license.unshift(age);
            }
            return { ...item, license };
        });
        return spliceArr(cloneList, sliceNum).map((item, i) => ({ list: item, key: i }));
    }, [recommend, activeIndex]);

    // 点击切换
    const onClickBtn = (type: 'l' | 'r') => {
        switch (type) {
            case 'l':
                if (activeIndex === 0) return;
                setActiveIndex(0);
                recommendSwiper?.slideTo(0);
                break;
            case 'r':
                if (activeIndex === 1) return;
                setActiveIndex(1);
                recommendSwiper?.slideTo(1);
                break;
            default:
                break;
        }
    };

    // 自动切换
    const onSlideChange = (swiper: any) => {
        const { realIndex } = swiper;
        setActiveIndex(realIndex);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.recommend_top}>
                <NormalTitle title={intl.get('Broker.RecommendedBroker').d('推荐交易商')} />

                {(recommend || []).length > sliceNum && <SwitchBtn activeIndex={activeIndex} onClickBtn={onClickBtn} />}
            </div>

            <div className={styles.recommend_box}>
                {recommendList.length !== 0 ? (
                    <Swiper
                        observer
                        observeParents
                        resizeObserver
                        grabCursor
                        onSlideChange={onSlideChange}
                        onSwiper={setRecommendSwiper}
                        controller={{ control: recommendSwiper }}
                        className={c(styles.recommend_swiper)}
                        autoplay={{
                            delay: 5000,
                            pauseOnMouseEnter: true,
                            disableOnInteraction: false,
                        }}
                    >
                        {recommendList.map((item: any) => {
                            const { list, key } = item;
                            return (
                                <SwiperSlide key={key} className={styles.recommend_swiper_item}>
                                    <ul className={styles.recommend_list}>
                                        {list.map((item: any, i: number) => {
                                            const { id } = item;
                                            const len = list.length;

                                            return (
                                                <li
                                                    key={id}
                                                    className={c(styles.recommend_item, {
                                                        [styles.no_bottom]: i === len - 1 || i === len - 2,
                                                    })}
                                                >
                                                    <BrokerItemB size={58} record={item} />
                                                </li>
                                            );
                                        })}
                                    </ul>

                                    {(list || []).length !== 0 && (list || []).length < sliceNum - 1 && (
                                        <div className={styles.no_more}>
                                            <NoMore
                                                text={intl.get('Common.NoMoreRecommendations').d('没有更多推荐内容')}
                                            />
                                        </div>
                                    )}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                ) : (
                    <div className={styles.null_data}>
                        <NoContent />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Billboard;
