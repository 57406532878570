import TransferCom from '@/components/public/TransferCom';
import MBrokerItem from './m';
import PCBrokerItem from './pc';
import type { BrokerItemProps } from './type';

const BrokerItem: React.FC<BrokerItemProps> = (props) => {
    return (
        <TransferCom
            m={<MBrokerItem {...props} />}
            ipad={<PCBrokerItem {...props} />}
            pc={<PCBrokerItem {...props} />}
        />
    );
};

export default BrokerItem;
