import React from 'react';
import { NextImage, Scrollbar } from '@/components';
import SwiperCore, { Pagination, Autoplay, Controller, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatMts } from '@/utils/mts';
import { currentLocaleUrl, formatDomUnit } from '@/utils';
import c from 'classnames';
import styles from './index.module.less';
import 'swiper/swiper-bundle.css';
import type { SwiperProps } from '../../types';

SwiperCore.use([Pagination, Autoplay, Controller, Navigation]);

const SecondSwiper: React.FC<SwiperProps> = ({ elementId, interval = 5, customStyle, swiperList = [] }) => {
    const swiperItem = (item: any, l: any[], i: number) => {
        return (
            <div className={styles.swiperSlideBox} title={item?.title || ''}>
                <NextImage
                    className={styles.swiperSlideImg}
                    src={item?.url}
                    layout="fill"
                    alt={item?.title}
                    priority={i === 0}
                />
                {item.title ? (
                    <div className={c(styles.swiperSlideTitle, l.length < 2 ? styles.swiperSlideTitleOne : null)}>
                        <Scrollbar
                            noScrollX
                            className={styles.recScroll}
                            trackYClassName={styles.recScrollTrackY}
                            thumbYClassName={styles.recScrollThumbY}
                        >
                            <div className={styles.swiperSlideTitleText}>{formatMts(item.titleMts) || item.title}</div>
                        </Scrollbar>
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <div className={styles.swiperCenter}>
            <div className={styles.swiperContain} style={{ ...formatDomUnit(customStyle || {}) }}>
                <div className={styles.swiperLeft}>
                    <Swiper
                        loop={swiperList[0].length > 1}
                        observer
                        resizeObserver
                        autoplay={{
                            delay: (interval || 5) * 1000,
                            disableOnInteraction: false,
                        }}
                        grabCursor
                        className={styles.swiperCon}
                        pagination={
                            swiperList[0].length < 2
                                ? false
                                : {
                                      clickable: true,
                                      bulletClass: styles.swiperBullet,
                                      bulletActiveClass: styles.swiperBulletActive,
                                  }
                        }
                        navigation={{
                            prevEl: `.swiper-0-${elementId} .swiper-button-prev`,
                            nextEl: `.swiper-0-${elementId} .swiper-button-next`,
                        }}
                    >
                        {swiperList[0].map((item: any, i: number) => {
                            return (
                                <SwiperSlide className={c(styles.swiperSlide, styles.typeOne)} key={item.key}>
                                    {item.openUrl ? (
                                        <a href={currentLocaleUrl(item.openUrl)} target="_blank">
                                            {swiperItem(item, swiperList[0], i)}
                                        </a>
                                    ) : (
                                        swiperItem(item, swiperList[0], i)
                                    )}
                                </SwiperSlide>
                            );
                        })}

                        <div style={{ opacity: swiperList[0].length > 1 ? 1 : 0 }} className={`swiper-0-${elementId}`}>
                            <div className={c(styles.swiperBtnNext, 'swiper-button-next')} />
                            <div className={c(styles.swiperBtnPrev, 'swiper-button-prev')} />
                        </div>
                    </Swiper>
                </div>

                <ul className={styles.swiperRight}>
                    {swiperList[1].map((item: any) => {
                        const { key, list: sList } = item || {};
                        return (
                            <li key={key}>
                                <Swiper
                                    loop={sList.length > 1}
                                    observer
                                    resizeObserver
                                    autoplay={{
                                        delay: (interval || 3) * 1000,
                                        disableOnInteraction: false,
                                    }}
                                    grabCursor
                                    className={styles.swiperCon}
                                >
                                    {sList.map((sItem: any, i: number) => {
                                        return (
                                            <SwiperSlide
                                                className={c(styles.swiperSlide, styles.typeOne)}
                                                key={sItem.key}
                                            >
                                                {sItem.openUrl ? (
                                                    <a href={currentLocaleUrl(sItem.openUrl)} target="_blank">
                                                        {swiperItem(sItem, sList, i)}
                                                    </a>
                                                ) : (
                                                    swiperItem(sItem, sList, i)
                                                )}
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default SecondSwiper;
