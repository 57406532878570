import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PcMActivity from './pc';
import MActivity from './m';
import type { ActivityProps } from '../../type';

const Normal: React.FC<ActivityProps> = (props) => {
    return (
        <TransferCom pc={<PcMActivity {...props} />} m={<MActivity {...props} />} ipad={<PcMActivity {...props} />} />
    );
};

export default Normal;
