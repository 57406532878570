import React, { useMemo, useState } from 'react';
import { NextImage } from '@/components';
import SwiperCore, { Pagination, Autoplay, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatMts } from '@/utils/mts';
import 'swiper/swiper-bundle.css';
import c from 'classnames';
import styles from './index.module.less';
import { currentLocaleUrl, formatDomUnit } from '@/utils';

import type { SwiperProps } from '../../types';
import { cloneDeep } from 'lodash';

SwiperCore.use([Pagination, Autoplay, Controller]);

const ThirdSwiper: React.FC<SwiperProps> = ({ interval = 5, customStyle, swiperList: swList = [] }) => {
    const { width, widthUnit, height, heightUnit, ...restProps } = customStyle || {};

    const swiperList = useMemo(() => {
        if ((swList || []).length === 1) {
            const cloneList = cloneDeep(swList || []);
            const item = cloneList[0];
            return [
                { ...item, key: 1 },
                { ...item, key: 2 },
            ];
        }
        return swList;
    }, [swList]);

    return (
        <div className={styles.swiperCenter} style={formatDomUnit(restProps)}>
            <div
                className={styles.swiperContain}
                style={{
                    width: `${width}${widthUnit}`,
                    height: `${height}${heightUnit}`,
                }}
            >
                <Swiper
                    loop
                    loopedSlides={3}
                    observer
                    resizeObserver
                    observeParents
                    observeSlideChildren
                    autoplay={{
                        delay: (interval || 5) * 1000,
                        disableOnInteraction: false,
                    }}
                    grabCursor
                    className={styles.swiperCon}
                    centeredSlides
                    spaceBetween={16}
                    slidesPerView={1.225}
                    pagination={
                        swList.length < 2
                            ? false
                            : {
                                  clickable: true,
                                  bulletClass: styles.swiperBullet,
                                  bulletActiveClass: styles.swiperBulletActive,
                              }
                    }
                >
                    {swiperList.map((item: any) => {
                        const swiperItem = (
                            <div className={styles.swiperSlideBox}>
                                <NextImage src={item?.url} layout="fill" alt={item?.title} />
                                {item.title ? (
                                    <div className={styles.swiperSlideTitle} title={item?.title || ''}>
                                        <div
                                            className={c(
                                                styles.swiperSlideTitleText,
                                                swList.length < 2 ? styles.swiperSlideTitleOne : null,
                                            )}
                                        >
                                            {formatMts(item.titleMts) || item.title}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        );
                        return (
                            <SwiperSlide className={c(styles.swiperSlide, styles.typeOne)} key={item.key}>
                                {item.openUrl ? (
                                    <a href={currentLocaleUrl(item.openUrl)} target="_blank">
                                        {swiperItem}
                                    </a>
                                ) : (
                                    swiperItem
                                )}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
};

export default ThirdSwiper;
