import React, { useCallback, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import c from 'classnames';
import { message } from 'antd';
import { cloneDeep } from 'lodash';

import { formatDomUnit } from '@/utils';
import { NextImage } from '@/components';
import { focusAddPost, focusDelete } from '@/services/focus';
import type { UgcListType } from '@/type/ugc';

import styles from './index.module.less';
import { VisualUgcProps } from '../../../type';

const MUgc: React.FC<VisualUgcProps> = (props) => {
    const { customStyle, serviceData } = props || {};

    const [list, setList] = useState<UgcListType[]>(serviceData?.list || []);

    /** 防止切换页面后，数据变化不及时 */
    useEffect(() => {
        setList(serviceData?.list || []);
    }, [serviceData?.list]);

    /**
     * 关注/取消关注
     */
    const onFocusUgc = useCallback(
        (index: number) => {
            const { id, is_focus } = list[index] || {};
            if (id) {
                const fun = is_focus === 1 ? focusDelete : focusAddPost;
                fun(id).then((res: any) => {
                    if (res?.state === 1) {
                        message.success(
                            is_focus === 1
                                ? intl.get('PersonalCenter.UnfollowSuc').d('取消关注成功！')
                                : intl.get('Article.FollowedSuc').d('关注成功！'),
                        );

                        const newList = cloneDeep(list);
                        newList[index].is_focus = is_focus === 1 ? 0 : 1;
                        setList(newList);
                    }
                });
            }
        },
        [list],
    );

    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            {/* 列表 */}
            <ul className={styles.ugc}>
                {list.map((item, index) => {
                    const { id, avatar_url, name, introduction, is_focus } = item || {};
                    if (!id) {
                        return null;
                    }
                    return (
                        <li className={styles.ugc__item} key={id}>
                            <div className={styles.avatar}>
                                <NextImage layout="fill" src={avatar_url || ''} place="ugc" />
                            </div>
                            <span className={styles.name}>{name || '-'}</span>
                            <p className={styles.intro}>{introduction || '-'}</p>
                            <div
                                className={c(styles.button, is_focus === 1 ? styles.focus : '')}
                                onClick={() => onFocusUgc(index)}
                            >
                                {is_focus === 1 ? (
                                    intl.get('Article.AlreadyFollowed').d('已关注')
                                ) : (
                                    <React.Fragment>
                                        <span className={c('iconfont', 'icon-jiahao')} />
                                        <span>{intl.get('Article.Followed').d('关注')}</span>
                                    </React.Fragment>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default MUgc;
