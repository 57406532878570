import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PCSwiper from './pc';
import MSwiper from './m';
import { SwiperProps } from './type';

const Swiper: React.FC<SwiperProps> = (props) => {
    return <TransferCom m={<MSwiper {...props} />} pc={<PCSwiper {...props} />} ipad={<PCSwiper {...props} />} />;
};

export default Swiper;
