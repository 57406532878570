import React from 'react';

import { formatDomUnit } from '@/utils';
import { useAppSelector } from '@/store/hook';

import styles from './index.module.less';
import { CalendarList, CalendarWatch } from './components';
import { CalendarItem } from './type';
import { CalendarContext } from './context';
import useGetTime from './hook/useGetTime';

interface Props {
    /** 组件样式 */
    customStyle?: any;

    /** 服务端数据 */
    serviceData: {
        /** 列表数据 */
        list: CalendarItem[];
        /** 多语言 */
        locale: string;
    };
}

const PCCalendar: React.FC<Props> = ({ customStyle, serviceData }) => {
    const { currentMon, currentData, setCurrentData, activityList, switchMon, list } = useGetTime(serviceData);

    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);

    return (
        <CalendarContext.Provider
            value={{
                currentMon,
                currentData,
                setCurrentData,
                list,
                activityList,
                switchMon,
            }}
        >
            <div style={{ top: topHeight, ...formatDomUnit(customStyle || {}) }} className={styles.calendar}>
                <CalendarWatch />
                <CalendarList />
            </div>
        </CalendarContext.Provider>
    );
};

export default PCCalendar;
