import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, Input } from 'antd';
import { NextImage } from '@/components';
import InfiniteScroller from 'react-infinite-scroller';
import { getBrokerList, getBrokerTopOrRecList } from '@/services/broker';
import { useAppSelector, useAppDispatch } from '@/store/hook';
import { setCanScroll } from '@/store/config';
import debounce from 'lodash/debounce';
import c from 'classnames';
import { formatBrokerBusiness } from '@/utils/mts';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    open: boolean;
    placeholder?: string;
    index: number;
    dataList: any[];
    onClose?: () => void;
    onChange?: (a: any, b: number) => void;
}

const BrokerMenu: React.FC<Props> = (props) => {
    const locale = intl.getInitOptions().currentLocale;

    const { placeholder, open, index, dataList = [], onClose, onChange } = props;
    const listRef = useRef<any>(null);
    const [form] = Form.useForm();
    const [searchVal, setSearchValue] = useState<string>('');
    // 交易商下拉选择
    const [brokerList, setBrokerList] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const { current: page } = useRef({ pageSize: 20, total: 0, current: 1, loading: false, scrollTop: 0 });
    const dispatch = useAppDispatch();
    const { topHeaderHeight } = useAppSelector((state: any) => state.config);

    // 自定义搜索
    const onSearchChange = debounce((e: any) => {
        const { value } = e?.target || {};
        setSearchValue(value);
        if (value) {
            getBrokerFun(1, value);
        } else {
            getRecommendList();
        }
    }, 200);

    const onClearInput = () => {
        onSearchChange('');
        form?.setFieldsValue({ selVal: '' });
    };

    // 数据改变
    const onValueChange = (v: any) => {
        if (v) {
            setSearchValue('');
            form?.setFieldsValue({ selVal: '' });
            onChange?.(v, index);
            onClose?.();
        }
    };

    const getRecommendList = useCallback(() => {
        getBrokerTopOrRecList({
            state: 1,
            is_top_or_recommend: '1',
        }).then((res: any) => {
            const { state, data } = res || {};
            if (state === 1) {
                const { list = [] } = data || {};
                const newList = (list || [])
                    .map((item: any) => {
                        return { ...item, value: item?.id, label: item?.name_abbreviation };
                    })
                    .slice(0, 10);
                setBrokerList(newList);
                page.total = newList.length;
                page.current = 1;
                setHasMore(false);
            }
        });
    }, [page]);

    // 获取交易商列表
    const getBrokerFun = useCallback(
        (current: number, shName?: string) => {
            page.loading = true;

            const item = (dataList || []).find((item: any) => {
                return item.id;
            });
            const { openInfo } = item || {};

            getBrokerList({
                page: current,
                pageSize: page.pageSize,
                type: openInfo?.broker.type,
                full_name: shName,
            })
                .then((res: any) => {
                    const { state, data } = res || {};
                    if (state === 1) {
                        const { list = [], total } = data || {};
                        if (current === 1) {
                            setBrokerList(
                                (list || []).map((item: any) => {
                                    return { ...item, value: item?.id, label: item?.name_abbreviation };
                                }),
                            );
                        } else {
                            setBrokerList((l: any[]) => {
                                return [...l, ...(list || [])].map((item: any) => {
                                    return { ...item, value: item?.id, label: item?.name_abbreviation };
                                });
                            });
                        }
                        setHasMore(Math.ceil(total / page.pageSize) > current);
                        page.total = total;
                        page.current = current;
                    }
                })
                .finally(() => {
                    page.loading = false;
                });
        },
        [dataList, page],
    );

    useEffect(() => {
        getRecommendList();
    }, [getRecommendList, locale]);

    // 为空时
    const notFoundContent = (
        <div className={styles.nullData}>
            <NextImage src="/img/place/null_data.webp" width={200} height={160} alt="empty" />
            <p className={styles.nullTxt}>{intl.get('Broker.NoDealerMsg').d('暂无交易商～')}</p>
        </div>
    );

    const closePageScroll = useCallback(() => {
        document.body.style.position = '';
        document.body.style.top = '';
        // 使 scrollTop 恢复原状
        if (document?.scrollingElement) {
            document.scrollingElement.scrollTop = page.scrollTop;
        }
        dispatch(setCanScroll(true));
    }, [dispatch, page.scrollTop]);

    useEffect(() => {
        if (open) {
            dispatch(setCanScroll(false));
            page.scrollTop = document?.scrollingElement?.scrollTop || 0;
            document.body.style.position = 'fixed';
            document.body.style.top = -page.scrollTop + 'px';
        } else {
            closePageScroll();
        }

        return () => {
            closePageScroll();
        };
    }, [closePageScroll, dispatch, open, page]);

    const loadMoreData = useCallback(() => {
        if (page.loading || !hasMore) {
            return;
        }
        getBrokerFun(page.current + 1, searchVal);
    }, [getBrokerFun, page, hasMore, searchVal]);

    return (
        <div
            ref={listRef}
            className={c(styles.brokerMenu, open ? styles.brokerMenuOpen : null)}
            style={{ top: open ? topHeaderHeight : '100vh', height: `calc(100vh - ${topHeaderHeight}px)` }}
        >
            <div className={styles.searchInputBox}>
                <Form form={form} className={styles.form}>
                    <Form.Item noStyle name="selVal">
                        <Input
                            placeholder={placeholder || intl.get('Broker.AddTrader').d('添加交易商')}
                            className={styles.searchInput}
                            onInput={onSearchChange}
                            suffix={
                                searchVal ? (
                                    <span
                                        onClick={onClearInput}
                                        className={c(styles.closeIcon, 'iconfont', 'icon-shanchu')}
                                    />
                                ) : null
                            }
                            prefix={<span className={c(styles.prefix, 'iconfont', 'icon-sousuo')} />}
                        />
                    </Form.Item>
                </Form>
            </div>

            <InfiniteScroller
                initialLoad={false}
                pageStart={1}
                loadMore={loadMoreData}
                hasMore={hasMore}
                useWindow={false}
                getScrollParent={() => listRef?.current}
            >
                <ul className={styles.brokerMenuList}>
                    {brokerList.length !== 0
                        ? brokerList.map((item: any) => {
                              const { logo, value, label, business_name } = item || {};

                              return (
                                  <li
                                      key={value}
                                      className={styles.selOption}
                                      onClick={() => {
                                          onValueChange(item);
                                      }}
                                  >
                                      <div className={c(styles.logo, 'logo')}>
                                          <NextImage
                                              src={logo}
                                              alt={business_name}
                                              place="logo_a"
                                              width={28}
                                              height={28}
                                          />
                                      </div>
                                      <span className={c(styles.text, 'text')}>{label}</span>
                                      <span className={styles.business__name}>
                                          {formatBrokerBusiness(business_name, locale)}
                                      </span>
                                  </li>
                              );
                          })
                        : notFoundContent}
                </ul>
            </InfiniteScroller>
        </div>
    );
};

export default BrokerMenu;
