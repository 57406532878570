// 获取跳转地址
export const getUrl = (item: any) => {
    const { type, web = {}, article, block, blocks, navPageID } = item || {};
    let url = '';
    switch (type) {
        case 'article':
            const { code, seo_url } = article || {};
            url = `/article/${seo_url || code}`;
            break;
        case 'web':
            url = web.url;
            break;
        case 'block':
            const { blockId: blockId } = block || {};
            url = `/block/${blockId}`;
            break;
        case 'blocks':
            const { blockIds } = blocks || {};
            url = `/block/${blockIds?.map((item: any) => item.block_id).join(',')}`;
            break;
        case 'page':
            url = `/page/${navPageID}`;
            break;
        default:
            break;
    }
    return url;
};
