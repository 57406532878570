import React from 'react';
import { formatDomUnit } from '@/utils';
import NavTabItem from '@/components/public/Tabs/NavTabItem';
import { Tabs, InfiniteScroll } from '@/components';
import FlashNewsList from '../FlashNews/list';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    navs: any[];
    customStyle: any;
    activeTab?: number;
    serviceData?: {
        list: any[];
        hasMore: boolean;
    };
}

const NewsFlash: React.FC<Props> = ({ customStyle = {}, navs = [], activeTab = 0, serviceData }) => {
    const ids = navs[activeTab]?.blockid.join(',');
    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            <div className={styles.flashNewsTit}>
                <span>7*24</span>
                <span>{intl.get('Search.Tab2').d('快讯')}</span>
            </div>
            <Tabs
                isLink
                isFixed
                list={navs}
                defaultActiveTab={activeTab}
                itemRender={(data, activeTab) => <NavTabItem data={data} activeTab={activeTab} />}
            />
            <div className={styles.tabsCon}>
                <InfiniteScroll
                    initialLoad={false}
                    serviceData={serviceData}
                    api="/japi/article/quick/list"
                    extraParams={{ ids }}
                    renderList={(list) => <FlashNewsList options={list} />}
                />
            </div>
        </div>
    );
};

export default NewsFlash;
