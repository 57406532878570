import React, { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import { cloneDeep } from 'lodash';
import c from 'classnames';
import intl from 'react-intl-universal';

import { NextImage } from '@/components';
import type { UgcListType } from '@/type/ugc';
import { focusAddPost, focusDelete } from '@/services/focus';
import { currentLocaleUrl } from '@/utils';

import styles from './index.module.less';

interface RenderItemProps {
    list: UgcListType[];
    onUnfollowModal: ({
        callbackFn,
        title,
        index,
    }: {
        callbackFn: (index: number) => void;
        title: string;
        index: number;
    }) => void;
}

const RenderItem = (props: RenderItemProps) => {
    const { onUnfollowModal } = props || {};
    const [list, setList] = useState<UgcListType[]>(props.list || []);

    /** 防止切换页面后，数据变化不及时 */
    useEffect(() => {
        setList(props.list);
    }, [props.list]);

    const focusReqHandler = useCallback(
        (index: number) => {
            const { id, is_focus } = list[index] || {};
            if (id) {
                const fun = is_focus === 1 ? focusDelete : focusAddPost;
                fun(id).then((res: any) => {
                    if (res?.state === 1) {
                        message.success(
                            is_focus === 1
                                ? intl.get('PersonalCenter.UnfollowSuc').d('取消关注成功！')
                                : intl.get('Article.FollowedSuc').d('关注成功！'),
                        );

                        const newList = cloneDeep(list);
                        newList[index].is_focus = is_focus === 1 ? 0 : 1;
                        setList(newList);
                    }
                });
            }
        },
        [list],
    );

    /**
     * 关注/取消关注
     */
    const onFocusUgc = useCallback(
        (index: number) => {
            const { is_focus, name } = list[index] || {};
            if (is_focus === 1) {
                onUnfollowModal({
                    callbackFn: focusReqHandler,
                    title: intl.get('Ugc.UnfollowModelTitle', { name }).d(`确定要取消对${name}的关注吗？`),
                    index,
                });

                return;
            }
            focusReqHandler(index);
        },
        [list],
    );

    return (
        <div className={styles.list__item}>
            {list.map((item, index) => {
                const { avatar_url, name, introduction, article_list, id, is_focus } = item;
                return (
                    <div key={id} className={styles.item__wrapper}>
                        <a href={currentLocaleUrl(`/ugc/${id}`)} target="_blank" className={styles.header}>
                            <div className={styles.avatar}>
                                <NextImage src={avatar_url} layout="fill" objectFit="cover" place="ugc" />
                            </div>
                            <div className={styles.info}>
                                <div className={styles.name__wrapper}>
                                    <div className={styles.name}>{name}</div>
                                    <div
                                        className={styles.focus}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onFocusUgc(index);
                                        }}
                                    >
                                        <NextImage
                                            src={`/icon/${is_focus ? 'focus' : 'unfollow'}.webp`}
                                            layout="fill"
                                            objectFit="contain"
                                            needPrefix
                                        />
                                    </div>
                                </div>
                                {introduction && <div className={styles.intro}>{introduction}</div>}
                            </div>
                        </a>
                        <div className={styles.article__list}>
                            {article_list.map((article) => {
                                const { title, seo_url, code = '' } = article;
                                return (
                                    <a
                                        key={code}
                                        href={currentLocaleUrl(`/article/${seo_url || code}`)}
                                        target="_blank"
                                        className={styles.article__item}
                                    >
                                        <span className={c('iconfont', 'icon-row', styles.icon)} />
                                        <p>{title}</p>
                                    </a>
                                );
                            })}
                            {article_list.length < 3 && (
                                <div className={styles.noMore}>
                                    <span>{intl.get('Ugc.NoMore').d('没有更多推荐内容')}</span>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default RenderItem;
