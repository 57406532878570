import React, { useRef } from 'react';

import { InfiniteScroll } from '@/reconstruction/components/public';
import type { UgcListType } from '@/type/ugc';

import styles from './index.module.less';
import { VisualUgcProps } from '../../../type';
import RenderItem from './RenderItem';

const MUgc: React.FC<VisualUgcProps> = (props) => {
    const { serviceData } = props || {};
    const InfiniteScrolleRef = useRef<any>(null);

    return (
        <InfiniteScroll
            serviceData={serviceData}
            onRef={InfiniteScrolleRef}
            initialLoad={false}
            api="/japi/topic/recommended_ugc_list"
            renderList={(list) => {
                return (
                    <div className={styles.wrapper}>
                        {list.map((item) => {
                            return <RenderItem key={item.id} dataSource={item} />;
                        })}
                    </div>
                );
            }}
        />
    );
};

export default MUgc;
