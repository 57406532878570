import React from 'react'
import { formatDomUnit } from '@/utils'
import styles from './index.module.less'

interface PageProp {
    customStyle?: any
    render?: any
}

const Page: React.FC<PageProp> = (parmas) => {
    const { customStyle, render } = parmas
    return (
        <div
            className={styles.page}
            style={{ ...(formatDomUnit(customStyle || {})) }}
        >
            {render?.()}
        </div>
    )
}

export default Page
