import { createContext } from 'react';

interface ActivityContentProps {
    currentStatus: string[];
    currentCity: string[];
    filterChangeHandler: (type: 'status' | 'city', id: 'all' | string) => void;
    cityListData: {
        label: string;
        value: string;
        mts: string;
        id: string;
    }[];
    cityOption: {
        value: string;
        label: string;
        id: string;
    }[];
    citySumList: {
        value: string;
        label: string;
        id: string;
    }[];
}

export const ActivityContent = createContext<ActivityContentProps>({
    /** 当前状态 */
    currentStatus: ['all'],
    /** 当前区域 */
    currentCity: ['all'],
    /** 筛选变化 */
    filterChangeHandler: () => {},
    /** 热门城市数据 */
    cityListData: [],
    /** 下拉框数据 */
    cityOption: [],
    /** 城市总数据 */
    citySumList: [],
});
