import React from 'react';

import { formatDomUnit } from '@/utils';

import styles from './index.module.less';
import type { ActivityProps } from '../../../type';
import { HeaderFilter, ActivityList } from './components';
import useGetFilter from './hook/useGetFilter';
import { ActivityContent } from './content';

const PcAll: React.FC<ActivityProps> = ({ customStyle, serviceData }) => {
    const {
        currentStatus,
        currentCity,
        filterChangeHandler,
        delFilterHandler,
        clearFilterHandler,
        cityListData,
        cityOption,
        citySumList,
    } = useGetFilter(serviceData?.city);

    return (
        <ActivityContent.Provider
            value={{
                currentStatus,
                currentCity,
                filterChangeHandler,
                delFilterHandler,
                clearFilterHandler,
                cityListData,
                cityOption,
                citySumList,
            }}
        >
            <div style={{ ...formatDomUnit(customStyle || {}) }}>
                <div className={styles.activity}>
                    {/* 筛选 */}
                    <HeaderFilter />
                    {/* 列表 */}
                    <ActivityList serviceData={serviceData?.list} />
                </div>
            </div>
        </ActivityContent.Provider>
    );
};

export default PcAll;
