import React, { useMemo } from 'react';
import { StarLv } from '@/components';
import { currentLocaleUrl, getFileType } from '@/utils';
import ContactList from '../../ContactList';
import c from 'classnames';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import type { ColumnItemProps } from '../type';

const ColumnItem: React.FC<ColumnItemProps> = ({ prop, label, last, partId, list = [] }) => {
    const formData = (keys: string[], record: any) => {
        let value: any = record;
        let key: string = '';
        let preValue = record;
        keys.forEach((k: string) => {
            key = k;
            preValue = value;
            value = value ? value[k] : '';
        });
        return { key, value, preValue };
    };

    const MoreItem = (val: any, key: any, record: any, prop: string) => {
        const remark = (record || {})[`${key}_${prop}`] || '';
        return (val ? `${val} ${remark}` : remark) || '—';
    };

    const CheckItem = (val: any) => {
        return val || val === 0 ? (
            <span className={c(styles.icon, 'iconfont', `icon-${val === 1 ? 'tick' : 'fork'}`)} />
        ) : (
            '—'
        );
    };

    const ListItem = (val: any[]) => {
        return (val || []).length !== 0 ? (val || []).join('、') : '—';
    };

    const toolList = useMemo(() => {
        return [
            { label: 'MT4', value: '0' },
            { label: 'MT5', value: '1' },
            { label: 'TS2.0', value: '3' },
            { label: intl.get('Broker.WebTransactions').d('网页交易'), value: '4' },
            { label: intl.get('Broker.MobilePhoneTrading').d('手机交易'), value: '5' },
            { label: 'GTS', value: '2' },
            { label: intl.get('Broker.Others').d('其他'), value: '6' },
        ];
    }, []);

    // 跳转到pdf
    const onGotoPdf = (href: string) => {
        window.open(href);
    };

    const formatImgUrl = (item: any, prop: string, brokerId: any) => {
        const { flag } = item || {};
        const type = getFileType(flag);
        if (type !== 'pdf') {
            return `/broker/${brokerId}/${prop}_${0}`;
        } else {
            return item?.flag || '';
        }
    };

    // 资料信息
    const formatFileItem = (item: any, prop: string, brokerId: any) => {
        const { type, url = [], third_url, manuscript_address } = item || {};
        switch (type) {
            case 0:
                if (!manuscript_address) {
                    return {};
                }
                return { ...item, type, url: manuscript_address?.flag ? `/article/${manuscript_address?.flag}` : '' };
            case 1:
                if (!third_url) {
                    return {};
                }
                return { ...item, type, url: third_url?.flag || '' };
            case 2:
                const urlItem = (url || [])[0] || {};
                if ((url || []).length === 0) {
                    return {};
                }
                return { ...item, type, url: formatImgUrl(urlItem, prop, brokerId) };
            default:
                return {};
        }
    };

    const formatRender = (prop: string, record: any) => {
        const keyList = (prop || '').split('.');
        const { key, value, preValue } = formData(keyList, record);
        const { _id, broker_seo } = record || {};
        const { url: seoUrl } = broker_seo || {};

        switch (partId) {
            case 5:
            case 6:
                return MoreItem(value, key, preValue, 'desc');
            default:
                break;
        }

        switch (key) {
            case 'contact_way': // 联系方式
                return (value || []).length !== 0 ? <ContactList list={value || []} /> : '—';
            case 'company_honor': // 公司荣誉
            case 'company_scene': // 公司实景
                const prop = (key || '').split('_')[1] || '';
                const fItem = formatFileItem((value || [])[0] || {}, prop, seoUrl || _id);
                const { type: fType, url } = fItem || {};
                if (url) {
                    if (fType === 2) {
                        return (
                            <span
                                className={styles.hrefHover}
                                onClick={() => {
                                    onGotoPdf(url);
                                }}
                            >
                                {intl.get('Broker.ClickDetails').d('【点击查看详情】')}
                            </span>
                        );
                    } else {
                        return (
                            <a className={styles.hrefHover} href={currentLocaleUrl(url)} target="_blank">
                                {intl.get('Broker.ClickDetails').d('【点击查看详情】')}
                            </a>
                        );
                    }
                } else {
                    return '—';
                }

            case 'rate':
                return value || value === 0 ? (
                    <StarLv
                        grade={Number(value || 0)}
                        wrapClassname={styles.starList}
                        itemClassname={styles.starItem}
                    />
                ) : (
                    '—'
                );

            case 'compensatory_balance': // 最低存款
            case 'maximum_lever': // 最大杠杆
            case 'minimum_position': // 最小头寸
            case 'warehouse_blowout': // 爆仓比例
                return MoreItem(value, key, preValue, 'remark');

            case 'state': // 锁仓
            case 'ea': // EA交易
            case 'scalp': // 超短线
            case 'micro_account': // 微型账户
            case 'mock_account': // 模拟账户
            case 'swap': // 换汇
            case 'hk_warrant': // 港股股认购
            case 'us_warrant': // 美股股认购
            case 'leverage': // 杠杆
            case 'is_listed': // 是否上市
                return CheckItem(value);

            case 'order_type': // 订单执行类型
            case 'account_type': // 账户类型
            case 'investment_market': // 投资市场
            case 'investment_type': // 投资种类
            case 'open_account_method': // 开户门槛及方法
            case 'hk_order_type': // 港股订单
            case 'us_order_type': // 美股订单
            case 'instrument_exchange': // 交易工具
                return ListItem(value);

            case 'trading_software': // 交易软件
                const softwareList: any = [];
                toolList.forEach((item: any) => {
                    const obj = (value || []).find((val: any) => {
                        return item.label === val;
                    });
                    if (obj || obj === 0) {
                        softwareList.push(item?.label);
                    }
                });
                return softwareList.length !== 0 ? softwareList.join('、') : '—';

            case 'supervisory_licence':
                const licenceList: any[] = (value || []).map((item: any, i: number) => {
                    return { ...item, key: i };
                });
                return licenceList.length !== 0 ? (
                    <div className={styles.textList}>
                        {licenceList.map((item: any) => {
                            return <p key={item?.key}> {item?.name || '—'} </p>;
                        })}
                    </div>
                ) : (
                    '—'
                );
            default:
                return value || '—';
        }
    };

    return (
        <ul className={c(styles.wrapper, last ? styles.lastWrapper : null)}>
            <li>{label}</li>
            {(list || []).map((item: any) => {
                return <li key={item.key}>{formatRender(prop, item)}</li>;
            })}
        </ul>
    );
};

export default ColumnItem;
