import React, { useState, useEffect } from 'react';
import { App } from 'antd';
import { useRouter } from 'next/router';
import { cloneDeep } from 'lodash';
import intl from 'react-intl-universal';
import { nanoid } from 'nanoid';
import BrokerContrast from '@/reconstruction/components/pages/broker/contrast';
import { getVisualPage } from '@/services/manage';
import { getBrokerDetail } from '@/services/broker';
import { formatContrastData } from '@/utils/broker';

interface Props {
    locale: string;
    /** 链接带的数据 ids */
    idsList: string[];
    /** banner 数据 */
    bannerData: any;
    /** 顶部选中的 三个交易商 基本信息 */
    brokerList: any[];
    /** 顶部选中的 三个交易商 数据 */
    dataList: any[];
    /** 一级导航 */
    navList: any[];
    /** 业务类型 */
    businessList: any[];
    /** 公共数据 */
    globalData: any;
    /** 页面权限 */
    auth: boolean;
}

const getNormalData = async (idsList: string[], locale: string) => {
    const funList = idsList.map((item: any) => item && getBrokerDetail(item, locale));
    const [brokerOne, brokerTwo, brokerThree] = await Promise.all(funList);
    const moreData = await formatContrastData({ brokerOne, brokerTwo, brokerThree }, locale);

    return moreData;
};

const Page: React.FC<Props> = ({ locale, idsList, brokerList, dataList, navList, bannerData: bData, businessList }) => {
    const router = useRouter();
    const { message } = App.useApp();

    /** 顶部选中的 三个交易商 基本信息 */
    const [selBrokerList, setSelBrokerList] = useState<any[]>(brokerList || []);

    /** 顶部选中的 三个交易商 数据 */
    const [dataSource, setDataSource] = useState<any[]>(dataList || []);

    /** 一级导航 */
    const [firstNavs, setFirstNavs] = useState<any[]>(navList || []);

    /** 顶部banner数据 */
    const [bannerData, setBannerData] = useState<any>(bData || {});

    useEffect(() => {
        setSelBrokerList(brokerList);
        setDataSource(dataList);
        setFirstNavs(navList);
        setBannerData(bData);
    }, [locale, idsList, brokerList, dataList, navList, bData]);

    /**
     * 去对比
     * @param l
     */
    const onToContrast = (l: any[] = []) => {
        const ids = (l || []).map((item: any) => item?._id || item?.id);
        const idstr = ids.join('.');

        const { pathname } = window?.location || {};

        router.replace(`${pathname}?ids=${encodeURIComponent(idstr)}`);
    };

    /**
     * 更换交易商
     * @param v
     * @param i
     * @returns
     */
    const onBrokerChange = (v: any, i: number) => {
        const { id, type } = v || {};
        const cloneList = cloneDeep(selBrokerList || []);
        const brokerList = cloneList.filter((item: any) => {
            return item._id === id;
        });
        const otherList = cloneList.filter((item: any) => {
            return item.type && item.type !== type;
        });

        if (brokerList.length !== 0) {
            message.warning(intl.get('Broker.BrokerLWarnMsg').d('该交易商已在对比列表'));
            return;
        }
        if (otherList.length !== 0) {
            message.warning(intl.get('Broker.OtherLWarnMsg').d('仅支持同种类型交易商进行对比'));
            return;
        }
        getBrokerDetail(id).then((res: any) => {
            const { state, data } = res || {};
            if (state === 1) {
                const cloneList = cloneDeep(selBrokerList || []);
                cloneList.splice(i, 1, { key: nanoid(), ...(data || {}) });
                setSelBrokerList(cloneList);

                setTimeout(() => {
                    // addHrefEventListener();
                    onToContrast(cloneList);
                }, 10);
            }
        });
    };

    const normalProps = {
        locale,
        contrastList: selBrokerList,
        dataSource,
        firstNavs,
        bannerData,
        businessList,

        onToContrast,
        onBrokerChange,
        onChangeBrokerList: setSelBrokerList,
    };

    return <BrokerContrast {...normalProps} />;
};

const getServerSideProps = async (params: any, globalData: any, navId: string, pageId: string, metaData: any) => {
    const { query = {}, locale } = params || {};
    const { ids } = query || {};
    const { baseConfig = {} } = globalData;
    const { showNewLayout = false } = baseConfig;

    const idsList = (ids || '').split('.');

    const columnRes = await getNormalData(idsList, locale);

    const visualPage = await getVisualPage(pageId, locale);

    if (visualPage?.state !== 1) {
        visualPage.data = {};
    }
    const { state: pageState } = visualPage.data;

    return {
        props: {
            metaData,
            navId,
            locale,
            idsList,
            globalData,
            showNewLayout,
            pageData: { auth: pageState === 1 },
            ...columnRes,
        },
    };
};

export default {
    Page,
    getServerSideProps,
};
