import React, { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'antd';
import { NextImage } from '@/components';
import { resizeObserverFun, getColSpan, currentLocaleUrl } from '@/utils';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import c from 'classnames';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    list: any[];
}

const LiveList: React.FC<Props> = ({ list = [] }) => {
    // public
    const router = useRouter();
    const { id: navId }: any = router.query || {};

    const domRef = useRef<any>(null);
    const [domWidth, setDomWidth] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(domRef.current, setDomWidth);
    }, []);

    const statusName = ['Live.NotStarted', 'Live.Live', 'Activity.Over'];
    const statusClassName = [styles.before, styles.progressing, styles.end];

    const getStatus = (item: any) => {
        const { start_time, end_time } = item || {};
        const nowTime = new Date().getTime();
        const startTime = new Date(start_time).getTime();
        const endTime = new Date(end_time).getTime();
        if (nowTime < startTime) {
            return 0;
        }
        if (nowTime > endTime) {
            return 2;
        }
        return 1;
    };

    return (
        <div ref={domRef} className={styles.list}>
            <Row gutter={24} className={styles.listRow}>
                {list.map((item: any) => {
                    const { id, title, cover_url, start_time } = item;
                    const status = getStatus(item);
                    return (
                        <Col key={id} span={getColSpan(domWidth, 4)} className={styles.listCol}>
                            <a href={currentLocaleUrl(`/live/${navId}/${id}`)} target="_blank">
                                <div className={styles.listItem}>
                                    <div className={styles.coverUrl}>
                                        <NextImage src={cover_url} layout="fill" alt={title} />
                                    </div>

                                    <div className={styles.listItemContent}>
                                        <div className={styles.title}>{item.title}</div>

                                        <div className={styles.botMsg}>
                                            <div className={styles.time}>
                                                {intl.get('Live.BroadcastTime').d('开播时间：')}
                                                {dayjs(start_time).format('MM.DD HH:mm')}
                                            </div>
                                            <div className={c(styles.btns, statusClassName[status])}>
                                                {intl.get(`${statusName[status]}`) || '-'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

export default LiveList;
