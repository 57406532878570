import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import { RowBoxProp } from './type';
import PCRowBox from './pc';
import MRowBox from './m';

const RowBox: React.FC<RowBoxProp> = (props) => {
    return <TransferCom pc={<PCRowBox {...props} />} m={<MRowBox {...props} />} ipad={null} />;
};

export default RowBox;
