import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MColumnItem from './m';
import PcColumnItem from './pc';
import type { ColumnItemProps } from './type';

const ColumnItem: React.FC<ColumnItemProps> = (props) => {
    return (
        <TransferCom
            m={<MColumnItem {...props} />}
            ipad={<PcColumnItem {...props} />}
            pc={<PcColumnItem {...props} />}
        />
    );
};

export default ColumnItem;
