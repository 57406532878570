import BrokerList from '@/reconstruction/components/pages/broker/list';
import { getVisualPage } from '@/services/manage';
import { getBusinessList, getBillboardList } from '@/services/broker';
import { redirectFun } from '@/utils';

interface Props {
    locale: string;
    businessList: any[];
    /** 服务端数据 */
    serviceData: {
        list: any[];
        hasMore: boolean;
    };
    globalData: any;
    /** 页面权限 */
    auth: boolean;
}

const Page: React.FC<Props> = (props) => {
    return <BrokerList {...props} />;
};

const getServerSideProps = async (params: any, globalData: any, navId: string, pageId: string, metaData: any) => {
    const { locale } = params || {};
    const { baseConfig = {} } = globalData;
    const { showNewLayout = false } = baseConfig;
    const { pageSize }: any = process.env.NEXT_PUBLIC_SETTING;

    const businessRes = (await getBusinessList(locale)) || {}; // 获取业务
    const { data, state } = businessRes || {};
    if (Number(state) !== 1) {
        return redirectFun(businessRes);
    }
    const businessList = data || [];

    // 获取【交易商榜单页面】数据
    const visualPage = await getVisualPage(pageId, locale);

    if (visualPage?.state !== 1) {
        visualPage.data = {};
    }
    const { state: pageState } = visualPage.data;

    const serviceData = await getBillboardList({ page: 1, pageSize, page_size: pageSize }, locale);
    if (serviceData?.state !== 1) {
        serviceData.data = [];
    }
    const { total, list = [] } = serviceData?.data || {};

    return {
        props: {
            metaData,
            navId,
            showNewLayout,
            locale,
            businessList,
            serviceData: { list: list || [], hasMore: total > pageSize },
            pageData: { auth: pageState === 1 },
        },
    };
};

export default {
    Page,
    getServerSideProps,
};
