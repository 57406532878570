import React, { useState, useEffect, useMemo, useRef } from 'react';
import FlashList from '../FlashList';
import { InfiniteScroll, Tabs } from '@/components';
import NavTabItem from '@/components/public/Tabs/NavTabItem';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    ids?: string;
    options: any[];
    elementId: string;
    serviceData: {
        list: any[];
        hasMore: boolean;
    };
}

const FlashPage: React.FC<Props> = ({ ids, elementId, options = [], serviceData }) => {
    const InfiniteScrolleRef = useRef<any>(null);
    const [activeItem, setActiveItem] = useState<any>({});
    const [isInit, setIsInit] = useState<boolean>(true);

    const arr = useMemo(() => {
        return [...options].map((item: any, index: number) => {
            const { id, title } = item;
            if (title === '全部' || id instanceof Array) {
                return { ...item, value: (id || []).join(','), id: 'all', index };
            }

            const nid = (item?.id || '').toString();
            return { ...item, value: nid, id: nid, index };
        });
    }, [options]);

    const isArrEqual = (arr1: any[], arr2: any[]) => {
        return arr1.length === arr2.length && arr1.every((ele: any) => arr2.includes(ele));
    };

    useEffect(() => {
        const idList = (ids || '').split(',') || [];
        const opIds = arr.filter((e: any) => e.id !== 'all').map((e: any) => e.id);
        if (ids) {
            if (isArrEqual(idList, opIds)) {
                setActiveItem(arr[0]);
            } else {
                const id = idList[0];
                const obj = arr.find((e: any) => e.id === id);

                if (obj) {
                    setActiveItem(obj);
                } else {
                    setActiveItem(arr[0]);
                }
            }
        } else {
            setActiveItem(arr[0]);
        }
    }, [ids, arr]);

    const onTabChange = (key: number, data: any) => {
        setActiveItem(data);
        setIsInit(false);
    };

    useEffect(() => {
        if (activeItem?.id && !isInit) {
            InfiniteScrolleRef.current?.resetList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeItem?.id, isInit]);

    return (
        <div className={styles.flashNewsBox}>
            <div className={styles.flashNewsTit}>
                <span>7*24</span>
                <span>{intl.get('Home.NewsFlash').d('快讯')}</span>
            </div>

            <Tabs
                wrapperClass={styles.tabs}
                translateY={35}
                elementId={elementId}
                isFixed
                list={arr}
                defaultActiveTab={activeItem?.index}
                onTabChange={onTabChange}
                itemRender={(data) => <NavTabItem data={data} activeTab={activeItem.index} />}
            />
            <InfiniteScroll
                onRef={InfiniteScrolleRef}
                initialLoad={false}
                serviceData={serviceData}
                api="/japi/article/quick/list"
                extraParams={{ ids: activeItem?.value }}
                renderList={(list) => <FlashList options={list} />}
            />
        </div>
    );
};

export default FlashPage;
