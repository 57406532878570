import React, { useState } from 'react';
import { Typography } from 'antd';
import c from 'classnames';
import intl from 'react-intl-universal';
import styles from './index.module.less';

const { Paragraph } = Typography;

interface Props {
    title: string;
    content: string;
    style?: any;
}

const ExpanDesc: React.FC<Props> = ({ title, content, style }) => {
    const [expansion, setExpansion] = useState<boolean>(false);

    const onExpandPg = (e: any, v: boolean) => {
        e.stopPropagation && e.stopPropagation();
        setExpansion(v);
    };

    return content ? (
        <div className={c(styles.expansion, { [styles.ex_open]: expansion })} style={{ ...(style || {}) }}>
            <Paragraph
                className={styles.text}
                ellipsis={{
                    rows: 3,
                    expandable: true,
                    expanded: expansion,
                    symbol: (
                        <span className={styles.drop_btn}>
                            <span
                                className={styles.drop_btn_posi}
                                onClick={(e) => {
                                    onExpandPg?.(e, !expansion);
                                }}
                            >
                                <span>{intl.get('Broker.ExpandAll').d('展开全部')}</span>
                                <span className={styles.arrow_box}>
                                    <span className={c('iconfont', 'icon-jiantou', styles.arrow)} />
                                </span>
                            </span>
                        </span>
                    ),
                    onEllipsis: (ellipsis) => {
                        console.log('Ellipsis changed:', ellipsis);
                    },
                }}
            >
                {title}
                {content}
                {expansion && (
                    <span
                        className={c(styles.drop_btn, styles.drop_up_btn)}
                        onClick={(event: any) => {
                            event?.stopPropagation();
                            event?.nativeEvent.stopImmediatePropagation();

                            setExpansion(!expansion);
                        }}
                    >
                        <span>{intl.get('Broker.CollapseAll').d('收起全部')}</span>
                        <span className={styles.arrow_box}>
                            <span className={c('iconfont', 'icon-jiantou', styles.arrow)} />
                        </span>
                    </span>
                )}
            </Paragraph>
        </div>
    ) : null;
};

export default ExpanDesc;
