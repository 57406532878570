import React, { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'antd';
import { NextImage } from '@/components';
import { resizeObserverFun, getColSpan, currentLocaleUrl } from '@/utils';
import styles from './index.module.less';

interface Props {
    list: any[];
}

const Topic: React.FC<Props> = ({ list }) => {
    const domRef = useRef<any>(null);
    const [domWidth, setDomWidth] = useState<number>(0);
    useEffect(() => resizeObserverFun(domRef.current, setDomWidth), []);

    return (
        <div ref={domRef}>
            <Row gutter={24} className={styles.listRow}>
                {list.map((item) => (
                    <Col key={item._id} span={getColSpan(domWidth, 3)} className={styles.listCol}>
                        <a href={currentLocaleUrl(`/topic/${item.seo_info?.seo_url || item._id}`)} target="_blank">
                            <div className={styles.listItem}>
                                <NextImage src={item.cover?.url} layout="fill" alt={item.title} />
                                <div className={styles.listItemTitleBox}>
                                    <div className={styles.listItemTitle}>{item.title}</div>
                                </div>
                            </div>
                        </a>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Topic;
