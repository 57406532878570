import React, { useContext } from 'react';

import { useAppSelector } from '@/store/hook';
import { ActivityListItem } from '@/reconstruction/components/listItem';

import styles from './index.module.less';
import { CalendarContext } from '../../context';

const CalendarList = () => {
    const { activityList } = useContext(CalendarContext);
    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);

    return (
        <div className={styles.wrapper__list} style={{ maxHeight: `calc(100vh - ${topHeight}px - 480px - 32px)` }}>
            {activityList.map((item) => {
                return (
                    <ActivityListItem className={styles.list__item} layout="small" key={item.id} dataSource={item} />
                );
            })}
        </div>
    );
};

export default CalendarList;
