import React, { useMemo, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import c from 'classnames';

import { getSelectKeyItem, getNavId } from '@/utils/home';
import { distributeRequest } from '@/utils/pages/id';
import { redirectUrl, withGlobalData, formatNavSeoMts } from '@/utils';
import services from '@/services';
import * as PreviewElement from '@/components/visual';
import FlashPage from '@/components/home/FlashPage';
import AnalystIndex from '@/components/analyst/AnalystIndex';
import NewAnalystIndex from '@/reconstruction/components/pages/analyst/AnalystIndex';
import { systemPageConfig, systemPageList } from '@/reconstruction/config/page';
import { useAppDispatch } from '@/store/hook';
import { setActiveNavKeys } from '@/store/config';
import { Activity, AuthMask, FlashNews, HotNews, UgcRec } from '@/components';
import Broker from '@/components/public/Broker';

import styles from './index.module.less';

export interface Props {
    locale: string;
    pageData: any;
    jumpUrl: {
        activityPage?: string;
        flashNewsPage?: string;
    };
    flashNews: any[];
    flashNewsId: string;
    activitys: any[];
    hotList: any[];
    ugcs: any[];
    navId?: string;
    pageId?: string;
    showBroker: boolean;
    showNewLayout: boolean;
    showTopTW: boolean;
    showBarkly: boolean;
    showAdvertising: boolean;
    topTWCode?: string;
    leftAdvertisingCode?: string;
    rightAdvertisingCode?: string;
}

interface RenderProps {
    key: string;
    props: any;
    activeTab?: number;
    serviceData: {
        list: any[];
        hasMore: boolean;
    };
    flashServiceData: {
        list: any[];
        hasMore: boolean;
    };
    state?: number;
    elementId: string;
    parentKey: string;
    component: string;
    children: RenderProps[];
}

const Page = (props: Props) => {
    const {
        locale,
        pageData,
        flashNews = [],
        flashNewsId,
        jumpUrl,
        activitys = [],
        hotList = [],
        ugcs = [],
        showBroker = [],
        showNewLayout,
        showBarkly,
        navId = '',
        pageId = '',
    } = props;

    /** 系统创建页面 */
    if (systemPageList.includes(navId)) {
        const Client = systemPageConfig[navId].Client;
        return (
            <>
                <Client {...props} />
                <AuthMask auth={pageData?.auth} isPage />
            </>
        );
    }

    const dispatch = useAppDispatch();

    /** 展示页面手动增加当前页面路径 */
    useEffect(() => {
        if (pageId) {
            dispatch(setActiveNavKeys(['page', pageId]));
        }
    }, [pageId]);

    const router = useRouter();

    const { ids = '' }: any = router.query;

    // 右侧是否显示
    const showRightData = useMemo(() => {
        return (
            !!(flashNews.length || activitys.length || hotList.length || ugcs.length || showBroker) && !showNewLayout
        );
    }, [activitys, flashNews, hotList, ugcs, showBroker, showNewLayout]);

    const renderItem = useCallback((params: RenderProps) => {
        const {
            key,
            elementId,
            props,
            component,
            state,
            children = [],
            serviceData = {},
            flashServiceData = {},
            activeTab = 0,
            parentKey,
        } = params || {};
        const Element: any = PreviewElement;

        const CompElement: any = Element[component] || null;

        let containerType = '';
        // 判断容器类型
        switch (parentKey) {
            case 'RowBox_left': // 左右布局
            case 'RowBox_right': // 左右布局
                containerType = 'box';
        }

        const render =
            children && children.length !== 0
                ? { render: () => [...(children || [])].map((e: any) => renderItem({ ...e })) }
                : {};

        return CompElement ? (
            <CompElement
                key={key}
                elementKey={key}
                showRightData={showRightData}
                showNewLayout={showNewLayout}
                elementId={elementId}
                serviceData={serviceData}
                flashServiceData={flashServiceData}
                activeTab={activeTab}
                defaultActiveTab={activeTab}
                state={state}
                containerType={containerType}
                {...props}
                {...render}
            />
        ) : null;
    }, []);

    // 匹配组件
    const renderContent = (type: string) => {
        switch (type) {
            case 'pc_quick':
                return (
                    <FlashPage
                        elementId={navId}
                        options={pageData.menus}
                        serviceData={pageData.serviceData}
                        ids={ids}
                    />
                );
            case 'analysis':
                return showNewLayout ? (
                    <NewAnalystIndex
                        elementId={navId}
                        options={pageData.menus}
                        serviceData={pageData.serviceData}
                        detail={pageData.detail}
                        locale={locale}
                    />
                ) : (
                    <AnalystIndex
                        elementId={navId}
                        options={pageData.menus}
                        serviceData={pageData.serviceData}
                        detail={pageData.detail}
                        locale={locale}
                    />
                );
            default:
                return renderItem(pageData);
        }
    };

    return (
        <div className={styles.container}>
            <div className={c(styles.containerLf, { [styles.hidden]: !showNewLayout })}>{renderContent(navId)}</div>
            {showRightData && (
                <div className={styles.containerRf}>
                    {!!showBroker && <Broker />}
                    {!!flashNews.length && (
                        <FlashNews list={flashNews} ids={flashNewsId} morePage={jumpUrl.flashNewsPage} />
                    )}
                    {!!hotList.length && <HotNews list={hotList} />}
                    {!!activitys.length && <Activity list={activitys} morePage={jumpUrl.activityPage} />}
                    {!!ugcs.length && <UgcRec list={ugcs} />}
                </div>
            )}

            <AuthMask auth={pageData?.auth} isPage />
        </div>
    );
};

const redirectNotFund = (locale?: string) => ({
    redirect: {
        destination: redirectUrl('/404', locale),
        permanent: false,
    },
});

export const getServerSideProps = withGlobalData(async (params, globalData) => {
    const { menus, baseConfig = {} } = globalData;
    const { showNewLayout = false } = baseConfig;
    const { query, locale } = params || {};
    const auth = params?.req?.cookies?.AUTH_TOKEN;

    const { firstId, secondId, tabId, ids = '', pageId } = query || {}; // firstId = 一级导航id or 导航自定义url; secondId = 二级导航id or 导航自定义url; tabId = 标签页id or 标签页自定义url;  ids = 快讯合集id;  pageId = 可视化页面id;

    const firstNav = menus.find((item: any) => [item.inventedUrl, item.id].includes(firstId));

    // 不存在二级导航的情况下，导航id为secondId
    let tabSeo = tabId;
    if ((firstNav?.children || []).length === 0) {
        tabSeo = secondId;
    }

    const newId = getNavId(firstId, secondId, firstNav);

    let nav: any = {};

    if (newId) {
        // 菜单页 /[id]
        nav = showNewLayout
            ? getSelectKeyItem(menus || [], newId)
            : menus.find((item: any) => [item.inventedUrl, item.id].includes(newId));
    } else if (pageId) {
        nav = {
            navId: pageId,
            pageId: pageId,
        };
    } else {
        // 首页 /
        nav = menus[0];
        if (nav?.children && nav?.children.length !== 0 && showNewLayout) {
            nav = nav?.children[0];
        }
    }

    const {
        showActivity,
        showBroker = false,
        showHotList,
        showNewsFlash,
        showUgc,
        newsFlashBlocksIds,
        jumpUrl,
        navId,
        newsHotBlocksIds,
        showTopTW,
        showBarkly,
        showAdvertising,
        topTWCode,
        leftAdvertisingCode,
        rightAdvertisingCode,
        title = '',
        keywords = '',
        description = '',
        titleMts,
        keywordsMts,
        descriptionMts,
    } = nav || {};

    const metaData = {
        title: formatNavSeoMts(title, titleMts, locale),
        keywords: formatNavSeoMts(keywords, keywordsMts, locale),
        description: formatNavSeoMts(description, descriptionMts, locale),
    };

    /** 系统创建页面 */
    if (systemPageList.includes(navId)) {
        return systemPageConfig[navId].server?.(params, globalData, navId, nav?.pageId, metaData);
    }

    if (!navId) {
        return redirectNotFund(locale);
    }

    // 根据页面类型获取数据
    const [res, res2, res3, res4, res5] = await Promise.all([
        distributeRequest({ ids, nav, navId, tabId: tabSeo, auth, locale, metaData })?.() || null,
        showNewsFlash && services.japi.getFlashNews(`?page=1&pageSize=5&ids=${newsFlashBlocksIds}`, locale),
        showActivity && services.japi.getNewActivitys(locale),
        showHotList && services.japi.getHotArticle({ limitSize: 5, blockIds: newsHotBlocksIds.split(',') }, locale),
        showUgc && services.ugc.getUgcList({ page: 1, pageSize: 4 }, { locale }),
    ]);

    const {
        metaData: newMetaData,
        hasTabId,
        pageData,
    } = (res || {}) as {
        metaData?: {
            title?: string;
            keywords?: string;
            description?: string;
        };
        hasTabId: boolean;
        pageData: any;
    };

    // 修复容器组件问题，暂时注释，待观察
    if (tabSeo && !hasTabId && !showNewLayout) {
        return redirectNotFund(locale);
    }

    return {
        props: {
            locale,
            navId: navId || null,
            pageId: pageId || null,
            metaData: newMetaData || metaData,
            pageData, // 可视化组件
            jumpUrl: jumpUrl ? JSON.parse(jumpUrl) : {},
            flashNews: res2?.data?.list ? res2.data.list : [], // 右侧快讯
            flashNewsId: newsFlashBlocksIds || '', // 右侧快讯合集id
            activitys: res3?.data ? res3.data : [], // 右侧活动
            hotList: res4?.data ? res4.data : [], // 右侧热榜新闻
            ugcs: res5?.data?.list ? res5.data.list : [], // 右侧创作者
            showBroker,
            showNewLayout, // 是否展示新布局：showNewLayout
            showTopTW: showTopTW === 1, // 是否展示头部TW组件：showTopTW
            showBarkly: showBarkly === 1, // 是否展示暗黑模式
            showAdvertising: showAdvertising === 1, // 是否展示广告：showAdvertising
            topTWCode: topTWCode || null, // 头部TW组件代码：topTWCode
            leftAdvertisingCode: leftAdvertisingCode || null, // 左广告代码：leftAdvertisingCode
            rightAdvertisingCode: rightAdvertisingCode || null, // 右广告代码：rightAdvertisingCode
        },
    };
});

export default Page;
