import React from 'react';
import TransferCom from '@/components/public/TransferCom';

// import IpadAnalystIndex from './ipad';
import MAnalystIndex from './m';
import PcAnalystIndex from './pc';

interface IProps {
    options: any[];
    elementId: string;
    serviceData: {
        list: any[];
        hasMore: boolean;
    };
    detail: any;
    locale: string;
}
const AnalystIndex: React.FC<IProps> = (props) => {
    return (
        <TransferCom
            m={<MAnalystIndex {...props} />}
            ipad={<PcAnalystIndex {...props} />}
            pc={<PcAnalystIndex {...props} />}
        />
    );
};

export default AnalystIndex;
