import React from 'react';
import intl from 'react-intl-universal';

import { currentLocaleUrl, formatDomUnit, getVisualSkipPage } from '@/utils';
import { useAppSelector } from '@/store/hook';
import { NextImage } from '@/components';
import type { ArticleVideoInfoType } from '@/type/article';

import { VideoProps } from '../type';
import styles from './index.module.less';

const RenderItem = ({ dataSource }: { dataSource: ArticleVideoInfoType }) => {
    const { seo_url, code, thumbnail_pics = [], title = '' } = dataSource || {};

    const cover = thumbnail_pics[0]?.url;

    return (
        <a href={currentLocaleUrl(`/article/${seo_url || code}`)} target="_blank" className={styles.list__item}>
            <div className={styles.list__item__cover}>
                <NextImage src={cover} place="new_normal" layout="fill" objectFit="cover" alt={title} />
                <div className={styles.cover__play}>
                    <div className={styles.cover__play__icon}>
                        <NextImage src="/icon/video-play-small.webp" layout="fill" place="new_normal" needPrefix />
                    </div>
                </div>
            </div>
            <div className={styles.list__item__title}>{title}</div>
        </a>
    );
};

const MVideo: React.FC<VideoProps> = (props) => {
    const { customStyle, serviceData, buttonUrl } = props;
    const { list = [] } = serviceData || {};

    const { imgPrefix } = useAppSelector((state: any) => {
        return state.config;
    });

    if (list.length === 0) {
        return null;
    }

    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            <div className={styles.video}>
                {/* 头部 */}
                <div className={styles.header}>
                    <div className={styles.header__left}>
                        <em style={{ backgroundImage: `url('${imgPrefix}/icon/badge-2.webp')` }} />
                        <h2>{intl.get('Home.Video').d('视频')}</h2>
                    </div>
                    <a
                        href={currentLocaleUrl(getVisualSkipPage(buttonUrl))}
                        target="_blank"
                        className={styles.header__right}
                    >
                        {intl.get('Common.SeeMore').d('查看更多')}
                    </a>
                </div>
                {/* 列表 */}
                <div className={styles.list}>
                    {list.slice(0, 5).map((item: any) => {
                        const { pk } = item || {};
                        return <RenderItem dataSource={item} key={pk} />;
                    })}
                </div>
            </div>
        </div>
    );
};

export default MVideo;
