import React from 'react';
import intl from 'react-intl-universal';
import c from 'classnames';

import { ArticleVideoInfoType } from '@/type/article';
import { NextImage, Scrollbar } from '@/components';
import { currentLocaleUrl } from '@/utils';

import styles from './index.module.less';

interface Props {
    list: ArticleVideoInfoType[];
    activeIndex: number;
    MoreUrl: string;
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

const VideoList = ({ setActiveIndex, list = [], activeIndex = 0, MoreUrl = '' }: Props) => {
    return (
        <div className={styles.preview}>
            <div className={styles.preview__list}>
                <Scrollbar noScrollX trackYClassName={styles.recScrollTrackY} thumbYClassName={styles.recScrollThumbY}>
                    {list.map((item, index) => {
                        const { thumbnail_pics, title, pk } = item || {};
                        const cover = thumbnail_pics[0]?.url;

                        // 当前选中
                        const isActive = index === activeIndex;

                        return (
                            <div
                                onClick={() => setActiveIndex?.(index)}
                                className={c(styles.preview__list__item, isActive ? styles.active : '')}
                                key={pk}
                            >
                                <div className={styles.item__cover}>
                                    <NextImage
                                        src={cover}
                                        className={styles.item__cover__img}
                                        place="new_normal"
                                        layout="fill"
                                        objectFit="cover"
                                        alt={title}
                                    />
                                    <div className={styles.cover__play}>
                                        <div className={styles.cover__play__icon}>
                                            <NextImage
                                                src="/icon/video-play-small.webp"
                                                layout="fill"
                                                place="new_normal"
                                                needPrefix
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.item__title}>{title}</div>
                            </div>
                        );
                    })}
                </Scrollbar>
            </div>
            <a href={currentLocaleUrl(MoreUrl)} target="_blank" className={styles.preview__more}>
                {intl.get('Home.AllVideos').d('全部视频')}
                <svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%" />
                </svg>
            </a>
        </div>
    );
};

export default VideoList;
