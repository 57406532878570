import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PCCalendar from './pc';
import MCalendar from './m';

const Calendar: React.FC = (props: any) => {
    return <TransferCom pc={<PCCalendar {...props} />} m={<MCalendar {...props} />} ipad={null} />;
};

export default Calendar;
