import React, { useState, useEffect } from 'react';
import c from 'classnames';
import NoContent from '@/components/public/NoContent';
import { NormalTitle, BrokerItemA, NoMore } from '@/reconstruction/components/public';
import intl from 'react-intl-universal';
import SwiperCore, { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getBillboardDetails } from '@/services/broker';
import { currentLocaleUrl } from '@/utils';
import { getUrl } from '@/utils/url';
import { formatMts } from '@/utils/mts';
import type { BillboardPros } from '../types';
import styles from './index.module.less';

SwiperCore.use([Mousewheel]);

const Billboard: React.FC<BillboardPros> = ({ hasArrow, groupTitleMts, groupTitle, moreInfo, serviceData }) => {
    const { billboard = [], billboardDetails = [] } = serviceData || {};

    const MaxNum = 3;
    const currentLocale = intl.getInitOptions().currentLocale;

    const [loading, setLoading] = useState<boolean>(false);
    // tab 选中项
    const [activeItem, setActiveItem] = useState<any>(billboard[0] || {});
    const [detailsList, setDetailsList] = useState<any>(billboardDetails || []);

    // 动态设置 选中项
    useEffect(() => {
        setActiveItem(billboard[0] || {});
    }, [billboard, currentLocale]);

    // 根据active，动态请求加榜单详情
    useEffect(() => {
        if (loading) return;
        setLoading(true);
        if (activeItem?.id) {
            getBillboardDetails({ id: activeItem?.id })
                .then((res: any) => {
                    const { state, data } = res || {};
                    if (state === 1) {
                        const { list = [] } = data || {};
                        const newList: any[] = (list || []).splice(0, MaxNum);

                        setDetailsList(newList);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setDetailsList([]);
            setLoading(false);
        }
    }, [activeItem]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.billboard_top}>
                {hasArrow ? (
                    <NormalTitle customClass={styles.maxLine} title={formatMts(groupTitleMts) || groupTitle || ''} />
                ) : (
                    <span />
                )}

                <a className={styles.more} href={currentLocaleUrl(getUrl(moreInfo))} target="_blank">
                    <span>{intl.get('Common.SeeMore').d('查看更多')}</span>
                    <span className={c('iconfont', 'icon-jiantou', styles.arrow)} />
                </a>
            </div>

            <Swiper
                observer
                observeParents
                mousewheel
                resizeObserver
                grabCursor
                slidesPerView="auto"
                className={c(styles.list)}
            >
                {billboard.map((item: any) => {
                    const { id, simple_name } = item;

                    return (
                        <SwiperSlide
                            className={c(styles.list_item, { [styles.active]: activeItem?.id === id })}
                            key={id}
                            onClick={() => setActiveItem(item)}
                        >
                            {simple_name || '-'}
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            {detailsList.length !== 0 ? (
                <div className={styles.details_list_box}>
                    <ul className={styles.details_list}>
                        {detailsList.map((item: any, i: number) => {
                            const { id } = item;

                            return (
                                <li
                                    key={id}
                                    className={c({
                                        [styles.no_bottom]: i === detailsList.length - 1,
                                    })}
                                >
                                    <BrokerItemA rank={i < 3} index={i} record={item} />
                                </li>
                            );
                        })}

                        {detailsList.length !== 0 && detailsList.length < MaxNum && <NoMore top={24} />}
                    </ul>
                </div>
            ) : (
                <div className={styles.null_data}>
                    <NoContent />
                </div>
            )}
        </div>
    );
};

export default Billboard;
