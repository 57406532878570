import React from 'react';

import { formatDomUnit } from '@/utils';

import styles from './index.module.less';
import { RowBoxProp } from '../type';

const PCRowBox: React.FC<RowBoxProp> = (parmas) => {
    const { render, elementKey, showNewLayout, customStyle } = parmas;

    return (
        <div
            className={showNewLayout ? styles[elementKey] : ''}
            style={showNewLayout ? {} : { ...formatDomUnit(customStyle || {}) }}
        >
            {render?.()}
        </div>
    );
};

export default PCRowBox;
