import React from 'react';
import c from 'classnames';
import intl from 'react-intl-universal';

import { formatDomUnit, currentLocaleUrl } from '@/utils';
import { NormalTitle } from '@/reconstruction/components/public';

import styles from './index.module.less';
import RenderItem from './RenderItem';
import { NormalProps } from '../type';

const PcTopic: React.FC<NormalProps> = ({ customStyle, isShowNew, isShowHot, showType, setShowType, currentList }) => {
    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            <div className={styles.topic}>
                <div className={styles.topic_top}>
                    <NormalTitle title={intl.get('Home.RecommendedColumn').d('推荐专栏')} />

                    <div className={styles.tab_wrapper}>
                        {isShowNew && (
                            <div
                                onClick={() => setShowType('new')}
                                className={c(styles.tab_item, { [styles.active]: showType === 'new' })}
                            >
                                {intl.get('Common.New').d('最新')}
                            </div>
                        )}
                        {isShowHot && (
                            <div
                                onClick={() => setShowType('hot')}
                                className={c(styles.tab_item, { [styles.active]: showType === 'hot' })}
                            >
                                {intl.get('Common.Hot').d('最热')}
                            </div>
                        )}
                    </div>

                    <a href={currentLocaleUrl('/topic/list')} className={styles.wrapper__more} target="_blank">
                        <span className={styles.text__more}>{intl.get('Common.SeeMore').d('查看更多')}</span>
                        <span className={c(styles.arrow__more, 'iconfont', 'icon-arrow-right')} />
                    </a>
                </div>
                <div className={styles.list}>
                    {currentList.map((item: any, i: number) => (
                        <RenderItem dataSource={item} key={`${item.code || item._id}_${i}`} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PcTopic;
