import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Tabs, NextImage, StarLv, LoadMore, BrokerBusinessTag } from '@/components';
import NavTabItem from '@/components/public/Tabs/NavTabItem';
import { getBillboardDetails } from '@/services/broker';
import { resizeObserverFun, PAGE_CENTER_WA, PAGE_CENTER_WB, formatMts, currentLocaleUrl } from '@/utils';
import Link from 'next/link';
import dayjs from 'dayjs';
import c from 'classnames';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    navs: any[];
    elementId: any;
    options: any;
    hasArrow?: boolean;
    groupTitle?: string;
    groupTitleMts?: {
        zh_cns?: string;
        en_us?: string;
    };
}

const BrokerTop: React.FC<Props> = ({ navs = [], elementId, options, hasArrow, groupTitle, groupTitleMts }) => {
    const { list = [] } = options;
    const currentLocale = intl.getInitOptions().currentLocale === 'en';

    const domRef = useRef<any>(null);
    const [domWidth, setDomWidth] = useState<number>(PAGE_CENTER_WA);
    useEffect(() => {
        resizeObserverFun(domRef.current, setDomWidth);
    }, []);

    const newNavs = useMemo(() => {
        return (navs || []).map((item: any, i: number) => {
            const { openInfo } = item || {};
            const { type } = openInfo || {};
            const { id } = openInfo[type] || {};
            return { ...item, id, key: i };
        });
    }, [navs]);

    const [activeList, setActiveList] = useState<any>(list || []);
    const [activeItem, setActiveItem] = useState<any>(newNavs[0] || {});

    const newList = useMemo(() => {
        const nList = (activeList || [])
            .map((item: any, i: number) => {
                return { ...item, key: i };
            })
            .slice(0, 10);
        const num = Math.ceil(nList.length / 2);
        return [
            { key: 1, list: nList.slice(0, num) },
            { key: 2, list: nList.slice(num, (activeList || []).length) },
        ];
    }, [activeList]);

    const formatFiveYear = (v: any) => {
        const num = dayjs().diff(dayjs(v), 'year', true);
        const count = Math.floor(num / 5);
        if (count < 1) {
            return intl.get('Broker.VithinFiveYear').d('5年内');
        }
        return intl.get('Broker.MoreYear', { field: count * 5 }).d(`${count * 5}年以上`);
    };

    const onTabChange = (_key: number, data: any) => {
        setActiveItem(data);
    };

    useEffect(() => {
        getBillboardDetails({ id: activeItem?.id }).then((res: any) => {
            const { state, data } = res || {};
            if (state === 1) {
                const { list = [] } = data || {};
                setActiveList(list || []);
            }
        });
    }, [activeItem?.id, currentLocale]);

    return (
        <div className={styles.brokerNav} ref={domRef}>
            {hasArrow ? (
                <h2>
                    <span>{formatMts(groupTitleMts) || groupTitle}</span>
                    <Link href="/broker/list">
                        <a target="_blank">
                            <NextImage
                                src="/broker/icon/circle_arrow.webp"
                                needPrefix
                                width={24}
                                height={24}
                                alt="arrow"
                            />
                        </a>
                    </Link>
                </h2>
            ) : null}
            <Tabs
                elementId={elementId}
                list={newNavs}
                onTabChange={onTabChange}
                itemRender={(data, activeTab) => {
                    return <NavTabItem data={data} activeTab={activeTab} />;
                }}
            />
            <div className={c(styles.billboard, domWidth < PAGE_CENTER_WA ? styles.billboardOne : null)}>
                {activeList.length !== 0 ? (
                    newList.map((brokerList: any) => {
                        const { key: aKey, list: aList = [] } = brokerList;
                        return (
                            <ul key={aKey} className={styles.brokerList}>
                                {aList.map((brokerItem: any) => {
                                    const {
                                        id,
                                        key: bKey,
                                        logo,
                                        name_abbreviation,
                                        business_name,
                                        score,
                                        rate,
                                        year,
                                        license = [],
                                        seo_url,
                                    } = brokerItem || {};

                                    const sList = (license || []).map((item: any, index: number) => {
                                        return { name: item, key: index };
                                    });
                                    return (
                                        <li key={bKey}>
                                            <a
                                                href={currentLocaleUrl(`/broker/${seo_url || id}`)}
                                                className={styles.brokeItem}
                                                target="_blank"
                                            >
                                                <div className={styles.logo}>
                                                    <NextImage
                                                        src={logo}
                                                        layout="fill"
                                                        className={styles.logoForRecommend}
                                                        place="logo_b"
                                                        alt={name_abbreviation}
                                                    />
                                                    {bKey < 3 && (
                                                        <span className={styles.num}>
                                                            <NextImage
                                                                src={`/broker/icon/ranking-${bKey + 1}.webp`}
                                                                needPrefix
                                                                alt={`rank_${bKey + 1}`}
                                                                width={24}
                                                                height={29}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                                <div className={styles.info}>
                                                    <div>
                                                        <h2>{name_abbreviation}</h2>
                                                        <div className={styles.msg}>
                                                            <p className={styles.score}>
                                                                {intl.get('Broker.Score').d('评分')}：
                                                                <span>{Number(score || 0).toFixed(1)}</span>
                                                            </p>
                                                            <StarLv
                                                                wrapClassname={styles.grade}
                                                                grade={Number(rate || 0)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <p className={styles.textBot}>
                                                        <span>{formatFiveYear(year)}</span>
                                                        {sList.map((item: any, i: number) => {
                                                            return (
                                                                <span key={item.key}>
                                                                    {i > 0 ? (
                                                                        <span className={styles.split}>|</span>
                                                                    ) : null}
                                                                    <span>{item.name}</span>
                                                                </span>
                                                            );
                                                        })}
                                                    </p>
                                                </div>
                                                <BrokerBusinessTag name={business_name} site="rightTop" />
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        );
                    })
                ) : (
                    <div className={styles.loadMore}>
                        <LoadMore
                            isMobile={domWidth < PAGE_CENTER_WB}
                            empty={true}
                            emptyText={intl.get('Broker.NoListData').d('暂无交易商榜单数据')}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default BrokerTop;
