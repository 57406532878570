import React from 'react';
import { getArticleViewNum, formatNewsLetter, formatIsTopList, filterSpace, currentLocaleUrl } from '@/utils';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { formatMts } from '@/utils/mts';
import styles from './index.module.less';
import intl from 'react-intl-universal';

// 默认包含 小图、视频
export const normalArticleRender = (item: any, action: any) => {
    const {
        title,
        // content,
        view_num,
        is_original,
        datetime,
        nanoid,
        introduction,
        thumbnail_pics = [],
    } = item || {};

    const thumbnail = thumbnail_pics[0] || {};
    const timer = dayjs(datetime * 1000).format('YYYY.MM.DD');
    const inttimer = timer !== 'Invalid Date' ? timer : datetime;

    return (
        <a
            key={nanoid}
            className={classnames(styles.articleItem, styles.typeOne)}
            onClick={() => {
                action ? action(item) : console.log('no action');
            }}
            target="_blank"
            title={title}
        >
            <div className={styles.articleContain}>
                <div className={styles.articleLeft}>
                    <div className={styles.articleTitle}>{title}</div>

                    <div className={styles.articleCon}>
                        <div className={styles.articleDesc}>{filterSpace(introduction || '')}</div>
                    </div>

                    <div className={styles.articleMsg}>
                        {Number(is_original) === 1 ? (
                            <div className={styles.msgFont}>{intl.get('Common.Original').d('原创')}</div>
                        ) : null}
                        <div>{getArticleViewNum(view_num)}人看过</div>
                        <div>{inttimer}</div>
                    </div>
                </div>

                <div className={styles.articleRight}>
                    {thumbnail.url ? <div style={{ backgroundImage: `url(${thumbnail.url})` }} /> : null}
                    {item.item_type === '1_v' ? <span className={styles.videoPlay} /> : null}
                </div>
            </div>
        </a>
    );
};

// 三图
export const multiPicArticleRender = (item: any, action: any) => {
    const { title, view_num, is_original, datetime, nanoid, thumbnail_pics = [] } = item || {};

    const newPicList = [...thumbnail_pics].map((e: any, i: number) => {
        return { ...e, key: i };
    });
    const timer = dayjs(datetime * 1000).format('YYYY.MM.DD');
    const inttimer = timer !== 'Invalid Date' ? timer : datetime;

    return (
        <a
            key={nanoid}
            className={classnames(styles.articleItem, styles.typeTwo)}
            onClick={() => {
                action ? action(item) : console.log('no action');
            }}
            target="_blank"
            title={title}
        >
            <div className={styles.articleContain}>
                <div className={styles.articleTitle}>{title}</div>
                <div className={styles.articleMsg}>
                    {Number(is_original) === 1 ? (
                        <div className={styles.msgFont}>{intl.get('Common.Original').d('原创')}</div>
                    ) : null}
                    <div>{getArticleViewNum(view_num || 0)}人看过</div>
                    <div>{inttimer}</div>
                </div>
                <div className={styles.articlePosterList}>
                    {[...newPicList].map((pic: any) => (
                        <div key={pic.key} className={styles.articlePoster}>
                            <div style={{ backgroundImage: `url(${pic.url})` }} />
                        </div>
                    ))}
                </div>
            </div>
        </a>
    );
};

// 只有标题
export const txtArticleRender = (item: any, action: any) => {
    const { title, view_num, is_original, datetime, nanoid } = item || {};

    const timer = dayjs(datetime * 1000).format('YYYY.MM.DD');
    const inttimer = timer !== 'Invalid Date' ? timer : datetime;

    return (
        <a
            key={nanoid}
            className={classnames(styles.articleItem, styles.typeThree)}
            onClick={() => {
                action ? action(item) : console.log('no action');
            }}
            target="_blank"
            title={title}
        >
            <div className={classnames(styles.articleContain, styles.articleNoUrl)}>
                <div className={styles.articleLeft}>
                    <div className={styles.articleTitle}>{title}</div>

                    <div className={styles.articleMsg}>
                        {Number(is_original) === 1 ? (
                            <div className={styles.msgFont}>{intl.get('Common.Original').d('原创')}</div>
                        ) : null}
                        <div>{getArticleViewNum(view_num || 0)}人看过</div>
                        <div>{inttimer}</div>
                    </div>
                </div>
            </div>
        </a>
    );
};

// 置顶
export const topArticleRender = (list: any[], action: any, customStyle: any) => {
    const newList = formatIsTopList(list);
    const { borderRadius, borderRadiusUnit } = customStyle || {};

    return (
        <div
            className={classnames(styles.topList, styles.typeFirst)}
            style={{ borderRadius: borderRadius ? `${borderRadius}${borderRadiusUnit}` : 'none' }}
        >
            <ul>
                {[...(newList || [])].map((e: any, index) => {
                    const { key, data = [] } = e;
                    return (
                        <li key={key}>
                            {[...data].map((o: any) => {
                                const { title, titleMts, nanoid } = o;
                                const tit = formatMts(titleMts) || title;
                                return (
                                    <a
                                        key={nanoid}
                                        title={tit}
                                        target="_blank"
                                        href={currentLocaleUrl(action ? action(o) : '')}
                                    >
                                        {index === 0 ? <h1>{tit}</h1> : tit}
                                    </a>
                                );
                            })}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

// 专区
export const prefectureArticleRender = (list: any[], action: any) => {
    return (
        <div className={classnames(styles.prefectureItem, styles.typeFive)}>
            <ul>
                {[...(list || [])].map((e: any, i: number) => {
                    const { title, nanoid } = e;
                    return (
                        <li key={nanoid}>
                            <span className={classnames(i < 3 ? styles[`num-${i + 1}`] : '')}>{i + 1}</span>
                            <a
                                title={title}
                                target="_blank"
                                onClick={() => {
                                    action ? action(e) : console.log('no action');
                                }}
                            >
                                {title}
                            </a>
                        </li>
                    );
                })}
            </ul>

            <div className={classnames(styles.logo, styles.logoA)} />
            <div className={classnames(styles.logo, styles.logoB)} />
        </div>
    );
};

// 时间线
export const timeLineArticleRender = (list: any[], action: any) => {
    const weeekDays = ['日', '一', '二', '三', '四', '五', '六'];
    const newsLetter = formatNewsLetter(list, 1000) || {};

    return (
        <div className={classnames(styles.timeLineItem, styles.typeSix)}>
            <ul className={styles.timeList}>
                {Object.keys(newsLetter).map((date: any) => {
                    const datetimer = new Date(Number(date));
                    const day = dayjs(datetimer).format('MM.DD');

                    const weeek = weeekDays[dayjs(datetimer).day() || 0];
                    const newlist = newsLetter[date] || [];

                    return (
                        <li key={date}>
                            <div className={styles.date}>
                                <span />
                                <span>
                                    {day} 星期{weeek}
                                </span>
                            </div>

                            <ol>
                                {[...newlist].map((article: any) => {
                                    const { title, datetime, introduction, nanoid } = article;
                                    const timer = dayjs(new Date(datetime * 1000)).format('HH:mm');

                                    return (
                                        <li key={nanoid}>
                                            <a
                                                title={title}
                                                target="_blank"
                                                onClick={() => {
                                                    action ? action(article) : console.log('no action');
                                                }}
                                            >
                                                <>
                                                    <div className={styles.timeItemTit}>
                                                        <span />
                                                        <span>{timer}</span>
                                                        <span>{title}</span>
                                                    </div>

                                                    <div className={styles.timeItemCon}>
                                                        <div className={styles.articleDesc}>
                                                            {filterSpace(introduction || '')}
                                                        </div>
                                                    </div>
                                                </>
                                            </a>
                                        </li>
                                    );
                                })}
                            </ol>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
