import { useEffect, useMemo, useRef, useState } from 'react';
import intl from 'react-intl-universal';

import type { BrokerBusinessType, BrokerFilterType, BrokerSortType } from '@/type/broker';
import { getBrokerValues } from '@/services/broker';
import { cloneDeep } from 'lodash';

interface DictType {
    dict_key: number;
    dict_name: string[];
    dict_type: string;
}

type FilterRecordType = 'checkbox' | 'country' | 'slider' | 'other';

interface Props {
    /** 初始筛选数据 */
    initFilterList: BrokerFilterType[];
    /** 初始排序数据 */
    initSortList: BrokerSortType[];
    /** 业务列表 */
    businessList: any[];
}

/** 筛选项hook */
const useFilterItem = ({ initFilterList, initSortList, businessList }: Props) => {
    const locale = intl.getInitOptions().currentLocale;
    const isInit = useRef<boolean>(true);

    // ----------------------- 业务 Start -----------------------
    const [businessActiveIndex, setBusinessActiveIndex] = useState<number>(0); // 坐标
    const [businessLoading, setBusinessLoading] = useState<boolean>(false); // 加载状态
    // ----------------------- 业务 End -----------------------

    // ----------------------- 筛选 Start -----------------------
    const [filterList, setFilterList] = useState<BrokerFilterType[]>(initFilterList); // 筛选列表
    const [brokerScreen, setBrokerScreen] = useState<DictType[]>([]); // 筛选参数数据
    const [currentFilter, setCurrentFilter] = useState<BrokerFilterType[]>([]); // 当前已筛选
    const [countryList, setCountryList] = useState<string[]>([]); // 国家地区
    // ----------------------- 筛选 End -----------------------

    // ----------------------- 排序 Start -----------------------
    const [sortList, setSortList] = useState<BrokerSortType[]>(initSortList); // 排序数据
    const [sortActiveIndex, setSortActiveIndex] = useState<number>(0); // 排序当前下标

    /** 筛选数据 */
    const sortData = useMemo(() => {
        return sortList.map((item: any) => {
            const key = Object.keys(item)[0];
            return {
                value: key,
                label: item[key],
            };
        });
    }, [sortList]);

    /** 时间数据 */
    const yearData = useMemo(() => {
        return filterList.find((item) => {
            return item.value === 'year';
        });
    }, [filterList]);

    /** 国家数据 */
    const countryData = useMemo(() => {
        return filterList.find((item) => {
            return item.value === 'country';
        });
    }, [filterList]);
    // ----------------------- 排序 End -----------------------
    /** 请求额外参数 */
    const extraParams = useMemo(() => {
        const currentSort = sortData[sortActiveIndex];
        // 列表额外参数
        return {
            sort_field: currentSort.value,
            sort_type: currentSort.value === 'year' ? 1 : -1,
            broker_screen: [
                ...brokerScreen,
                {
                    dict_type: 'type',
                    dict_name: [],
                    dict_key: businessList[businessActiveIndex]?.id,
                },
            ],
            country: countryList,
            is_all_country: countryList.length > 0 ? 0 : 1,
        };
    }, [sortData, sortActiveIndex, brokerScreen, countryList, businessList, businessActiveIndex]);

    /** 切换多语言初始化数据 */
    useEffect(() => {
        if (isInit.current) {
            isInit.current = false;
            return;
        }

        setCurrentFilter([]);
        setBrokerScreen([]);
        setCountryList([]);
        setBusinessActiveIndex(0);
        setSortActiveIndex(0);
        setFilterList(initFilterList);
        setSortList(initSortList);
    }, [locale]);

    /** 业务点击 */
    const businessClickHandler = async (item: BrokerBusinessType, index: number) => {
        const { id } = item;

        setBusinessLoading(true);
        // 获取筛选项和排序选项
        getBrokerValues({ type: id }, locale)
            .then((res: any) => {
                if (res.state !== 1) {
                    return;
                }
                const { sort = [], filter = [] } = res.data || {};
                setBusinessActiveIndex(index);
                setFilterList(filter);
                setSortList(sort);
                setBrokerScreen([]);
                setCountryList([]);
                setCurrentFilter([]);
                setSortActiveIndex(0);
            })
            .finally(() => {
                setBusinessLoading(false);
            });
    };

    /** 排序点击 */
    const sortClickHandler = (index: number) => {
        setSortActiveIndex(index);
    };

    /** 增加筛选项参数 */
    const addBrokerScreen = (record: BrokerFilterType, value: string, type: FilterRecordType) => {
        // 国家单独处理
        if (type === 'country' && record.label) {
            setCountryList([...countryList, record.label]);
            return;
        }

        const currentScreen = brokerScreen.find((item) => {
            return item.dict_type === value;
        });
        // 筛选不存在
        if (!currentScreen) {
            setBrokerScreen([
                ...brokerScreen,
                {
                    dict_type: value,
                    dict_name: [record.name || record.label || ''],
                    dict_key: type === 'other' ? 1 : 0,
                },
            ]);

            return;
        }

        const cloneBrokerScreen = brokerScreen.filter((item) => item.dict_type !== value);
        // 筛选已存在
        setBrokerScreen([
            ...cloneBrokerScreen,
            {
                dict_type: value,
                dict_name: [...currentScreen.dict_name, record.name || record.label || ''],
                dict_key: type === 'other' ? 1 : 0,
            },
        ]);
    };

    /** 删除筛选项参数 */
    const delBrokerScreen = (record: BrokerFilterType, value: string, type: FilterRecordType) => {
        // 国家单独处理
        if (type === 'country' && record.label) {
            const newList = countryList.filter((item) => item !== record.label);
            setCountryList(newList);
            return;
        }

        const currentScreen = brokerScreen.find((item) => {
            return item.dict_type === value;
        });
        if (!currentScreen) return;

        const dictName = currentScreen?.dict_name || [];
        const cloneBrokerScreen = brokerScreen.filter((item) => item.dict_type !== value);

        if (dictName.length <= 1) {
            setBrokerScreen(cloneBrokerScreen);
            return;
        }
        // 筛选已存在
        setBrokerScreen([
            ...cloneBrokerScreen,
            {
                dict_type: value,
                dict_name: dictName.filter((item) => item !== (record.name || record.label)),
                dict_key: type === 'other' ? 1 : 0,
            },
        ]);
    };

    /** 清空筛选项 */
    const clearFilterHandler = () => {
        setBrokerScreen([]);
        setCurrentFilter([]);
        setCountryList([]);
    };

    /** 筛选点击 */
    const filterClickHandler = (record: BrokerFilterType, value: string, type: FilterRecordType) => {
        const findFilter = currentFilter.find((item) => {
            return item.filterKey === value && item.value === record.value;
        });

        /** 不存在筛选时 */
        if (!findFilter) {
            addBrokerScreen(record, value, type);
            setCurrentFilter([...currentFilter, { ...record, filterKey: value, type }]);
            return;
        }

        /** 筛选项已存在 */
        const result = currentFilter.filter((item) => {
            return item.filterKey !== value || item.value !== record.value;
        });
        delBrokerScreen(record, value, type);
        setCurrentFilter(result);
    };

    /** 滑块筛选 */
    const sliderFilterChangeHandler = (record: BrokerFilterType, value: [number, number]) => {
        let startIndex = 0;
        let endIndex = 0;
        /** 列表数据 */
        const list = record.key.reduce((init: any[], item, index) => {
            if (item.label === '其他') {
                return init;
            }
            if (item.value === value[0]) {
                startIndex = index;
            }
            if (item.value === value[1]) {
                endIndex = index;
            }

            return [...init, item];
        }, []);
        /** 展示文案 */
        const minText = list[startIndex].label || '';
        const maxText = list[endIndex].label || '';
        const label = `${record.name} ${minText}-${maxText}`;

        const findFilter = currentFilter.findIndex((item) => {
            return item.filterKey === record.value;
        });

        // 已存在筛选项，删除之前的数据
        if (findFilter !== -1) {
            let cloneBrokerScreen = cloneDeep(brokerScreen);
            let cloneBrokerFilter = cloneDeep(currentFilter);

            cloneBrokerScreen = brokerScreen.filter((item) => item.dict_type !== record.value);
            cloneBrokerFilter[findFilter].label = label;
            cloneBrokerFilter[findFilter].rangeList = value;

            setCurrentFilter(cloneBrokerFilter);
            setBrokerScreen([
                ...cloneBrokerScreen,
                {
                    dict_type: record.value,
                    dict_name: list.map((item) => item.label).slice(startIndex, endIndex + 1),
                    dict_key: 0,
                },
            ]);
            return;
        }

        // 不存在筛选项，直接增加
        setCurrentFilter([
            ...currentFilter,
            { ...record, filterKey: record.value, label, type: 'slider', rangeList: value, isSlider: true },
        ]);
        setBrokerScreen([
            ...brokerScreen,
            {
                dict_type: record.value,
                dict_name: list.map((item) => item.label).slice(startIndex, endIndex + 1),
                dict_key: 0,
            },
        ]);
    };

    return {
        /** 筛选项列表 */
        filterList,
        /** 当前筛选项 */
        currentFilter,
        /** 筛选点击 */
        filterClickHandler,
        /** 清空筛选 */
        clearFilterHandler,
        /** 滑块筛选 */
        sliderFilterChangeHandler,
        /** 排序项列表 */
        sortList,
        /** 排序项下标 */
        sortActiveIndex,
        /** 排序点击 */
        sortClickHandler,
        /** 筛选数据 */
        sortData,
        /** 成立时间数据 */
        yearData,
        /** 国家地区数据 */
        countryData,
        /** 业务点击 */
        businessClickHandler,
        /** 当前业务 */
        businessActiveIndex,
        /** 加载状态 */
        businessLoading,
        /** 请求额外参数 */
        extraParams,
    };
};

export default useFilterItem;
