import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { App } from 'antd';
import { NextImage } from '@/components';
import c from 'classnames';
import { NormalTitle, MBrokerSelect } from '@/reconstruction/components/public';
import intl from 'react-intl-universal';
import { nanoid } from 'nanoid';
import { cloneDeep } from 'lodash';
import { currentLocaleUrl } from '@/utils';
import { getBusinessList } from '@/services/broker';
import type { ContrastPros } from '../../../types';
import styles from './index.module.less';

const Contrast: React.FC<ContrastPros> = ({ contrast = [] }) => {
    const { message } = App.useApp();
    const currentLocale = intl.getInitOptions().currentLocale;

    // 更换交易商
    const [brokerOpen, setBrokerOpen] = useState<boolean>(false);
    const [changeIndex, setChangeIndex] = useState<number>(0);
    const [changetItem, setChangeItem] = useState<any>(undefined);

    // 业务列表
    const [businessList, setBusinessList] = useState<any>([]);

    // 对比的交易商
    const [contrastList, setContrastList] = useState<any[]>([]);

    /**
     * 初始化获取交易商类型
     */
    useEffect(() => {
        getBusinessList().then((res: any) => {
            const { state, data = [] } = res || {};
            if (state === 1) {
                setBusinessList(data || []);
            }
        });
    }, [currentLocale]);

    // 对比
    useEffect(() => {
        const newList: any[] = [];
        const brokerArr = Array.from({ length: 3 }, (_v, i) => i);
        const { type } = contrast.find((item: any) => item?.type) || {};
        brokerArr.forEach((_item: any, i: number) => {
            if (contrast[i]) {
                newList.push(contrast[i]);
            } else {
                newList.push({ id: nanoid(), edit: true, type });
            }
        });

        setContrastList(newList);
    }, [contrast]);

    // 判断是否可以对比
    const canContrast = useMemo(() => {
        return contrastList.find((item) => item?.id && !item?.edit);
    }, [contrastList]);

    // 前往对比
    const onToContrast = useCallback(() => {
        if (!canContrast) return;
        const ids = contrastList.map((item: any) => {
            if (item?.edit) {
                return '';
            } else {
                return item?.id;
            }
        });
        const idstr = ids.join('.');
        window.open(currentLocaleUrl(`/page/Pc_Barterer_Comparison?ids=${encodeURIComponent(idstr)}`));
    }, [canContrast, contrastList]);

    // 全部清除
    const onClearAll = () => {
        const cloneList = cloneDeep(contrastList || []);
        setContrastList(cloneList.map(() => ({ id: nanoid(), edit: true })));
    };

    // 选择交易商回调
    const onBrokerChange = (record: any, index: number) => {
        const cloneList = cloneDeep(contrastList);
        const hasItem = cloneList.find((item: any, i: number) => item.id === record.id && index !== i);
        if (hasItem) {
            message.warning(intl.get('Broker.BrokerLWarnMsg').d('该交易商已在对比列表'));
        } else {
            cloneList.splice(index, 1, { ...record, edit: false });
            const newList = cloneList.map((item: any) => ({ ...item, type: record?.type || item?.type }));
            setContrastList(newList);
        }
    };

    // 更换交易商
    const onHandelChange = (item: any, i: number) => {
        setBrokerOpen(true);
        setChangeItem(item);
        setChangeIndex(i);
    };

    // 关闭更换交易商弹窗
    const onBrokerClose = () => {
        setBrokerOpen(false);
        setChangeItem(undefined);
    };

    // 添加
    const onAddItem = (record: any, index: number, call?: any) => {
        const cloneList = cloneDeep(contrastList);
        const hasItem = cloneList.find((item: any) => item.id === record.id);
        if (hasItem) {
            message.warning(intl.get('Broker.BrokerLWarnMsg').d('该交易商已在对比列表'));
        } else {
            cloneList.splice(index, 1, { ...record, edit: false });
            const newList = cloneList.map((item: any) => ({ ...item, type: record?.type || item?.type }));
            setContrastList(newList);
        }
        call?.();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.contrast_top}>
                <NormalTitle title={intl.get('Broker.BrokerComparison').d('交易商对比')} />
            </div>

            <ul className={styles.contrast_list}>
                {(contrastList || []).map((item: any, index: number) => {
                    const { id, logo, name_abbreviation, score, seo_url, edit } = item;

                    return (
                        <li key={id} className={c({ [styles.sel_broker]: edit })}>
                            <div className={c({ [styles.hide]: edit })}>
                                <a href={currentLocaleUrl(`/broker/${seo_url || id}`)} target="_blank">
                                    <div className={styles.logo}>
                                        <NextImage
                                            src={logo}
                                            alt={name_abbreviation}
                                            place="logo_a_new"
                                            width={68}
                                            height={68}
                                        />
                                    </div>
                                    <p className={styles.name}>{name_abbreviation}</p>
                                </a>

                                <p className={styles.score}>
                                    <span>
                                        <strong>{Number(score || 0).toFixed(1)}</strong>
                                    </span>
                                    <span>{intl.get('Broker.Score').d('评分')}</span>
                                </p>

                                <div className={styles.change} onClick={() => onHandelChange(item, index)}>
                                    {intl.get('Broker.ChangeBroker').d('更换交易商')}
                                </div>
                            </div>

                            <div
                                className={c(styles.add_btn, { [styles.hide]: !edit })}
                                onClick={() => onHandelChange(item, index)}
                            >
                                <span className={c('iconfont', 'icon-jiahao', styles.add_icon)} />
                                <span className={styles.add_text}>{intl.get('Broker.AddTrader').d('添加交易商')}</span>
                            </div>
                        </li>
                    );
                })}
            </ul>

            <div className={styles.to_contrast}>
                <span className={c({ [styles.btn_disabled]: !canContrast })} onClick={onToContrast}>
                    {intl.get('Broker.ToCompare').d('去对比')}
                </span>
            </div>

            <div className={styles.clear_list}>
                <span onClick={onClearAll} className={c(styles.clear_btn, { [styles.clear_btn_dis]: !canContrast })}>
                    <span className={styles.clear_text}>{intl.get('Broker.ClearTraders').d('清空全部交易商')}</span>
                </span>
            </div>

            <MBrokerSelect
                open={brokerOpen}
                businessList={businessList}
                activeIndex={changeIndex}
                activeType={changetItem?.type}
                onChange={onBrokerChange}
                onClose={onBrokerClose}
            />
        </div>
    );
};

export default Contrast;
