import React from 'react';

import type { ArticleType } from '@/type/article';
import { NextImage } from '@/components';
import { currentLocaleUrl, newFormatTimer } from '@/utils';
import { NoContent } from '@/components/public';
import { NoMore } from '@/reconstruction/components/public';

import styles from './index.module.less';

function ArticlesList({ list = [], topIconLeft = 0 }: { topIconLeft: number; list: ArticleType[] }) {
    return (
        <div className={styles.article_list}>
            <div className={styles.article_list_border}>
                {list.map((item) => {
                    const {
                        union_id,
                        _id,
                        code,
                        title,
                        seo_url,
                        introduction,
                        cover,
                        article_add_time,
                        tags = [],
                    } = item || {};

                    return (
                        <a
                            href={currentLocaleUrl(`/article/${seo_url || code}`)}
                            target="_blank"
                            key={code || union_id || _id}
                            className={styles.article_list_item}
                        >
                            <div className={styles.article_list_cover}>
                                <NextImage layout="fill" src={cover?.url || ''} />
                            </div>
                            <div className={styles.article_list_info}>
                                <div className={styles.article_list_info_title}>{title}</div>
                                <div className={styles.article_list_info_intro}>
                                    <div className={styles.article_list_info_intro_text}>{introduction}</div>
                                </div>
                                <div className={styles.article_list_info_bottom}>
                                    <div className={styles.timer}>{newFormatTimer(article_add_time)}</div>
                                    <div className={styles.tags}>
                                        {tags.slice(0, 3).map?.((tag: string) => (
                                            <span
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(currentLocaleUrl(`/keyword?tags=${tag}`));
                                                }}
                                                key={tag}
                                            >
                                                {tag}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </a>
                    );
                })}
                {list.length === 0 && <NoContent />}
                {list.length !== 0 && list.length < 3 && <NoMore top={80} />}
                {Boolean(topIconLeft) && (
                    <em
                        className={styles.top_icon}
                        style={{ backgroundImage: 'url("/img/visual/column/top-icon.webp")', left: topIconLeft }}
                    />
                )}
            </div>
        </div>
    );
}

export default ArticlesList;
