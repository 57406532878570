import React, { useContext, useMemo } from 'react';
import c from 'classnames';

import styles from './index.module.less';
import { statusList } from '../../../config';
import { ActivityContent } from '../../content';
import { DropDownBox } from '@/reconstruction/components/public';
import intl from 'react-intl-universal';

const HeaderFilter: React.FC = () => {
    const {
        currentStatus,
        currentCity,
        filterChangeHandler,
        delFilterHandler,
        clearFilterHandler,
        cityListData,
        cityOption,
        citySumList,
    } = useContext(ActivityContent);

    /** 下拉框事件 */
    const dropDownBoxChange = (record: { value: string; id: string }) => {
        const { id } = record;
        filterChangeHandler('city', id);
    };

    return (
        <div className={styles.filter}>
            {/* 状态 */}
            <div className={styles.filter__item}>
                <div className={styles.label}>{intl.get('Activity.ActiveState').d('活动状态')}：</div>
                <div
                    onClick={() => filterChangeHandler('status', 'all')}
                    className={c(styles.value, { [styles.active]: currentStatus.length === 0 })}
                >
                    {intl.get('Common.All').d('全部')}
                </div>
                {statusList.map((item) => {
                    const activeCls = currentStatus.includes(item.value) ? styles.active : '';
                    return (
                        <div
                            onClick={() => filterChangeHandler('status', item.id)}
                            className={c(styles.value, activeCls)}
                            key={item.value}
                        >
                            {intl.get(item?.mts || '').d(item.label)}
                        </div>
                    );
                })}
            </div>
            {/* 区域 */}
            <div className={styles.filter__item}>
                <div className={styles.label}>{intl.get('Activity.OwningRegion').d('所属区域')}：</div>
                <div className={styles.filter__value}>
                    <div
                        onClick={() => filterChangeHandler('city', 'all')}
                        className={c(styles.value, { [styles.active]: currentCity.length === 0 })}
                    >
                        {intl.get('Common.All').d('全部')}
                    </div>
                    {cityListData.map((item) => {
                        const { value, label, id } = item;
                        const activeCls = currentCity.includes(value) ? styles.active : '';
                        return (
                            <div
                                onClick={() => filterChangeHandler('city', id)}
                                className={c(styles.value, activeCls)}
                                key={value}
                            >
                                {label}
                            </div>
                        );
                    })}
                </div>
                {/* 下拉框 */}
                <DropDownBox
                    option={cityOption}
                    selectedList={currentCity}
                    placeholder={intl.get('Activity.MoreOtherCountries').d('更多其他国家')}
                    onChange={dropDownBoxChange as any}
                />
            </div>
            {/* 筛选条件 */}
            <div className={c(styles.filter__item, styles.reset__wrapper)}>
                <div className={styles.label}>{intl.get('Activity.ScreeningCondition').d('筛选条件')}：</div>
                <div className={styles.reset} onClick={clearFilterHandler}>
                    <span>{intl.get('Activity.ResetFilter').d('清空条件')}</span>
                    <span className={c('iconfont', 'icon-clear')} />
                </div>
            </div>
            {/* 活动状态 */}
            <div className={styles.filter__item}>
                <div className={styles.label}>{intl.get('Activity.ActiveState').d('活动状态')}：</div>
                <div className={styles.selected}>
                    {currentStatus.length === 0 && (
                        <div className={c(styles.selected__value, styles.all)}>
                            <span className={styles.text}>{intl.get('Common.All').d('全部')}</span>
                        </div>
                    )}

                    {currentStatus.map((item) => {
                        const statusItem = statusList.filter((status) => status.value === item)[0];
                        return (
                            <div className={styles.selected__value} key={item}>
                                <span className={styles.text}>{intl.get(statusItem.mts).d(statusItem.label)}</span>
                                <span
                                    onClick={() => {
                                        delFilterHandler('status', item);
                                    }}
                                    className={c('iconfont', 'icon-fork', styles.icon)}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* 所属区域 */}
            <div className={c(styles.filter__item, styles.selected)}>
                <div className={styles.label}>{intl.get('Activity.OwningRegion').d('所属区域')}：</div>
                {currentCity.length === 0 && (
                    <div className={c(styles.selected__value, styles.all)}>
                        <span className={styles.text}>{intl.get('Common.All').d('全部')}</span>
                    </div>
                )}
                {currentCity.map((item) => {
                    const text = citySumList.filter((city) => city.value === item)[0]?.label || '';
                    return (
                        <div className={styles.selected__value} key={item}>
                            <span className={styles.text}>{text}</span>
                            <span
                                onClick={() => {
                                    delFilterHandler('city', item);
                                }}
                                className={c('iconfont', 'icon-fork', styles.icon)}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HeaderFilter;
