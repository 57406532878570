import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ActivityType } from '@/type/activity';
import { ActivityListItem } from '@/reconstruction/components/listItem';
import { InfiniteScroll } from '@/reconstruction/components/public';

import styles from './index.module.less';
import { ActivityContent } from '../../content';

interface Props {
    /** 服务端数据 */
    serviceData?: {
        list: ActivityType[];
        hasMore: boolean;
    };
}

const ActivityList: React.FC<Props> = ({ serviceData }) => {
    const { currentStatus, currentCity = [] } = useContext(ActivityContent);
    const InfiniteScrolleRef = useRef<any>(null);
    const [isInit, setIsInit] = useState(true);

    /** 额外参数 */
    const extraParams = useMemo(() => {
        return {
            ...(currentCity.length > 0 ? { cityList: currentCity } : {}),
            ...(currentStatus.length > 0 ? { stateList: currentStatus } : {}),
        };
    }, [currentCity, currentStatus]);

    /** 重新请求 */
    useEffect(() => {
        if (isInit) {
            setIsInit(false);
            return;
        }
        InfiniteScrolleRef.current?.resetList();
    }, [extraParams]);

    return (
        <InfiniteScroll
            serviceData={serviceData}
            onRef={InfiniteScrolleRef}
            initialLoad={false}
            method="post"
            api="/japi/activity/multiple/select/activity"
            extraParams={extraParams}
            renderList={(list: ActivityType[] = []) => {
                return (
                    <div className={styles.wrapper}>
                        {list.map((item) => {
                            return <ActivityListItem className={styles.list__item} key={item.id} dataSource={item} />;
                        })}
                    </div>
                );
            }}
        />
    );
};

export default ActivityList;
