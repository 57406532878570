import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import MBrokerContrast from './m';
import PcBrokerContrast from './pc';
import type { BrokerContrastProps } from './type';

const BrokerContrast: React.FC<BrokerContrastProps> = (props) => {
    return (
        <TransferCom
            m={<MBrokerContrast {...props} />}
            ipad={<PcBrokerContrast {...props} />}
            pc={<PcBrokerContrast {...props} />}
        />
    );
};

export default BrokerContrast;
