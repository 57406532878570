import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import c from 'classnames';
import intl from 'react-intl-universal';

import { resizeObserverFun } from '@/utils';
import { useAppSelector } from '@/store/hook';

import styles from './index.module.less';
import { AllBrokerContext } from '../../context';

function HeaderFilter() {
    const { currentFilter, clearFilterHandler, filterClickHandler } = useContext(AllBrokerContext);

    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);
    const [domHeight, setDomHeight] = useState<number>(0); // 基本信息容器高度
    const [isShowMore, setIsShowMore] = useState<boolean>(false); // 是否展示更多
    const domRef = useRef<any>(null);

    useEffect(() => {
        // 获取容器高度
        resizeObserverFun(domRef.current, setDomHeight, 'clientHeight');
    }, []);

    /** 是否展示更多 */
    const showMore = useMemo(() => {
        return domHeight > 114;
    }, [domHeight]);

    return (
        <div className={styles.wrapper} style={{ maxHeight: `calc(100vh - ${topHeight}px  - 84px)` }}>
            {/* 筛选项 */}
            <div className={c(styles.selected__wrapper, { [styles.showMore]: isShowMore })}>
                <div className={styles.selected} ref={domRef}>
                    {currentFilter.map((item, index) => {
                        const { label, name, filterKey, type } = item;
                        const text = label || name || '';
                        return (
                            <div className={styles.selected__value} key={index}>
                                <span className={styles.text}>{text}</span>
                                <span
                                    onClick={() => {
                                        filterClickHandler(item, filterKey || '', type || 'checkbox');
                                    }}
                                    className={c('iconfont', 'icon-fork', styles.icon)}
                                />
                            </div>
                        );
                    })}
                    <div className={styles.reset} onClick={clearFilterHandler}>
                        <span>{intl.get('Activity.ResetFilter').d('清空条件')}</span>
                        <span className={c('iconfont', 'icon-clear')} />
                    </div>
                </div>
            </div>
            {showMore && (
                <div
                    className={c(styles.more, { [styles.active]: isShowMore })}
                    onClick={() => setIsShowMore(!isShowMore)}
                >
                    <span>
                        {isShowMore ? intl.get('Common.PackUp').d('收起') : intl.get('Common.Unfold').d('展开')}
                    </span>
                    <span className={c('iconfont', 'icon-jiantou')} />
                </div>
            )}
        </div>
    );
}

export default HeaderFilter;
