import React, { useRef, useState } from 'react';
import { Button, ConfigProvider } from 'antd';
import { NextImage } from '@/components';
import { PcBrokerSelect } from '@/reconstruction/components/public';
import { currentLocaleUrl } from '@/utils';
import intl from 'react-intl-universal';
import type { SearchHeaderProps } from '../type';
import styles from './index.module.less';

const SearchHeader: React.FC<SearchHeaderProps> = ({ businessList = [] }) => {
    const selRef = useRef<any>(null);

    const [inputVal, setInputVal] = useState<string | undefined>(undefined);

    /**
     * 选中交易商
     * @param v
     * @param call
     */
    const onSelectItem = (v: any, call: any) => {
        const { id, seo_url } = v || {};
        if (seo_url || id) {
            window.open(currentLocaleUrl(`/broker/${seo_url || id}`));
        }
        call?.();
    };

    /**
     * 点击查询按钮
     */
    const onClickSearch = () => {
        if (inputVal) {
            window.open(currentLocaleUrl(`/search?type=broker&q=${inputVal}`));
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.banner}>
                <NextImage layout="fill" src="/broker/banner/broker-list-banner.webp" needPrefix alt="banner" />
            </div>
            <ConfigProvider
                theme={{
                    token: { colorPrimary: '#2C36DE' },
                }}
            >
                <div className={styles.search}>
                    <div className={styles.input_box} ref={selRef}>
                        <PcBrokerSelect
                            size="large"
                            max={10}
                            parentRef={selRef}
                            businessList={businessList}
                            placeholder={intl.get('Common.Search').d('搜索')}
                            onSearch={setInputVal}
                            onSelect={onSelectItem}
                        />
                    </div>

                    <Button className={styles.btn} onClick={onClickSearch}>
                        {intl.get('Broker.Lnquire').d('查询')}
                    </Button>
                </div>
            </ConfigProvider>
        </div>
    );
};

export default SearchHeader;
