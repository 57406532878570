import { useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { cityList, statusList } from '../../config';
import intl from 'react-intl-universal';

const useGetFilter = (
    city: {
        value: string;
        id: string;
    }[] = [],
) => {
    const locale = intl.getInitOptions().currentLocale; // 多语言

    const [currentStatusId, setCurrentStatusId] = useState<string[]>([]); // 状态展示id列表
    const [currentCityId, setCurrentCityId] = useState<string[]>([]); // 区域展示id列表

    // 下拉框数据
    const cityOption = useMemo(() => {
        return city.reduce(
            (
                init: {
                    value: string;
                    label: string;
                    id: string;
                }[],
                item,
            ) => {
                if (cityList.findIndex((i) => intl.get(i.mts).d(i.value) === item.value) === -1) {
                    init.push({
                        value: item.value,
                        label: item.value,
                        id: item.id,
                    });
                }
                return init;
            },
            [],
        );
    }, [city]);

    /** 重置 */
    const clearFilterHandler = () => {
        setCurrentStatusId([]);
        setCurrentCityId([]);
    };

    /** 热门城市数据 */
    const cityListData = useMemo(() => {
        return cityList.map((i) => ({
            label: intl.get(i.mts).d(i.label),
            value: intl.get(i.mts).d(i.value),
            mts: i.mts,
            id: i.mts,
        }));
    }, [locale]);

    /** 热门城市数据 */
    const statusListData = useMemo(() => {
        clearFilterHandler(); // 清空筛选
        return statusList.map((i) => ({
            label: intl.get(i.mts).d(i.label),
            value: i.value,
            mts: i.mts,
            id: i.id,
        }));
    }, [locale]);

    /** 城市总数据 */
    const citySumList = useMemo(() => {
        return [...cityListData, ...cityOption];
    }, [cityOption, cityListData]);

    /** 筛选变化 */
    const filterChangeHandler = (type: 'status' | 'city', id: 'all' | string) => {
        switch (id) {
            case 'all': // 全部
                switch (type) {
                    case 'status': // 状态
                        setCurrentStatusId([]);
                        break;
                    case 'city': // 区域
                        setCurrentCityId([]);
                        break;
                }
                break;
            default: //其他筛选项
                let isSelected, cloneIdList;
                // 获取当前状态数据
                switch (type) {
                    case 'status':
                        cloneIdList = cloneDeep(currentStatusId);
                        break;
                    case 'city':
                        cloneIdList = cloneDeep(currentCityId);
                        break;
                }
                // 判断是否选中
                isSelected = cloneIdList.includes(id);
                // 已选择的情况下，进行删除，未选择的情况下，增加选项
                if (isSelected) {
                    cloneIdList.splice(cloneIdList.indexOf(id), 1);
                } else {
                    cloneIdList.push(id);
                }

                // 更新状态
                switch (type) {
                    // 状态
                    case 'status':
                        setCurrentStatusId(cloneIdList);
                        break;
                    // 区域
                    case 'city':
                        setCurrentCityId(cloneIdList);
                        break;
                }
                break;
        }
    };

    const delFilterHandler = (type: 'status' | 'city', id: 'all' | string) => {
        let cloneIdList;
        // 获取当前状态数据
        switch (type) {
            case 'status':
                cloneIdList = cloneDeep(currentStatusId);
                break;
            case 'city':
                cloneIdList = cloneDeep(currentCityId);
                break;
        }
        cloneIdList.splice(cloneIdList.indexOf(id), 1);

        // 更新状态
        switch (type) {
            // 状态
            case 'status':
                setCurrentStatusId(cloneIdList);
                break;
            // 区域
            case 'city':
                setCurrentCityId(cloneIdList);
                break;
        }
    };

    /** 当前状态内容 */
    const currentStatus = useMemo(() => {
        const filterList = statusListData.filter((item) => {
            return currentStatusId.includes(item.id);
        });
        return filterList.map((item) => item.value);
    }, [locale, currentStatusId]);

    /** 当前区域内容 */
    const currentCity = useMemo(() => {
        const filterList = citySumList.filter((item) => {
            return currentCityId.includes(item.id);
        });
        return filterList.map((item) => item.value);
    }, [locale, currentCityId]);

    return {
        /** 当前状态 */
        currentStatus,
        /** 当前区域 */
        currentCity,
        /** 筛选变化 */
        filterChangeHandler,
        /** 删除筛选项 */
        delFilterHandler,
        /** 重置筛选项 */
        clearFilterHandler,
        /** 热门城市数据 */
        cityListData,
        /** 下拉框数据 */
        cityOption,
        /** 城市总数据 */
        citySumList,
    };
};

export default useGetFilter;
