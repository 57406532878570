import React, { useEffect } from 'react';
import c from 'classnames';
import { cloneDeep } from 'lodash';
import intl from 'react-intl-universal';
import { App } from 'antd';

import { NextImage } from '@/components';
import useCustomModal from '@/reconstruction/components/public/CustomModal';
import { focusAddPost, focusDelete } from '@/services/focus';
import { currentLocaleUrl } from '@/utils';
import type { UgcListType } from '@/type/ugc';

import styles from './index.module.less';

function RenderItem({ dataSource }: { dataSource: UgcListType }) {
    const { message } = App.useApp();
    const { article_list = [] } = dataSource;
    const [ugcData, setUgcData] = React.useState<UgcListType>(dataSource);

    const { id, is_focus, name, avatar, introduction } = ugcData || {};

    const [customModal, CustomModal] = useCustomModal(); // 弹窗

    useEffect(() => {
        setUgcData(dataSource);
    }, [dataSource]);

    /** 取消/关注回调 */
    const handleFocus = () => {
        const fun = is_focus === 1 ? focusDelete : focusAddPost;
        fun(id).then((res: any) => {
            if (res?.state === 1) {
                message.success(
                    is_focus === 1
                        ? intl.get('PersonalCenter.UnfollowSuc').d('取消关注成功！')
                        : intl.get('Article.FollowedSuc').d('关注成功！'),
                );
                const data = cloneDeep(ugcData);
                data.is_focus = is_focus === 1 ? 0 : 1;

                setUgcData(data);
            }
        });
    };

    /**
     * 关注/取消关注
     */
    const onFocusUgc = (event: any) => {
        event?.preventDefault();
        if (id) {
            if (is_focus === 1) {
                customModal.confirm({
                    open: true,
                    title: intl.get('Article.WantToUnfollow', { field: name }).d(`确定要取消对 ${name} 的关注吗？`),
                    onOk: () => {
                        handleFocus();
                        customModal.close();
                    },
                });
            } else {
                handleFocus();
            }
        }
    };

    return (
        <div className={styles.item}>
            <a href={currentLocaleUrl(`/ugc/${id}`)} target="_blank" className={styles.item_header}>
                <div className={styles.avatar}>
                    <NextImage layout="fill" src={avatar || ''} place="ugc" />
                </div>
                <div className={styles.info}>
                    <span className={styles.name}>{name || '-'}</span>
                    <p className={styles.intro}>{introduction || '-'}</p>
                </div>
                <div className={c(styles.button, is_focus === 1 ? styles.focus : '')} onClick={onFocusUgc}>
                    {is_focus === 1 ? (
                        intl.get('Article.AlreadyFollowed').d('已关注')
                    ) : (
                        <React.Fragment>
                            <span className={c('iconfont', 'icon-jiahao')} />
                            <span>{intl.get('Article.Followed').d('关注')}</span>
                        </React.Fragment>
                    )}
                </div>
            </a>
            {article_list.slice(0, 1).map((item) => {
                const { id, seo_url, code, title, cover } = item;
                return (
                    <a
                        href={currentLocaleUrl(`/article/${seo_url || code}`)}
                        target="_blank"
                        className={styles.article_list}
                        key={id || code}
                    >
                        <div className={styles.title}>{title}</div>
                        <div className={styles.cover}>
                            <NextImage src={cover?.url} layout="fill" place="place" />
                        </div>
                    </a>
                );
            })}
            {CustomModal}
        </div>
    );
}

export default RenderItem;
