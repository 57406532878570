

import React from 'react';

import TransferCom from '@/components/public/TransferCom';

// import IpadBuyStep from './ipad';
import MNavLineItem from './m';
import PcNavLineItem from './pc';

interface IProps {
    data: any,
    activeTab: number,
    titleKey?: string
}
const NavLineItem: React.FC<IProps> = (props) => {
    return (
        <TransferCom
            m={<MNavLineItem {...props} />}
            ipad={<PcNavLineItem {...props} />}
            pc={<PcNavLineItem {...props} />}
        />
    );
};

export default NavLineItem;
