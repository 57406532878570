import React, { useEffect, useMemo, useState } from 'react';

import { formatDomUnit, getVisualSkipPage } from '@/utils';
import CustomVideos from '@/utils/video';

import { VideoProps } from '../type';
import styles from './index.module.less';
import { VideoList, VideoPreview } from './components';

const PCVideo: React.FC<VideoProps> = (props) => {
    const { customStyle, serviceData, buttonUrl } = props;

    const [activeIndex, setActiveIndex] = useState<number>(0);

    const { list = [] } = serviceData;

    useEffect(() => {
        if (list.length === 0) {
            return;
        }
        // 视频处理
        const width = '100%';
        const height = '100%';
        const players = new CustomVideos({
            selector: '#article-content',
            width,
            height,
        });
        return () => {
            players.dispose();
        };
    }, []);

    const previewData = useMemo(() => list[activeIndex], [list, activeIndex]);

    if (list.length === 0) {
        return null;
    }

    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }} className={styles.video}>
            {/* 背景 */}
            <div className={styles.video__background} />
            <div className={styles.video__container}>
                {/* 左侧预览 */}
                <div className={styles.video__preview} id="article-content">
                    <VideoPreview dataSource={previewData} />
                </div>
                {/* 右侧列表 */}
                <div className={styles.video__list}>
                    <VideoList
                        list={list}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        MoreUrl={getVisualSkipPage(buttonUrl)}
                    />
                </div>
            </div>
        </div>
    );
};

export default PCVideo;
