import React from 'react';
import { NextImage } from '@/components';
import { TagTitle } from '@/reconstruction/components/public';
import c from 'classnames';
import { useAppSelector } from '@/store/hook';
import { currentLocaleUrl, formatDomUnit } from '@/utils';
import intl from 'react-intl-universal';
import type { HotNewsProps } from '../type';
import styles from './index.module.less';

const HotNews: React.FC<HotNewsProps> = ({ serviceData, customStyle }) => {
    const { list: hotList = [] } = serviceData || {};
    const { imgPrefix } = useAppSelector((state: any) => state.config);

    // 带图样式
    const hasImage = (item: any, index: number) => {
        const { title, code, seo_url, cover = [], ugc, originate } = item;

        const { url } = (cover || [])[0] || {};
        return (
            <a href={currentLocaleUrl(`/article/${seo_url || code}`)} className={styles.img_item} target="_blank">
                <div className={styles.left_con}>
                    <div className={styles.text}>
                        <span className={c(styles.num, styles[`num${index + 1}`])}>{index + 1}</span>
                        <span className={styles.title}>{title}</span>
                    </div>

                    <p>{(ugc && ugc.name ? ugc.name : originate) || 'Hawk Insight'}</p>
                </div>

                <div className={styles.cover}>
                    <NextImage src={url} layout="fill" alt={title} place="new_normal" />
                </div>
            </a>
        );
    };

    // 纯文字样式
    const onlyText = (item: any, index: number) => {
        const { title, seo_url, code } = item;

        return (
            <a href={currentLocaleUrl(`/article/${seo_url || code}`)} className={styles.text_item} target="_blank">
                <span className={styles.num}>{index + 1}</span>
                <span className={styles.title}>{title}</span>
            </a>
        );
    };

    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            <div className={styles.hot_news} style={{ backgroundImage: `url('${imgPrefix}/bg/hot-bg.webp')` }}>
                <TagTitle
                    text={[intl.get('HotNews.Hot').d('热门'), intl.get('HotNews.News').d('资讯')]}
                    iconType="hot"
                />
                <ul className={styles.hot_list}>
                    {hotList.map((item: any, index: number) => (
                        <li key={item.code} className={styles.hot_item}>
                            {index < 3 ? hasImage(item, index) : onlyText(item, index)}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default HotNews;
