import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PCAll from './pc';
import MAll from './m';
import type { ActivityProps } from '../../type';

const All: React.FC<ActivityProps> = (props) => {
    return <TransferCom pc={<PCAll {...props} />} m={<MAll {...props} />} ipad={null} />;
};

export default All;
