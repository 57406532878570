import React from 'react';
import TransferCom from '@/components/public/TransferCom';
// import MChildNav from './m';
import PcKeyWord from './pc';
import MKeyWord from './m';
import { KeyWordProps } from './types';

const KeyWord: React.FC<KeyWordProps> = (props) => {
    return <TransferCom m={<MKeyWord {...props} />} ipad={<MKeyWord {...props} />} pc={<PcKeyWord {...props} />} />;
};

export default KeyWord;
