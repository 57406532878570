import React, { useMemo } from 'react';
import BrokerItem from '../../BrokerItem';
import c from 'classnames';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import { currentLocaleUrl } from '@/utils';
import type { BrokerListItemProps } from '../type';

const ListItem: React.FC<BrokerListItemProps> = ({ item: listItem }) => {
    const { id, name, introduction, list = [] } = listItem || {};

    // 只取前三
    const bokerList = useMemo(() => {
        const cloneList = cloneDeep(list || []);
        return cloneList.splice(0, 3);
    }, list);

    /**
     * 前往榜单详情
     * @param event
     * @param id
     */
    const onToMore = (event: any, id: string) => {
        event?.preventDefault();

        window.open(currentLocaleUrl(`/broker/list/detail/${id}`));
    };

    return (
        <div className={styles.billboard}>
            <div className={styles.billboard_top}>
                <a className={styles.title} href={currentLocaleUrl(`/broker/list/detail/${id}`)} target="_blank">
                    {name}
                </a>

                <a className={styles.more} href={currentLocaleUrl(`/broker/list/detail/${id}`)} target="_blank">
                    <span>{intl.get('Common.SeeMore').d('查看更多')}</span>
                    <span className={c('iconfont', 'icon-jiantou', styles.arrow)} />
                </a>
            </div>
            {introduction && (
                <div className={styles.billboard_info} onClick={(e: any) => onToMore(e, id)}>
                    <span>{intl.get('Broker.ListIntroduction').d('榜单简介：')}</span>
                    <span>{introduction}</span>
                </div>
            )}
            {bokerList.length !== 0 ? (
                <ul className={styles.billboard_list}>
                    {bokerList.map((item: any) => {
                        return (
                            <li key={item?.id}>
                                <BrokerItem record={item} />
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <div className={styles.billboard_list_null} />
            )}
        </div>
    );
};

export default ListItem;
