import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { App, Button } from 'antd';
import { BrokerBusinessTag, NextImage, StarLv } from '@/components';
import BrokerMenu from '../BrokerMenu';
import {
    resizeObserverFun,
    PAGE_CENTER_WA,
    PAGE_CENTER_WB,
    formatMts,
    currentLocaleUrl,
    getMtsKey,
    getMtsLocaleKey,
} from '@/utils';
import BrokerSelect from '../BrokerSelect';
import c from 'classnames';
import styles from './index.module.less';
import cloneDeep from 'lodash/cloneDeep';
import intl from 'react-intl-universal';

interface Props {
    list: any[];
    options: any;
    hasArrow?: boolean;
    groupTitle?: string;
    groupTitleMts?: {
        zh_cns?: string;
        en_us?: string;
    };
}

const BrokerContrast: React.FC<Props> = ({ list = [], options, hasArrow, groupTitle, groupTitleMts }) => {
    const { message } = App.useApp();

    const mtsKey = getMtsKey(intl.getInitOptions().currentLocale || 'zh-CN');
    const localeKey = getMtsLocaleKey(intl.getInitOptions().currentLocale || 'zh-CN');

    const domRef = useRef<any>(null);
    const [domWidth, setDomWidth] = useState<number>(PAGE_CENTER_WA);
    useEffect(() => {
        resizeObserverFun(domRef.current, setDomWidth);
    }, []);
    // 对比的交易商
    const [contrastList, setContrastList] = useState<any[]>(list || []);
    // mobile 对比交易商
    const [brokerOpen, setBrokerOpen] = useState<boolean>(false);
    const [changeIndex, setChangeIndex] = useState<number>(0);

    const newList = useMemo(() => {
        return (list || []).map((item: any, i: number) => {
            const { openInfo } = item || {};

            const { type } = openInfo || {};
            const { id, language = [] } = (openInfo || {})[type] || {};
            if (!language.includes(localeKey)) {
                return { key: i, edit: true };
            } else {
                return { ...item, id, key: i, stop: (options || {})[id] !== 1 };
            }
        });
    }, [list, options, mtsKey]);

    useEffect(() => {
        setContrastList(newList);
    }, [newList]);

    // 更换交易商
    const onHandelChange = (index: number) => {
        const cloneList = cloneDeep(contrastList || []);
        cloneList.splice(index, 1, { ...(cloneList[index] || {}), id: undefined, edit: true });
        setContrastList(cloneList);
    };
    const onBrokerChange = (v: any, index: number) => {
        // 该交易商已在对比列表
        const { id, name_abbreviation, business_name, logo, rate, score, seo_url, type } = v || {};
        const cloneList = cloneDeep(contrastList || []);

        if (cloneList.find((item: any) => item.id === id)) {
            message.warning(intl.get('Broker.BrokerLWarnMsg').d('该交易商已在对比列表'));
            return;
        }

        if (
            cloneList.find((item: any) => {
                const { openInfo } = item || {};
                const { broker } = openInfo || {};
                return item.id && broker?.type && broker?.type !== type;
            })
        ) {
            message.warning(intl.get('Broker.OtherLWarnMsg').d('仅支持同种类型交易商进行对比'));
            return;
        }
        const openInfo = {
            type: 'broker',
            broker: {
                id,
                title: name_abbreviation,
                business_name,
                logo,
                rate,
                score,
                seo_url,
                type,
            },
        };

        cloneList.splice(index, 1, { ...(cloneList[index] || {}), id, openInfo, edit: false, stop: false });
        setContrastList(cloneList);
    };

    // 全部清除
    const onClearAll = () => {
        const cloneList = cloneDeep(contrastList || []);
        setContrastList(
            cloneList.map((item: any) => {
                return { key: item.key, edit: true };
            }),
        );
    };

    // 前往对比
    const onToContrast = useCallback(() => {
        const ids = contrastList.map((item: any) => {
            if (item?.edit || item?.stop) {
                return '';
            } else {
                return item?.id;
            }
        });
        const idstr = ids.join('.');
        window.open(currentLocaleUrl(`/broker/contrast?ids=${encodeURIComponent(idstr)}`));
    }, [contrastList]);

    const noHasItem = useMemo(() => {
        return (
            (contrastList || []).filter((item: any) => {
                return !item.edit && item.openInfo && !item.stop;
            }).length === 0
        );
    }, [contrastList]);

    // -----------------h5
    // 更换交易商
    const onMobHandelChange = (index: number) => {
        setBrokerOpen(true);
        setChangeIndex(index);
    };
    // 关闭更换交易商弹窗
    const onBrokerClose = () => {
        setBrokerOpen(false);
    };

    return (
        <div className={styles.contrastBroker} ref={domRef}>
            {hasArrow ? <h2>{formatMts(groupTitleMts) || groupTitle}</h2> : null}

            <ul className={styles.contrastList}>
                {contrastList.map((item: any, index: number) => {
                    const { key, id, openInfo, edit, stop } = item || {};
                    const { type } = openInfo || {};
                    const {
                        title: tit,
                        name_abbreviation_mts,
                        logo,
                        business_name,
                        business_name_mts,
                        score,
                        rate,
                        seo_url,
                    } = (openInfo || {})[type] || {};
                    const title = (name_abbreviation_mts || {})[mtsKey] || tit;
                    const business = (business_name_mts || {})[mtsKey] || business_name;

                    const borederDom = (
                        <>
                            <div className={styles.logo}>
                                <NextImage
                                    src={logo}
                                    layout="fill"
                                    alt={title}
                                    place="logo_a"
                                    className={styles.logoForContrast}
                                />
                            </div>

                            <div className={styles.info}>
                                <h2>{title}</h2>
                                <div className={styles.msg}>
                                    <span className={styles.score}>
                                        <span className={styles.tit}>{intl.get('Broker.Score').d('评分')}：</span>
                                        <span className={styles.num}>{Number(score || 0).toFixed(1)}</span>
                                    </span>
                                    <StarLv
                                        grade={stop ? 0 : Number(rate || 0)}
                                        wrapClassname={styles.starList}
                                        itemClassname={styles.starItem}
                                    />
                                </div>
                            </div>
                        </>
                    );
                    const hasHrefDom = stop ? (
                        <div className={styles.botCon}>{borederDom}</div>
                    ) : (
                        <a
                            className={styles.botCon}
                            href={currentLocaleUrl(`/broker/${seo_url || id}`)}
                            target="_blank"
                        >
                            {borederDom}
                        </a>
                    );
                    return (
                        <li key={key} className={c(stop ? styles.stop : null)}>
                            {!edit || domWidth < PAGE_CENTER_WB ? (
                                <>
                                    <div className={styles.topCon}>
                                        <span
                                            onClick={() => {
                                                onHandelChange(index);
                                            }}
                                        >
                                            <span className={styles.a}>{intl.get('Broker.Replace').d('更换')}</span>
                                            <span className={styles.b}>
                                                {intl.get('Broker.ChangeBroker').d('更换交易商')}
                                            </span>
                                        </span>
                                    </div>
                                    {!edit ? (
                                        hasHrefDom
                                    ) : (
                                        <a
                                            className={c(styles.botCon, styles.botConEdit)}
                                            href={currentLocaleUrl(`/broker/${seo_url || id}`)}
                                            target="_blank"
                                        >
                                            <div className={styles.logo}>
                                                <NextImage src="/img/place/img_logo_a.webp" layout="fill" alt={title} />
                                            </div>

                                            <div className={styles.info}>
                                                <h2>—</h2>
                                                <div className={styles.msg}>
                                                    <span className={styles.score}>
                                                        <span className={styles.tit}>
                                                            {intl.get('Broker.Score').d('评分')}：
                                                        </span>
                                                        <span className={styles.num}>{(0).toFixed(1)}</span>
                                                    </span>
                                                    <StarLv
                                                        grade={0}
                                                        wrapClassname={styles.starList}
                                                        itemClassname={styles.starItem}
                                                    />
                                                </div>
                                            </div>
                                        </a>
                                    )}

                                    <div className={styles.changeCon}>
                                        <span
                                            onClick={() => {
                                                onMobHandelChange(index);
                                            }}
                                        >
                                            {intl.get('Broker.ChangeBroker').d('更换交易商')}
                                        </span>
                                        <span className={c(styles.arrow, 'iconfont', 'icon-arrow-right')} />
                                    </div>
                                    <BrokerBusinessTag name={business} site="leftTop" />
                                </>
                            ) : (
                                <div className={styles.topEdit}>
                                    <BrokerSelect
                                        index={index}
                                        dataList={contrastList}
                                        onChange={(v: any) => {
                                            onBrokerChange(v, index);
                                        }}
                                    />
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>

            <p className={styles.clearBtn}>
                <span onClick={onClearAll}>{intl.get('Broker.ClearTraders').d('清空全部交易商')}</span>
            </p>
            <Button className={styles.subBtn} disabled={noHasItem} onClick={onToContrast} aria-label="Contrast">
                {intl.get('Broker.ToCompare').d('去对比')}
                <span className={styles.back} />
            </Button>

            <BrokerMenu
                open={brokerOpen}
                index={changeIndex}
                dataList={contrastList}
                onChange={onBrokerChange}
                onClose={onBrokerClose}
            />
        </div>
    );
};

export default BrokerContrast;
