import React from 'react';

import { VisualTopicHome, VisualTopicAttention, VisualTopicRecommend } from './components';
import { type VisualTopicProps } from './type';

const Topic: React.FC<VisualTopicProps> = (props: VisualTopicProps) => {
    const { styleType = '1' } = props;

    return (
        <>
            {styleType === '1' && <VisualTopicHome {...props} />}
            {styleType === '2' && <VisualTopicAttention {...props} />}
            {styleType === '3' && <VisualTopicRecommend {...props} />}
        </>
    );
};

export default Topic;
