import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import MTopBroker from './m';
import PcTopBroker from './pc';
import type { TopBrokerProps } from './type';

const TopBroker: React.FC<TopBrokerProps> = (props) => {
    return (
        <TransferCom m={<MTopBroker {...props} />} ipad={<PcTopBroker {...props} />} pc={<PcTopBroker {...props} />} />
    );
};

export default TopBroker;
