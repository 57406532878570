import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MActivitys from './m';
import PcActivitys from './pc';

interface Props {
    list: any[];
}

const Topic: React.FC<Props> = (props: Props) => {
    return (
        <TransferCom m={<MActivitys {...props} />} ipad={<PcActivitys {...props} />} pc={<PcActivitys {...props} />} />
    );
};

export default Topic;
