import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { formatDomUnit, resizeObserverFun, PAGE_CENTER_WA, PAGE_CENTER_WC } from '@/utils';
import { LoadMore } from '@/components';
import { Tabs } from '@/reconstruction/components/public';
import styles from './index.module.less';
import { Skeleton } from 'antd';
import { scriptRegex } from '@/constants/regex';
import c from 'classnames';
import clone from 'lodash/clone';
import intl from 'react-intl-universal';
import NavTabItem from './components/NavTabItem';

interface Props {
    elementId: string;
    navs: any[];
    customStyle: any;
    code?: string;
    navSwitch?: boolean;
    shadeSwitch?: boolean;
}

const Market: React.FC<Props> = (params) => {
    const { elementId, navs, customStyle, shadeSwitch, code = '', navSwitch } = params;
    const wrapRef = useRef<any>(null);
    const domRef = useRef<any>(null);
    const [activeKey, setActiveKey] = useState<number>(0);
    const [activeItem, setActiveItem] = useState<any>({});
    const [twJsLoading, setTwJsLoading] = useState<boolean>(false);
    const [twJsError, setTwJsError] = useState<boolean>(false);
    const [jsMap, setJsMap] = useState<any[]>([]); // 已加载过的js
    const [domtWidth, setDomWidth] = useState<number>(PAGE_CENTER_WA); // 宽度

    useEffect(() => {
        resizeObserverFun(domRef.current, setDomWidth);
    }, []);
    const isMobile = useMemo(() => {
        return domtWidth <= PAGE_CENTER_WC;
    }, [domtWidth]); // 是否移动端

    // 当前小工具代码
    const activeCode = useMemo(() => {
        return (navSwitch ? activeItem?.code : code) || '';
    }, [activeItem, code, navSwitch]);

    /** 去除code里的script代码 */
    const delScript = useCallback((str: string) => {
        return str.replace(scriptRegex.scriptTagRegex, '');
    }, []);
    /** 加载js */
    const loadScript = useCallback(
        (src: string, async: boolean, content: string) => {
            setTwJsLoading(true);
            setTwJsError(false);
            const iframeElements = wrapRef.current.getElementsByTagName('iframe');
            // 删除所有的 iframe 标签
            while (iframeElements.length > 0) {
                const iframeElement = iframeElements[0];
                iframeElement.parentNode.removeChild(iframeElement);
            }
            const script = document.createElement('script');
            script.src = src;
            script.async = async;
            script.innerHTML = content;
            script.onload = () => {
                setJsMap([...jsMap, src]);
                setTwJsLoading(false);
            };
            script.onerror = () => {
                setJsMap([...jsMap, src]);
                setTwJsLoading(false);
                setTwJsError(true);
            };
            wrapRef.current?.insertBefore?.(script, wrapRef.current?.childNodes?.[0]);
        },
        [jsMap],
    );
    /** 执行script代码 */
    const execScript = useCallback(
        (content: string, src: string) => {
            try {
                const script = document.querySelector(`script[src='${src}']`);
                if (!script) {
                    const cloneArr = clone(jsMap);
                    setJsMap(
                        cloneArr.filter((item) => {
                            return item !== src;
                        }),
                    );
                }
                window?.eval?.(content);
            } catch (e) {}
        },
        [jsMap],
    );

    //----------- 小工具script执行
    useEffect(() => {
        // 执行小工具代码
        if (twJsLoading || !activeCode) return;
        const match = activeCode.match(scriptRegex.scriptTagRegex) || [];
        if (match.length === 0) return;
        for (const srcStr of match) {
            const src = srcStr.match(scriptRegex.srcRegex)?.[1] || ''; // src内容
            const async = scriptRegex.asyncRegex.test(srcStr); // async 内容
            const content = srcStr.match(scriptRegex.scriptContentRegex)?.[1] || ''; // srcipt内容
            const isLoad = src && jsMap.includes(src); // 是否已加载
            if (isLoad) {
                execScript(content, src);
            } else {
                loadScript(src, async, content);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCode, twJsLoading]);
    //--------- 导航切换
    useEffect(() => {
        if (navs.length !== 0) {
            const obj = navs.find((_, index) => index === activeKey) || {};
            setActiveItem(obj || {});
        }
    }, [activeKey, navs]);

    const onChangeTabs = (key: number, item: any) => {
        setActiveKey(item.index);
        setActiveItem(item || {});
    };

    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }} ref={domRef}>
            <div className={styles.market_con}>
                {navSwitch && (
                    <Tabs
                        isFixed
                        elementId={elementId}
                        list={navs}
                        onTabChange={onChangeTabs}
                        defaultActiveTab={activeKey}
                        itemRender={(data, tab) => <NavTabItem data={data} activeTab={tab} />}
                    />
                )}
                {twJsLoading && (
                    <React.Fragment>
                        <Skeleton />
                        <Skeleton style={{ marginTop: 24 }} />
                    </React.Fragment>
                )}
                {twJsError && (
                    <LoadMore
                        isMobile={isMobile}
                        emptyText={intl.get('Common.NoData').d('暂无数据')}
                        hasMore={false}
                        empty={twJsError}
                    />
                )}
                <div
                    ref={wrapRef}
                    className={c(styles.wrapper, { [styles.shade]: shadeSwitch })}
                    dangerouslySetInnerHTML={{ __html: delScript(activeCode) }}
                />
            </div>
        </div>
    );
};

export default Market;
