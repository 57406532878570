import TransferCom from '@/components/public/TransferCom';
import MContrast from './m';
import PCContrast from './pc';
import type { ContrastPros } from '../../types';

const Recommend: React.FC<ContrastPros> = (props) => {
    return <TransferCom m={<MContrast {...props} />} ipad={<PCContrast {...props} />} pc={<PCContrast {...props} />} />;
};

export default Recommend;
