import React, { useMemo } from 'react';
import { formatDomUnit } from '@/utils';
import AllBroker from '../components/AllBroker';
import Billboard from '../components/Billboard';
import Contrast from '../components/Contrast';
import HomeBroker from '../components/HomeBroker';

import type { BrokerProps } from '../type';

const Broker: React.FC<BrokerProps> = ({
    elementId,
    styleType,
    groupTitle,
    groupTitleMts,
    customStyle,
    serviceData,
    hasArrow,
    moreInfo,
    navs = [],
    contrastList = [],
}) => {
    const Element = useMemo(() => {
        const normalProps = {
            elementId,
            groupTitle,
            groupTitleMts,
            hasArrow,
        };

        switch (styleType) {
            case '1': // 全部交易商
                return <AllBroker serviceData={serviceData as any} moreInfo={moreInfo} {...normalProps} />;
            case '2': // 榜单
                return <Billboard serviceData={serviceData as any} moreInfo={moreInfo} {...normalProps} />;
            case '3': // 对比
                return <Contrast serviceData={serviceData as any} {...normalProps} />;
            case '4': // 首页交易商
                return <HomeBroker serviceData={serviceData as any} moreInfo={moreInfo} />;
            default:
                return null;
        }
    }, [styleType, elementId, groupTitle, groupTitleMts, navs, contrastList, hasArrow, moreInfo, serviceData]);

    return <div style={{ ...formatDomUnit(customStyle || {}) }}>{Element}</div>;
};

export default Broker;
