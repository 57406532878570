import { createContext } from 'react';
import type { BrokerBusinessType, BrokerFilterType, BrokerSortType } from '@/type/broker';

interface DictType {
    dict_key: number;
    dict_name: string[];
    dict_type: string;
}

interface AllBrokerContextType {
    /** 筛选数据 */
    filterList: BrokerFilterType[];
    /** 当前筛选 */
    currentFilter: BrokerFilterType[];
    /** 筛选点击 */
    filterClickHandler: (
        item: BrokerFilterType,
        value: string,
        type: 'checkbox' | 'slider' | 'country' | 'other',
    ) => void;
    /** 滑块筛选 */
    sliderFilterChangeHandler: (record: BrokerFilterType, value: [number, number]) => void;
    /** 筛选数据 */
    businessList: BrokerBusinessType[];
    /** 重置筛选 */
    clearFilterHandler: () => void;
    /** 当前业务 */
    businessActiveIndex: number;
    /** 业务点击 */
    businessClickHandler: (item: BrokerBusinessType, index: number) => void;
    /** 排序项列表 */
    sortList: BrokerSortType[];
    /** 排序项下标 */
    sortActiveIndex: number;
    /** 排序点击 */
    sortClickHandler: (index: number) => void;
    /** 筛选数据 */
    sortData: {
        value: string;
        label: any;
    }[];
    /** 成立时间数据 */
    yearData?: BrokerFilterType;
    /** 国家地区数据 */
    countryData?: BrokerFilterType;
    /** 服务端数据 */
    serviceData: {
        list: any[];
        hasMore: boolean;
    };
    extraParams?: {
        sort_field: string;
        sort_type: number;
        broker_screen: (
            | DictType
            | {
                  dict_type: string;
                  dict_name: never[];
                  dict_key: any;
              }
        )[];
        country: any[];
        is_all_country: number;
    };
    /** 对比点击 */
    handleContrast: (record: any) => void;
}

/** 全部交易商 */
export const AllBrokerContext = createContext<AllBrokerContextType>({
    /** 筛选数据 */
    filterList: [],
    /** 当前筛选 */
    currentFilter: [],
    /** 业务点击 */
    filterClickHandler: () => {},
    /** 滑块筛选 */
    sliderFilterChangeHandler: () => {},
    /** 重置筛选 */
    clearFilterHandler: () => {},
    /** 筛选数据 */
    businessList: [],
    /** 排序项列表 */
    sortList: [],
    /** 筛选数据 */
    sortData: [],
    /** 成立时间数据 */
    yearData: {
        name: '',
        value: '',
        key: [],
    },
    /** 国家地区数据 */
    countryData: {
        name: '',
        value: '',
        key: [],
    },
    /** 排序项下标 */
    sortActiveIndex: 0,
    /** 排序点击 */
    sortClickHandler: () => {},
    /** 当前业务 */
    businessActiveIndex: 0,
    /** 业务点击 */
    businessClickHandler: () => {},
    /** 服务端数据 */
    serviceData: {
        list: [],
        hasMore: false,
    },

    extraParams: undefined,
    /** 对比点击 */
    handleContrast: () => {},
});
