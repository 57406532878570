import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import MBrokerList from './m';
import PcBrokerList from './pc';
import type { BrokerListItemProps } from './type';

const BrokerList: React.FC<BrokerListItemProps> = (props) => {
    return (
        <TransferCom
            m={<MBrokerList {...props} />}
            ipad={<PcBrokerList {...props} />}
            pc={<PcBrokerList {...props} />}
        />
    );
};

export default BrokerList;
