import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MContactList from './m';
import PcContactList from './pc';
import type { ContactListProps } from './type';

const ContactList: React.FC<ContactListProps> = (props) => {
    return (
        <TransferCom
            m={<MContactList {...props} />}
            ipad={<PcContactList {...props} />}
            pc={<PcContactList {...props} />}
        />
    );
};

export default ContactList;
