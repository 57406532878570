import React, { useMemo, useCallback } from 'react';
import { FirstSwiper, SecondSwiper, ThirdSwiper, FourthSwiper } from '@/reconstruction/components/public/CustomSwiper';
import c from 'classnames';
import styles from './index.module.less';
import { getUrl } from '@/utils/url';
import { SwiperProps } from '../type';

const Swiper: React.FC<SwiperProps> = ({
    elementId,
    styleType,
    isSwitch,
    interval = 5,
    visualSwiperList = [],
    visualSwiperList0 = [],
    visualSwiperList1 = [],
    visualSwiperList2 = [],
    visualSwiperList3 = [],
    visualSwiperList4 = [],
}) => {
    const formList = useCallback(
        (l: any[]) => {
            return l.map((item, index) => {
                return { ...item, openUrl: getUrl(item.openInfo), key: index };
            });
        },
        [getUrl],
    );

    const swiperData = useMemo(() => {
        if (styleType === '2') {
            const fList = formList(visualSwiperList0);
            const sList = [
                {
                    key: 1,
                    list: formList(visualSwiperList1),
                },
                {
                    key: 2,
                    list: formList(visualSwiperList2),
                },
                {
                    key: 3,
                    list: formList(visualSwiperList3),
                },
                {
                    key: 4,
                    list: formList(visualSwiperList4),
                },
            ];

            return [fList, sList];
        } else {
            return formList(visualSwiperList);
        }
    }, [
        formList,
        styleType,
        visualSwiperList,
        visualSwiperList0,
        visualSwiperList1,
        visualSwiperList2,
        visualSwiperList3,
        visualSwiperList4,
    ]);

    const normalProps: any = {
        elementId,
        interval,
        styleType,
        swiperList: swiperData,
    };

    return (
        <div className={c(styles.swiperContainBox)}>
            {
                {
                    // 默认
                    '1': <FirstSwiper {...normalProps} isSwitch={isSwitch} />,
                    // 多图
                    '2': <SecondSwiper {...normalProps} />,
                    // 超版心
                    '3': <ThirdSwiper {...normalProps} />,
                    // 视频
                    '4': <FourthSwiper {...normalProps} />,
                }[styleType]
            }
        </div>
    );
};

export default Swiper;
