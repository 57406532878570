import React, { useState, useMemo, useRef, useEffect } from 'react';
import { NextImage, StarLv, LoadMore } from '@/components';
import dayjs from 'dayjs';
import c from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Mousewheel, Navigation } from 'swiper';
import {
    resizeObserverFun,
    PAGE_CENTER_NORMAL,
    PAGE_CENTER_WA,
    PAGE_CENTER_WB,
    formatMts,
    currentLocaleUrl,
} from '@/utils';
import Link from 'next/link';
import styles from './index.module.less';
import intl from 'react-intl-universal';

SwiperCore.use([Autoplay, Mousewheel, Navigation]);

interface Props {
    options: any;
    elementId: any;
    hasArrow?: boolean;
    groupTitle?: string;
    groupTitleMts?: {
        zh_cns?: string;
        en_us?: string;
    };
}

const BrokerSwiper: React.FC<Props> = ({ options, elementId, hasArrow, groupTitle, groupTitleMts }) => {
    const domRef = useRef<any>(null);
    const [domWidth, setDomWidth] = useState<number>(PAGE_CENTER_WA);
    useEffect(() => {
        resizeObserverFun(domRef.current, setDomWidth);
    }, []);

    const formatFiveYear = (v: any) => {
        const num = dayjs().diff(dayjs(v), 'year', true);
        const count = Math.floor(num / 5);
        if (count < 1) {
            return intl.get('Broker.VithinFiveYear').d('5年内');
        }
        return intl.get('Broker.MoreYear', { field: count * 5 }).d(`${count * 5}年以上`);
    };

    const { list = [] } = options || {};

    const arrayChunk = (len: number, list: any[]) => {
        if (len <= 1 || list.length < len) return list;

        const groups = [];
        const loop = Math.ceil(list.length / len);

        for (let i = 0; i < loop; i++) groups.push(list.slice(len * i, len * (i + 1)));

        return groups;
    };

    const slideNum = useMemo(() => {
        let result;
        if (domWidth < PAGE_CENTER_WA) {
            result = 2;
        } else if (domWidth < PAGE_CENTER_NORMAL) {
            result = 3;
        } else {
            result = 4;
        }
        return result;
    }, [domWidth]);

    const newList = useMemo(() => {
        const len = (list || []).length;
        if (len === 0) {
            return [];
        }
        const nList = (list || [])
            .map((item: any, i: number) => {
                return { ...item, key: i };
            })
            .slice(0, 10);
        const noList = [...nList, ...nList, ...nList, ...nList];
        return [...noList].map((item: any, i: number) => {
            return { ...item, key: i };
        });
    }, [list]);

    const gridList = useMemo(() => {
        if ((list || []).length === 0) {
            return [];
        }
        const nList = (list || []).slice(0, 10);
        const noList = [...nList, ...nList, ...nList, ...nList].map((item: any, i: number) => {
            return { ...item, key: i };
        });
        return arrayChunk(4, noList).map((item: any, i: number) => {
            return { list: item, key: i };
        });
    }, [list]);

    return (
        <div className={styles.swiperContainer} ref={domRef}>
            {hasArrow ? (
                <h2>
                    <span>{formatMts(groupTitleMts) || groupTitle}</span>
                    <Link href="/broker/all">
                        <a target="_blank">
                            <NextImage
                                src="/broker/icon/circle_arrow.webp"
                                needPrefix
                                width={24}
                                height={24}
                                alt="arrow"
                            />
                        </a>
                    </Link>
                </h2>
            ) : null}
            {(list || []).length !== 0 ? (
                <>
                    <Swiper
                        loop
                        observer
                        resizeObserver
                        slidesPerView={slideNum}
                        spaceBetween={24}
                        // grid={{ rows: 2 }} //当设置row>1时目前还不兼容loop模式（loop: true)
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        mousewheel
                        navigation={{
                            nextEl: `.swiper-${elementId} .swiper-button-next`,
                            prevEl: `.swiper-${elementId} .swiper-button-prev`,
                        }}
                        className={styles.swiperConForPc}
                    >
                        {newList.map((item: any, i: number) => {
                            const { id, key, logo, name_abbreviation, score, rate, year, license, seo_url } = item;
                            const sList = (license || []).map((item: any, j: number) => {
                                return { name: item, key: j };
                            });
                            const num = i % slideNum;

                            return (
                                <SwiperSlide className={styles.swiperSlide} key={key}>
                                    <div className={styles.swiperSlideCon}>
                                        <a
                                            href={currentLocaleUrl(`/broker/${seo_url || id}`)}
                                            target="_blank"
                                            className={styles.swiperSlideBox}
                                        >
                                            <div className={styles.swiperSlideBoxTop}>
                                                <div className={styles.logoForRecommend}>
                                                    <NextImage
                                                        src={logo}
                                                        alt={name_abbreviation}
                                                        place="logo_a"
                                                        width={64}
                                                        height={64}
                                                    />
                                                </div>
                                                <div className={styles.swiperSlideBoxInfo}>
                                                    <h2 title={name_abbreviation}>{name_abbreviation}</h2>
                                                    <p className={styles.score}>
                                                        <span>{intl.get('Broker.Score').d('评分')}：</span>
                                                        <span>{Number(score || 0).toFixed(1)}</span>
                                                    </p>
                                                    <StarLv grade={Number(rate || 0)} />
                                                </div>
                                            </div>

                                            <div className={styles.swiperSlideBoxBot}>
                                                <span>{formatFiveYear(year)} </span>
                                                {sList.map((sitem: any) => {
                                                    return <span key={sitem.key}>{sitem.name} </span>;
                                                })}
                                            </div>
                                        </a>
                                        <div className={c(styles.swiperBg, styles[`swiperBg${num}`])} />
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                        <div className={`swiper-${elementId}`}>
                            <div className={c(styles.swiperBtnNext, 'swiper-button-next')} />
                            <div className={c(styles.swiperBtnPrev, 'swiper-button-prev')} />
                        </div>
                    </Swiper>

                    <Swiper
                        loop
                        observer
                        resizeObserver
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        spaceBetween={24}
                        className={styles.swiperConForMob}
                    >
                        {gridList.map((gridItem: any) => {
                            return (
                                <SwiperSlide className={styles.swiperSlide} key={gridItem.key}>
                                    <div className={styles.swiperSlideList}>
                                        {(gridItem?.list || []).map((item: any, i: number) => {
                                            const {
                                                id,
                                                key,
                                                logo,
                                                name_abbreviation,
                                                score,
                                                rate,
                                                year,
                                                license,
                                                seo_url,
                                            } = item;
                                            const sList = (license || []).map((item: any, j: number) => {
                                                return { name: item, key: j };
                                            });
                                            return (
                                                <div key={key} className={styles.swiperSlideItem}>
                                                    <a
                                                        href={currentLocaleUrl(`/broker/${seo_url || id}`)}
                                                        target="_blank"
                                                        className={styles.swiperSlideBox}
                                                    >
                                                        <div className={styles.swiperSlideBoxTop}>
                                                            <div className={styles.logoForRecommend}>
                                                                <NextImage
                                                                    src={logo}
                                                                    alt={name_abbreviation}
                                                                    place="logo_a"
                                                                    width={45}
                                                                    height={45}
                                                                />
                                                            </div>
                                                            <div className={styles.swiperSlideBoxInfo}>
                                                                <h2 title={name_abbreviation}>{name_abbreviation}</h2>
                                                                <p className={styles.score}>
                                                                    <span>{intl.get('Broker.Score').d('评分')}：</span>
                                                                    <span>{Number(score || 0).toFixed(1)}</span>
                                                                </p>
                                                                <StarLv
                                                                    grade={Number(rate || 0)}
                                                                    wrapClassname={styles.grade}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={styles.swiperSlideBoxBot}>
                                                            <span>{formatFiveYear(year)} </span>
                                                            {sList.map((sitem: any) => {
                                                                return <span key={sitem.key}>{sitem.name} </span>;
                                                            })}
                                                        </div>
                                                    </a>
                                                    <div className={c(styles.swiperBg, styles[`swiperBg${i}`])} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </>
            ) : (
                <div className={styles.loadMore}>
                    <LoadMore
                        isMobile={domWidth < PAGE_CENTER_WB}
                        empty={true}
                        emptyText={intl.get('Broker.NoLeadTrader').d('暂无头部交易商数据')}
                    />
                </div>
            )}
        </div>
    );
};

export default BrokerSwiper;
