import React from 'react';
import styles from './index.module.less';
import { ActivityListItem } from '@/reconstruction/components/listItem';

interface Props {
    list: any[];
}

const PcActivitys: React.FC<Props> = ({ list }) => {
    return (
        <div className={styles.list}>
            <div className={styles.listRow}>
                {list.map((item) => {
                    return (
                        <div key={item.id} className={styles.listCol}>
                            <ActivityListItem dataSource={item} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PcActivitys;
