import { apiLangHeaderMap } from '@/constants/mts';

export default (lang: string) => {
    const toolLang = apiLangHeaderMap[lang] || 'en';

    return [
        {
            title: '复利计算器',
            mts: 'Broker.TradeTool1',
            introduction: '使用外汇复利计算器来计算预设的ROI百分比下每周期可以得复利多少。',
            introduction_mts: 'Broker.TradeToolIntroduction1',
            src: 'https://www.cashbackforex.com/Content/remote/remote-widgets.js',
            id: 'compounding-calculator-877344',
            content: `
                RemoteCalc({
                    "Url":"https://www.cashbackforex.com",
                    "TopPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmNvbG9yOiAjMjYyNjI2ICAhaW1wb3J0YW50OwpwYWRkaW5nOiAwOwpib3JkZXI6IG5vbmU7CmZvbnQtd2VpZ2h0OiAgbm9ybWFsOw==",
                    "BottomPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmJvcmRlcjogbm9uZTsKY29sb3I6IGJsYWNrOwpmb250LXdlaWdodDogIG5vcm1hbDs=",
                    "ButtonStyle":"YmFja2dyb3VuZDogIzJjMzZkZTsKY29sb3I6IHdoaXRlOwpib3JkZXItcmFkaXVzOiA0cHg7CmhlaWdodDogNDhweDsKd2lkdGg6IDQ3LjglOwo=",
                    "TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7CmJvcmRlcjogc29saWQgMXB4ICNlNWU1ZTUgIWltcG9ydGFudDsgCmNvbG9yOiAjMjYyNjI2OwoK",
                    "ContainerWidth":"992",
                    "HighlightColor":"#ffff00",
                    "IsDisplayTitle":false,
                    "IsShowEmbedButton":false,
                    "Lang":"${toolLang}",
                    "CompactType":"large",
                    "DefaultBalance":"100",
                    "DefaultPeriod":"12",
                    "DefaultGain":"2",
                    "Calculator":"compounding-calculator",
                    "ContainerId":"compounding-calculator-877344",
                    "AffiliateId":"primary",
                });
            `,
            mId: 'compounding-calculator-251868',
            mContent: `
                RemoteCalc({
                    "Url":"https://www.cashbackforex.com",
                    "TopPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmNvbG9yOiBibGFjazsKcGFkZGluZzogMDsKYm9yZGVyOiBub25lOw==",
                    "BottomPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmJvcmRlcjogbm9uZTsKY29sb3I6IGJsYWNrOw==",
                    "ButtonStyle":"YmFja2dyb3VuZDogIzJjMzZkZTsKY29sb3I6IHdoaXRlOwpib3JkZXItcmFkaXVzOiA0cHg7CmhlaWdodDogMzRweDsKd2lkdGg6IDEwMCU7Cg==",
                    "TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7CmNvbG9yOiAyNjI2MjY7CmJvcmRlcjogc29saWQgMXB4ICNlNWU1ZTU7IApjb2xvcjogIzI2MjYyNjsKCg==",
                    "HighlightColor":"#ffff00",
                    "IsDisplayTitle":false,
                    "IsShowEmbedButton":false,
                    "Lang":"${toolLang}",
                    "CompactType":"large",
                    "DefaultBalance":"100",
                    "DefaultPeriod":"12",
                    "DefaultGain":"2",
                    "Calculator":"compounding-calculator",
                    "ContainerId":"compounding-calculator-251868"
                });
            `,
        },
        {
            title: '保证金计算器',
            mts: 'Broker.TradeTool2',
            introduction: '使用外汇保证金计算器，您可以根据汇商提供的杠杆率确切地知道建仓头寸所需的保证金。',
            introduction_mts: 'Broker.TradeToolIntroduction2',
            src: 'https://www.cashbackforex.com/Content/remote/remote-widgets.js',
            id: 'margin-calculator-177582',
            content: `
                RemoteCalc({
                    "Url":"https://www.cashbackforex.com",
                    "TopPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmNvbG9yOiAjMjYyNjI2ICAhaW1wb3J0YW50OwpwYWRkaW5nOiAwOwpib3JkZXI6IG5vbmU7CmZvbnQtd2VpZ2h0OiAgbm9ybWFsOw==",
                    "BottomPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmJvcmRlcjogbm9uZTsKY29sb3I6IGJsYWNrOwpmb250LXdlaWdodDogIG5vcm1hbDs=",
                    "ButtonStyle":"YmFja2dyb3VuZDogIzJjMzZkZTsKY29sb3I6IHdoaXRlOwpib3JkZXItcmFkaXVzOiA0cHg7CmhlaWdodDogNDhweDsKd2lkdGg6IDQ3LjglOwo=",
                    "TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7CmJvcmRlcjogc29saWQgMXB4ICNlNWU1ZTUgIWltcG9ydGFudDsgCmNvbG9yOiAjMjYyNjI2OwoK",
                    "ContainerWidth":"992",
                    "HighlightColor":"#ffff00",
                    "IsDisplayTitle":false,
                    "IsShowChartLinks":false,
                    "IsShowEmbedButton":false,
                    "Lang":"${toolLang}",
                    "CompactType":"large",
                    "Calculator":"margin-calculator",
                    "ContainerId":"margin-calculator-177582"
                });
            `,
            mId: 'margin-calculator-503045',
            mContent: `
                RemoteCalc({
                    "Url":"https://www.cashbackforex.com",
                    "TopPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmNvbG9yOiBibGFjazsKcGFkZGluZzogMDsKYm9yZGVyOiBub25lOw==",
                    "BottomPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmJvcmRlcjogbm9uZTsKY29sb3I6IGJsYWNrOw==",
                    "ButtonStyle":"YmFja2dyb3VuZDogIzJjMzZkZTsKY29sb3I6IHdoaXRlOwpib3JkZXItcmFkaXVzOiA0cHg7CmhlaWdodDogMzRweDsKd2lkdGg6IDEwMCU7Cg==",
                    "TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7CmNvbG9yOiAyNjI2MjY7CmJvcmRlcjogc29saWQgMXB4ICNlNWU1ZTU7IApjb2xvcjogIzI2MjYyNjsKCg==",
                    "ContainerWidth":"769",
                    "HighlightColor":"#ffff00",
                    "IsDisplayTitle":false,
                    "IsShowChartLinks":false,
                    "IsShowEmbedButton":false,
                    "Lang":"${toolLang}",
                    "CompactType":"large",
                    "Calculator":"margin-calculator",
                    "ContainerId":"margin-calculator-503045"
                });
            `,
        },
        {
            title: '斐波那契计算器',
            mts: 'Broker.TradeTool3',
            introduction: '使用斐波那契回撤计算器准确绘制任何外汇货币对或其他金融工具的斐波那契回撤水平。',
            introduction_mts: 'Broker.TradeToolIntroduction3',
            src: 'https://www.cashbackforex.com/Content/remote/remote-widgets.js',
            id: 'fibonacci-calculator-842973',
            content: `
                RemoteCalc({
                    "Url":"https://www.cashbackforex.com",
                    "TopPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmNvbG9yOiAjMjYyNjI2ICAhaW1wb3J0YW50OwpwYWRkaW5nOiAwOwpib3JkZXI6IG5vbmU7CmZvbnQtd2VpZ2h0OiAgbm9ybWFsOw==",
                    "BottomPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmJvcmRlcjogbm9uZTsKY29sb3I6IGJsYWNrOwpmb250LXdlaWdodDogIG5vcm1hbDs=",
                    "ButtonStyle":"YmFja2dyb3VuZDogIzJjMzZkZTsKY29sb3I6IHdoaXRlOwpib3JkZXItcmFkaXVzOiA0cHg7CmhlaWdodDogNDhweDsKd2lkdGg6IDQ3LjglOwo=",
                    "TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7CmJvcmRlcjogc29saWQgMXB4ICNlNWU1ZTUgIWltcG9ydGFudDsgCmNvbG9yOiAjMjYyNjI2OwoK",
                    "ContainerWidth":"992",
                    "HighlightColor":"#ffff00",
                    "IsDisplayTitle":false,
                    "IsShowEmbedButton":false,
                    "Lang":"${toolLang}",
                    "CompactType":"large",
                    "Calculator":"fibonacci-calculator",
                    "ContainerId":"fibonacci-calculator-842973"
                });
            `,
            mId: 'fibonacci-calculator-647882',
            mContent: `
                RemoteCalc({
                    "Url":"https://www.cashbackforex.com",
                    "TopPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmNvbG9yOiBibGFjazsKcGFkZGluZzogMDsKYm9yZGVyOiBub25lOw==",
                    "BottomPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmJvcmRlcjogbm9uZTsKY29sb3I6IGJsYWNrOw==",
                    "ButtonStyle":"YmFja2dyb3VuZDogIzJjMzZkZTsKY29sb3I6IHdoaXRlOwpib3JkZXItcmFkaXVzOiA0cHg7CmhlaWdodDogMzRweDsKd2lkdGg6IDEwMCU7Cg==",
                    "TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7CmNvbG9yOiAyNjI2MjY7CmJvcmRlcjogc29saWQgMXB4ICNlNWU1ZTU7IApjb2xvcjogIzI2MjYyNjsKCg==",
                    "ContainerWidth":"769",
                    "HighlightColor":"#ffff00",
                    "IsDisplayTitle":false,
                    "IsShowEmbedButton":false,
                    "Lang":"${toolLang}",
                    "CompactType":"large",
                    "Calculator":"fibonacci-calculator",
                    "ContainerId":"fibonacci-calculator-647882"
                });
            `,
        },
        {
            title: '利润计算器',
            mts: 'Broker.TradeTool4',
            introduction: '使用外汇利润计算器，可以根据手数，账户货币等条件查看潜在的交易损益。',
            introduction_mts: 'Broker.TradeToolIntroduction4',
            src: 'https://www.cashbackforex.com/Content/remote/remote-widgets.js',
            id: 'profit-calculator-401622',
            content: `
                RemoteCalc({
                    "Url":"https://www.cashbackforex.com",
                    "TopPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmNvbG9yOiAjMjYyNjI2ICAhaW1wb3J0YW50OwpwYWRkaW5nOiAwOwpib3JkZXI6IG5vbmU7CmZvbnQtd2VpZ2h0OiAgbm9ybWFsOw==",
                    "BottomPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmJvcmRlcjogbm9uZTsKY29sb3I6IGJsYWNrOwpmb250LXdlaWdodDogIG5vcm1hbDs=",
                    "ButtonStyle":"YmFja2dyb3VuZDogIzJjMzZkZTsKY29sb3I6IHdoaXRlOwpib3JkZXItcmFkaXVzOiA0cHg7CmhlaWdodDogNDhweDsKd2lkdGg6IDQ3LjglOwo=",
                    "TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7CmJvcmRlcjogc29saWQgMXB4ICNlNWU1ZTUgIWltcG9ydGFudDsgCmNvbG9yOiAjMjYyNjI2OwoK",
                    "ContainerWidth":"992",
                    "HighlightColor":"#ffff00",
                    "IsDisplayTitle":false,
                    "IsShowChartLinks":false,
                    "IsShowEmbedButton":false,
                    "Lang":"${toolLang}",
                    "CompactType":"large",
                    "Calculator":"profit-calculator",
                    "ContainerId":"profit-calculator-401622"
                });
            `,
            mId: 'profit-calculator-594509',
            mContent: `
                RemoteCalc({
                    "Url":"https://www.cashbackforex.com",
                    "TopPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmNvbG9yOiBibGFjazsKcGFkZGluZzogMDsKYm9yZGVyOiBub25lOw==",
                    "BottomPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmJvcmRlcjogbm9uZTsKY29sb3I6IGJsYWNrOw==",
                    "ButtonStyle":"YmFja2dyb3VuZDogIzJjMzZkZTsKY29sb3I6IHdoaXRlOwpib3JkZXItcmFkaXVzOiA0cHg7CmhlaWdodDogMzRweDsKd2lkdGg6IDEwMCU7Cg==",
                    "TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7CmNvbG9yOiAyNjI2MjY7CmJvcmRlcjogc29saWQgMXB4ICNlNWU1ZTU7IApjb2xvcjogIzI2MjYyNjsKCg==",
                    "ContainerWidth":"769",
                    "HighlightColor":"#ffff00",
                    "IsDisplayTitle":false,
                    "IsShowChartLinks":false,
                    "IsShowEmbedButton":false,
                    "Lang":"${toolLang}",
                    "CompactType":"large",
                    "Calculator":"profit-calculator",
                    "ContainerId":"profit-calculator-594509"
                });
            `,
        },
        {
            title: '外汇返利计算器',
            mts: 'Broker.TradeTool5',
            introduction: '使用外汇返利计算器，可以准确地计算出您从交易与平仓中获得的返佣金额，含亏损订单。',
            introduction_mts: 'Broker.TradeToolIntroduction5',
            src: 'https://www.cashbackforex.com/Content/remote/remote-widgets.js',
            id: 'forex-rebate-calculator-107553',
            content: `
                RemoteCalc({
                    "Url":"https://www.cashbackforex.com",
                    "TopPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmNvbG9yOiAjMjYyNjI2ICAhaW1wb3J0YW50OwpwYWRkaW5nOiAwOwpib3JkZXI6IG5vbmU7CmZvbnQtd2VpZ2h0OiAgbm9ybWFsOw==",
                    "BottomPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmJvcmRlcjogbm9uZTsKY29sb3I6IGJsYWNrOwpmb250LXdlaWdodDogIG5vcm1hbDs=",
                    "ButtonStyle":"YmFja2dyb3VuZDogIzJjMzZkZTsKY29sb3I6IHdoaXRlOwpib3JkZXItcmFkaXVzOiA0cHg7CmhlaWdodDogNDhweDsKd2lkdGg6IDQ3LjglOwo=",
                    "TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7CmJvcmRlcjogc29saWQgMXB4ICNlNWU1ZTUgIWltcG9ydGFudDsgCmNvbG9yOiAjMjYyNjI2OwoK",
                    "ContainerWidth":"992",
                    "HighlightColor":"#ffff00",
                    "IsDisplayTitle":false,
                    "IsShowChartLinks":false,
                    "IsShowEmbedButton":false,
                    "Lang":"${toolLang}",
                    "CompactType":"large",
                    "Calculator":"forex-rebate-calculator",
                    "ContainerId":"forex-rebate-calculator-107553"
                });
            `,
            mId: 'forex-rebate-calculator-910064',
            mContent: `
                RemoteCalc({
                    "Url":"https://www.cashbackforex.com",
                    "TopPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmNvbG9yOiBibGFjazsKcGFkZGluZzogMDsKYm9yZGVyOiBub25lOw==",
                    "BottomPaneStyle":"YmFja2dyb3VuZDogd2hpdGU7CmJvcmRlcjogbm9uZTsKY29sb3I6IGJsYWNrOw==",
                    "ButtonStyle":"YmFja2dyb3VuZDogIzJjMzZkZTsKY29sb3I6IHdoaXRlOwpib3JkZXItcmFkaXVzOiA0cHg7CmhlaWdodDogMzRweDsKd2lkdGg6IDEwMCU7Cg==",
                    "TextboxStyle":"YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7CmNvbG9yOiAyNjI2MjY7CmJvcmRlcjogc29saWQgMXB4ICNlNWU1ZTU7IApjb2xvcjogIzI2MjYyNjsKCg==",
                    "ContainerWidth":"769",
                    "HighlightColor":"#ffff00",
                    "IsDisplayTitle":false,
                    "IsShowChartLinks":false,
                    "IsShowEmbedButton":false,
                    "Lang":"${toolLang}",
                    "CompactType":"large",
                    "Calculator":"forex-rebate-calculator",
                    "ContainerId":"forex-rebate-calculator-910064"
                });
            `,
        },
    ];
};
