import React, { useState, useMemo, useEffect, useRef } from 'react';
import { BrokerBusinessTag, NextImage } from '@/components';
import { resizeObserverFun, PAGE_CENTER_NORMAL, PAGE_CENTER_WA, currentLocaleUrl } from '@/utils';
import c from 'classnames';
import styles from './index.module.less';

interface Props {
    options: any;
}

const BrokerTop: React.FC<Props> = ({ options }) => {
    const domRef = useRef<any>(null);
    const [domWidth, setDomWidth] = useState<number>(PAGE_CENTER_NORMAL);
    useEffect(() => {
        resizeObserverFun(domRef.current, setDomWidth);
    }, []);

    const { list = [] } = options;

    const slideNum = useMemo(() => {
        return domWidth > PAGE_CENTER_WA ? 7 : 5;
    }, [domWidth]);

    const newList = useMemo(() => {
        return (list || [])
            .map((item: any, i: number) => {
                return { ...item, key: i };
            })
            .slice(0, slideNum);
    }, [list, slideNum]);

    return (
        <div className={styles.topBrokerBox} ref={domRef}>
            <ul className={c(styles.topBroker, styles[`topBroker${slideNum}`])}>
                {newList.map((item: any) => {
                    const { key, id, logo, name_abbreviation, business_name, seo_url } = item;
                    return (
                        <li key={key}>
                            <a href={currentLocaleUrl(`/broker/${seo_url || id}`)} target="_blank">
                                <NextImage src={logo} layout="fill" alt={name_abbreviation} place="logo_b" priority />
                            </a>
                            <BrokerBusinessTag name={business_name} site="leftTop" />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default BrokerTop;
