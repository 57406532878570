import React, { useState, useMemo, useEffect } from 'react';
import c from 'classnames';
import { NextImage } from '@/components';
import NoContent from '@/components/public/NoContent';
import NoMore from '@/reconstruction/components/public/NoMore';
import NormalTitle from '@/reconstruction/components/public/NormalTitle';
import intl from 'react-intl-universal';
import SwiperCore, { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getBrokerList } from '@/services/broker';
import { currentLocaleUrl } from '@/utils';
import { getUrl } from '@/utils/url';
import { formatMts } from '@/utils/mts';
import type { AllBrokerPros } from '../types';
import styles from './index.module.less';

SwiperCore.use([Mousewheel]);

const AllBroker: React.FC<AllBrokerPros> = ({ hasArrow, groupTitleMts, groupTitle, moreInfo, serviceData }) => {
    const { business = [], list: brokerList = [] } = serviceData || {};

    const MaxNum = 12;
    const currentLocale = intl.getInitOptions().currentLocale;

    const [loading, setLoading] = useState<boolean>(false);
    // tab 选中项
    const [activeItem, setActiveItem] = useState<any>(business[0] || {});
    const [detailsList, setDetailsList] = useState<any>(brokerList || []);

    // 获取broker 颜色
    const getBrokerColor = (num: number) => {
        if (num < 4) {
            return 3;
        }
        if (num < 8) {
            return 2;
        }
        return 1;
    };

    // 动态设置 选中项
    useEffect(() => {
        setActiveItem(business[0] || {});
    }, [business, currentLocale]);

    // 根据active，动态请求加榜单详情
    useEffect(() => {
        if (loading) return;
        setLoading(true);
        if (activeItem?.id) {
            getBrokerList({ type: activeItem?.id, page: 1, pageSize: 12 })
                .then((res: any) => {
                    const { state, data } = res || {};
                    if (state === 1) {
                        const { list = [] } = data || {};
                        const newList: any[] = (list || []).splice(0, MaxNum);

                        setDetailsList(newList);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setDetailsList([]);
            setLoading(false);
        }
    }, [activeItem]);

    // 是否最后几个
    const isLast = useMemo(() => {
        const len = business.length;
        const index = business.findIndex((item) => item.id === activeItem.id);
        return index === len - 1;
    }, [activeItem, business]);

    return (
        <div className={styles.wrapper}>
            <div className={c(styles.top, { [styles.topLine]: !hasArrow })}>
                {hasArrow && (
                    <div className={styles.topTitle}>
                        <NormalTitle
                            customClass={styles.maxLine}
                            title={formatMts(groupTitleMts) || groupTitle || ''}
                        />
                    </div>
                )}
                <Swiper
                    observer
                    observeParents
                    mousewheel
                    resizeObserver
                    grabCursor
                    slidesPerView="auto"
                    className={c(styles.list)}
                >
                    {business.map((item: any) => {
                        const { id, business } = item;

                        return (
                            <SwiperSlide
                                className={c(styles.list_item, { [styles.active]: activeItem?.id === id })}
                                key={id}
                                onClick={() => setActiveItem(item)}
                            >
                                {business || '-'}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                {moreInfo && (
                    <div className={c(styles.more, { [styles.moreLast]: isLast })}>
                        <a className={styles.more_con} href={currentLocaleUrl(getUrl(moreInfo))} target="_blank">
                            <span>{intl.get('Common.SeeMore').d('查看更多')}</span>
                            <span className={c('iconfont', 'icon-jiantou', styles.arrow)} />
                        </a>
                    </div>
                )}
            </div>

            {detailsList.length !== 0 ? (
                <div className={styles.broker_list_box}>
                    <ul className={styles.broker_list}>
                        {detailsList.map((item: any) => {
                            const { id, logo, name_abbreviation, score, seo_url } = item;

                            return (
                                <li key={id}>
                                    <a href={currentLocaleUrl(`/broker/${seo_url || id}`)} target="_blank">
                                        <div className={styles.logo}>
                                            <NextImage
                                                src={logo}
                                                layout="fill"
                                                alt={name_abbreviation}
                                                place="logo_a_new"
                                            />
                                        </div>

                                        <div className={styles.info}>
                                            <p className={styles.name}>{name_abbreviation}</p>
                                            <div className={styles.score}>
                                                <div className={styles.icon}>
                                                    <NextImage
                                                        src={`/broker/icon/score_${getBrokerColor(Number(score || 0))}.webp`}
                                                        needPrefix
                                                        layout="fill"
                                                        alt={name_abbreviation}
                                                        place="new_normal"
                                                    />
                                                </div>
                                                <span className={styles.num}>{Number(score || 0).toFixed(1)}</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ) : (
                <div className={styles.null_data}>
                    <NoContent />
                </div>
            )}

            {detailsList.length !== 0 && detailsList.length <= MaxNum / 2 && <NoMore />}
        </div>
    );
};

export default AllBroker;
