import React from 'react';
import SearchHeader from '../components/SearchHeader';
import ListItem from '../components/ListItem';
import { InfiniteScroll } from '@/reconstruction/components/public';
import { useAppSelector } from '@/store/hook';
import c from 'classnames';
import type { BrokerListProps } from '../type';
import styles from './index.module.less';

const BrokerList: React.FC<BrokerListProps> = (props) => {
    const { serviceData, businessList = [] } = props;
    const { activeNavKeys = [] } = useAppSelector((state: any) => state.config);

    return (
        <div className={c(styles.warpper, { [styles.margin_top]: (activeNavKeys || []).length < 2 })}>
            <SearchHeader businessList={businessList} />

            <InfiniteScroll
                initialLoad={false}
                serviceData={serviceData}
                noDataHeight={500}
                api="/japi/broker/billboard/list"
                renderList={(list) => {
                    return (
                        <div className={styles.wrapper__list}>
                            {list.map((item: any) => {
                                return <ListItem key={item?.id} item={item} />;
                            })}
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default BrokerList;
