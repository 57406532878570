import React, { useMemo } from 'react';
import { formatDomUnit } from '@/utils';
import { ArticlesList } from '@/components';
import c from 'classnames';
import styles from './index.module.less';
interface ArticleProps {
    elementId: string;
    styleType: string | number;
    videoType?: string;
    sourceType?: string;
    articleList?: any[];
    blockApi?: string;
    blockId?: any;
    showCount?: string | number;
    groupTitle?: string;
    groupTitleImg?: string;
    customStyle?: any;
    lineNum: number;
    serviceData: {
        list: any[];
        hasMore: boolean;
    };
    /** 容器类型 page-页面 box-容器组件 */
    containerType?: 'page' | 'box' | string;
}

const Article: React.FC<ArticleProps> = (props) => {
    const {
        styleType,
        blockApi,
        // showCount,
        customStyle,
        serviceData,
        containerType,
    } = props;

    /** 列表的列数 */
    const listColumn = useMemo(() => {
        switch (containerType) {
            case 'box':
                return 3;
            case 'page':
            default:
                return 4;
        }
    }, [containerType]);

    return (
        <div
            style={{ ...formatDomUnit(customStyle || {}) }}
            className={c(styles.articleBox, styleType === 'top' ? styles.articleBoxNull : null)}
        >
            <div className={styles.articleList}>
                <>
                    <div>
                        {
                            // 全部
                            styleType === 'all' ? (
                                <ArticlesList
                                    blockApi={blockApi || ''}
                                    serviceData={serviceData}
                                    listColumn={listColumn}
                                    lineType={'infinite'}
                                ></ArticlesList>
                            ) : null
                        }

                        {/* {
                            // 置顶
                            styleType === 'top' && topArticleRender(list, getHref, customStyle)
                        }

                        {
                            // 快讯
                            styleType === 'flash' ? <FlashNews options={list} elementId={elementId} /> : null
                        } */}
                    </div>
                </>
            </div>
        </div>
    );
};

export default Article;
