import React, { useMemo } from 'react';
import { NextImage } from '@/components';
import { currentLocaleUrl, formatIsUrl } from '@/utils';
import type { ContactListProps } from '../type';
import styles from './index.module.less';

const ContactList: React.FC<ContactListProps> = ({ list = [] }) => {
    const newList = useMemo(() => {
        return (list || []).map((item: any, i: number) => {
            const { contact_way } = item;
            const contac_str = (contact_way || '').replace(/；/g, ';');
            const cList = contac_str.split(';').map((o: any, j: number) => {
                return { value: o, key: j, isUrl: formatIsUrl(o) };
            });
            return { ...item, key: i, contactList: cList };
        });
    }, [list]);

    return (
        <ul className={styles.contactList}>
            {newList.map((item: any) => {
                const { type, contactList = [] } = item || {};
                return (
                    <li key={type} className={styles.contactItem}>
                        <NextImage
                            src={`/broker/contact/${type}.webp`}
                            needPrefix
                            width={24}
                            height={24}
                            alt="contact"
                        />
                        <div className={styles.right}>
                            {contactList.map((item: any) => {
                                return (
                                    <p key={item.key}>
                                        {item.isUrl ? (
                                            <a href={currentLocaleUrl(item.value)} target="_blank">
                                                {item.value}
                                            </a>
                                        ) : (
                                            item.value
                                        )}
                                    </p>
                                );
                            })}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default ContactList;
