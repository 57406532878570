import React, { useEffect } from 'react';
import c from 'classnames';
import { cloneDeep } from 'lodash';
import { App } from 'antd';
import intl from 'react-intl-universal';

import { NextImage } from '@/components';
import useCustomModal from '@/reconstruction/components/public/CustomModal';
import { topicFocusAddPost, topicFocusDelete } from '@/services/focus';
import { currentLocaleUrl } from '@/utils';
import type { TopicDataType } from '@/type/topic';
import { HomeColumnArticleList } from '@/reconstruction/components/listItem';

import styles from './index.module.less';

function RenderItem({ dataSource }: { dataSource: TopicDataType }) {
    const { article_list = [] } = dataSource;
    const { message } = App.useApp();
    const [columnData, setColumnData] = React.useState<TopicDataType>(dataSource);

    const [customModal, CustomModal] = useCustomModal(); // 弹窗

    useEffect(() => {
        setColumnData(dataSource);
    }, [dataSource]);

    const { is_focus, _id, seo_info, cover, title, introduction } = columnData || {};

    /** 取消/关注回调 */
    const handleFocus = () => {
        const fun = is_focus === 1 ? topicFocusDelete : topicFocusAddPost;
        fun(_id).then((res: any) => {
            if (res?.state === 1) {
                message.success(
                    is_focus === 1
                        ? intl.get('Topic.UnfollowSuc').d('取消订阅成功！')
                        : intl.get('Topic.FollowedSuc').d('订阅成功！'),
                );
                const data = cloneDeep(columnData);
                data.is_focus = is_focus === 1 ? 0 : 1;

                setColumnData(data);
            }
        });
    };

    /**
     * 关注/取消关注
     */
    const onFocusColumn = (event: any) => {
        event?.preventDefault();
        if (_id) {
            if (is_focus === 1) {
                customModal.confirm({
                    open: true,
                    title: intl.get('Topic.WantToUnfollow').d(`确定要取消订阅吗？`),
                    onOk: () => {
                        handleFocus();
                        customModal.close();
                    },
                });
            } else {
                handleFocus();
            }
        }
    };

    return (
        <div className={styles.list_item} key={_id}>
            <a
                href={currentLocaleUrl(`/topic/${seo_info?.seo_url || _id}`)}
                target="_blank"
                className={styles.list_item_left}
            >
                <div className={styles.img_wrapper}>
                    <NextImage src={cover.url} layout="fill" place="normal_b" alt={title} />
                </div>
                {title ? <div className={c(styles.title)}>{title}</div> : null}
                {introduction ? <div className={styles.introduction}>{introduction}</div> : null}

                <div
                    className={c(styles.focus, { [styles.not]: is_focus !== 1 })}
                    onClick={(e) => {
                        e.stopPropagation();
                        onFocusColumn(e);
                    }}
                >
                    {is_focus === 1 ? (
                        intl.get('Topic.AlreadyFollowed').d('已订阅')
                    ) : (
                        <React.Fragment>
                            <NextImage src={'/h5/attention.png'} alt="attention" needPrefix width={14} height={14} />
                            <span>{intl.get('Topic.Followed').d('订阅')}</span>
                        </React.Fragment>
                    )}
                </div>
            </a>
            <div className={styles.list_item_right}>
                <HomeColumnArticleList options={article_list} />
            </div>
            {CustomModal}
        </div>
    );
}

export default RenderItem;
