import React, { useState, useEffect, useRef, useMemo } from 'react';
import { App } from 'antd';
import { NextImage } from '@/components';
import BusinessTag from '@/reconstruction/components/public/BusinessTag';
import PcBrokerSelect from '@/reconstruction/components/public/BrokerSelect/PcBrokerSelect';
import cloneDeep from 'lodash/cloneDeep';
import { resizeObserverFun } from '@/utils';
import { nanoid } from 'nanoid';
import c from 'classnames';
import intl from 'react-intl-universal';
import type { TopBrokerProps } from '../type';
import styles from './index.module.less';

const TopBroker: React.FC<TopBrokerProps> = ({
    index,
    record,
    contrastList = [],
    businessList = [],
    onChange,
    onToContrast,
}) => {
    const changeRef = useRef<any>(null);

    const { message } = App.useApp();
    const { _id, logo, name_abbreviation, business_name } = record || {};

    // 是否正在更换
    const [isEdit, setIsEdit] = useState<boolean>(false);

    // 组件宽度
    const wrapRef = useRef<any>(null);
    const [wrapWidth, setWrapWidth] = useState<number>(382);
    useEffect(() => {
        resizeObserverFun(wrapRef.current, setWrapWidth);
    }, []);

    /** 图片宽高 */
    const imgWHStyle = useMemo(() => {
        const _w = Math.round(wrapWidth * 0.77);
        const _h = Math.round((_w * 196) / 296);
        return { width: _w, height: _h };
    }, [wrapWidth]);

    /**
     * 点击更换 交易商
     * @param e
     * @param index
     */
    const onShowChange = (e: any) => {
        e?.preventDefault();
        setIsEdit(true);
    };

    /** 更换交易商 */
    const onBrokerChange = (v: any, call?: any, index?: number) => {
        const cloneList = cloneDeep(contrastList);
        const hasItem = cloneList.find((item: any, i: number) => item._id === v.id && i !== index);

        const nowItem = cloneList.find((item: any, i: number) => item._id === v.id && i === index);

        if (hasItem) {
            message.warning(intl.get('Broker.BrokerLWarnMsg').d('该交易商已在对比列表'));
            call?.();
            return;
        }

        if (nowItem) {
            setIsEdit(false);
            call?.();
            return;
        }

        cloneList.splice(index || 0, 1, { ...v, edit: false });
        const newList = cloneList.map((item: any) => ({ ...item, type: v?.type || item?.type }));
        setIsEdit(false);
        onChange?.(newList);
        onToContrast?.(newList);
        call?.();
    };

    /** 清除当前交易商 */
    const onClearItem = () => {
        const cloneList = cloneDeep(contrastList || []);
        cloneList[index] = { key: nanoid() };
        onChange(cloneList);
        onToContrast?.(cloneList);
    };

    /**
     * 当前 业务类型
     */
    const activeType = useMemo(() => {
        const { type } = contrastList.find((item: any, i: number) => item.type && i !== index) || {};
        return type;
    }, [contrastList]);

    return (
        <div className={styles.wrapper} ref={wrapRef}>
            <div className={styles.main}>
                <div className={c(styles.logo, { [styles.logo_border]: _id })}>
                    <div className={styles.img} style={imgWHStyle}>
                        <NextImage
                            layout="fill"
                            src={_id ? logo : '/img/place/img_logo_b_new.webp'}
                            alt={name_abbreviation || 'broker-logo'}
                            place="img_logo_b_new"
                        />
                    </div>
                </div>

                <BusinessTag name={business_name} site="leftTop" />

                {_id && (
                    <span className={styles.close} onClick={onClearItem}>
                        <NextImage src="/broker/icon/broker-clear.webp" needPrefix alt="clear" width={20} height={20} />
                    </span>
                )}

                <div className={styles.change_btn_box} ref={changeRef}>
                    {isEdit ? (
                        <PcBrokerSelect
                            size="middle"
                            alignCenter={true}
                            parentRef={changeRef}
                            activeIndex={index}
                            activeType={activeType}
                            businessList={businessList}
                            onSelect={onBrokerChange}
                        />
                    ) : (
                        <span className={styles.change_btn} onClick={onShowChange}>
                            <span>{intl.get('Broker.Replace').d('更换')}</span>
                            <span> {intl.get('Broker.ChangeBroker').d('更换交易商')}</span>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopBroker;
