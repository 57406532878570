import React, { useMemo } from 'react';
import c from 'classnames';
import intl from 'react-intl-universal';
import type { HotNewsProps } from '../type';
import { currentLocaleUrl } from '@/utils';
import styles from './index.module.less';
import { cloneDeep } from 'lodash';

const HotNews: React.FC<HotNewsProps> = ({ serviceData }) => {
    const { list = [] } = serviceData || {};

    const hotList = useMemo(() => {
        const newList = cloneDeep(list || []);
        return newList.splice(0, 5);
    }, [list]);

    // 纯文字样式
    const onlyText = (item: any, index: number) => {
        const { title, seo_url, code } = item;

        return (
            <a href={currentLocaleUrl(`/article/${seo_url || code}`)} className={styles.text_item} target="_blank">
                <span
                    className={c(styles.num, {
                        [styles.rank]: index < 3,
                        [styles[`num${index + 1}`]]: index < 3,
                        [styles.font]: index > 2,
                    })}
                >
                    {index + 1}
                </span>
                <span className={styles.text}>{title}</span>
            </a>
        );
    };

    return (
        <div className={styles.hot_news}>
            <div className={styles.tit}>
                {intl.get('HotNews.Hot').d('热门')}
                {intl.get('HotNews.News').d('资讯')}
            </div>
            <ul className={styles.hot_list}>
                {hotList.map((item: any, index: number) => (
                    <li key={item.code} className={styles.hot_item}>
                        {onlyText(item, index)}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default HotNews;
