import React from 'react';
import { useAppSelector } from '@/store/hook';

import styles from './index.module.less';
import BrokerCurrentFilter from './components/CurrentFilter';
import BrokerListMain from './components/Main';

const List = () => {
    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);

    return (
        <div className={styles.list__wrapper}>
            {/* 当前筛选项 */}
            <div style={{ top: topHeight }} className={styles.filter__wrapper}>
                <BrokerCurrentFilter />
            </div>
            {/* 列表主体 */}
            <div className={styles.main__wrapper}>
                <BrokerListMain />
            </div>
        </div>
    );
};

export default List;
