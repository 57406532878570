import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PCVideo from './pc';
import MVideo from './m';
import { VideoProps } from './type';

const Video: React.FC<VideoProps> = (props) => {
    return <TransferCom pc={<PCVideo {...props} />} m={<MVideo {...props} />} ipad={null} />;
};

export default Video;
