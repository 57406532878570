import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import MTopic from './m';
import PcTopic from './pc';
import { type TopicAttentionProps } from '../../type';

const Topic: React.FC<TopicAttentionProps> = (props: TopicAttentionProps) => {
    const { serviceData } = props;
    // 无数据直接返回空
    if ((serviceData?.topic?.total || 0) === 0 && (serviceData?.ugc?.total || 0) === 0) {
        return null;
    }

    return <TransferCom m={<MTopic {...props} />} ipad={<PcTopic {...props} />} pc={<PcTopic {...props} />} />;
};

export default Topic;
