import React from 'react';

import styles from './index.module.less';
import { BrokerDrawer, BrokerList, HeaderFilter } from './components';
import { AllBrokerContext } from './context';
import useFilterItem from '../hook/useFilterItem';
import { useAppSelector } from '@/store/hook';
import { Affix } from 'antd';

interface Props {
    businessList: any[];
    /** 服务端数据 */
    serviceData: {
        list: any[];
        hasMore: boolean;
    };
    /** 首屏筛选数据 */
    filterList: any[];
    sortList: any[];
    globalData: any;
}

const MBrokerAll: React.FC<Props> = (props) => {
    const { businessList, serviceData } = props;

    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);

    /** 获取数据 */
    const contentValues = useFilterItem({
        initFilterList: props.filterList,
        initSortList: props.sortList,
        businessList,
    });

    return (
        <AllBrokerContext.Provider
            value={{
                ...contentValues,
                businessList,
                serviceData,
            }}
        >
            <div className={styles.wrapper}>
                {/* 筛选列表 */}
                {(contentValues.currentFilter || []).length > 0 && (
                    <Affix offsetTop={topHeight}>
                        <HeaderFilter />
                    </Affix>
                )}
                {/* 列表 */}
                <BrokerList />
                {/* 抽屉 */}
                <BrokerDrawer />
            </div>
        </AllBrokerContext.Provider>
    );
};

export default MBrokerAll;
