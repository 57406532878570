import React, { useState, useMemo, useEffect } from 'react';
import c from 'classnames';
import NoContent from '@/components/public/NoContent';
import { NormalTitle, BrokerItemA } from '@/reconstruction/components/public';
import intl from 'react-intl-universal';
import SwiperCore, { Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getBillboardDetails } from '@/services/broker';
import { currentLocaleUrl } from '@/utils';
import { getUrl } from '@/utils/url';
import { formatMts } from '@/utils/mts';
import type { BillboardPros } from '../types';
import styles from './index.module.less';
import { cloneDeep } from 'lodash';

SwiperCore.use([Mousewheel]);

const Billboard: React.FC<BillboardPros> = ({ hasArrow, groupTitleMts, groupTitle, moreInfo, serviceData }) => {
    const { billboard = [], billboardDetails = [] } = serviceData || {};

    const MaxNum = 12;
    const currentLocale = intl.getInitOptions().currentLocale;

    const [loading, setLoading] = useState<boolean>(false);
    // tab 选中项
    const [activeItem, setActiveItem] = useState<any>(billboard[0] || {});
    const [detailList, setDetailList] = useState<any>(billboardDetails || []);

    const billboardGroupList = useMemo(() => {
        const newList = cloneDeep(detailList || []);
        const nList = newList.map((item: any, i: number) => ({ ...item, key: i }));
        const num = Math.ceil(nList.length / 2);
        return [
            { key: 1, list: nList.slice(0, num) },
            { key: 2, list: nList.slice(num, newList.length) },
        ];
    }, [detailList]);

    // 动态设置 选中项
    useEffect(() => {
        setActiveItem(billboard[0] || {});
    }, [billboard, currentLocale]);

    // 根据active，动态请求加榜单详情
    useEffect(() => {
        if (loading) return;
        setLoading(true);

        if (activeItem?.id) {
            getBillboardDetails({ id: activeItem?.id })
                .then((res: any) => {
                    const { state, data } = res || {};
                    if (state === 1) {
                        const { list = [] } = data || {};
                        const newList: any[] = cloneDeep(list || []).splice(0, MaxNum);

                        setDetailList(newList);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setDetailList([]);
            setLoading(false);
        }
    }, [activeItem]);

    // 是否最后几个
    const isLast = useMemo(() => {
        const len = billboard.length;
        const index = billboard.findIndex((item) => item.id === activeItem.id);
        return index === len - 1 || index === len - 2;
    }, [activeItem, billboard]);

    return (
        <div className={styles.wrapper}>
            <div className={c(styles.top, { [styles.topLine]: !hasArrow })}>
                {hasArrow && (
                    <div className={styles.topTitle}>
                        <NormalTitle
                            customClass={styles.maxLine}
                            title={formatMts(groupTitleMts) || groupTitle || ''}
                        />
                    </div>
                )}
                <Swiper
                    observer
                    observeParents
                    mousewheel
                    resizeObserver
                    grabCursor
                    slidesPerView="auto"
                    className={c(styles.list)}
                >
                    {billboard.map((item: any) => {
                        const { id, simple_name } = item;

                        return (
                            <SwiperSlide
                                className={c(styles.list_item, { [styles.active]: activeItem?.id === id })}
                                key={id}
                                onClick={() => setActiveItem(item)}
                            >
                                {simple_name || '-'}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                {moreInfo && (
                    <div className={c(styles.more, { [styles.moreLast]: isLast })}>
                        <a className={styles.more_con} href={currentLocaleUrl(getUrl(moreInfo))} target="_blank">
                            <span>{intl.get('Common.SeeMore').d('查看更多')}</span>
                            <span className={c('iconfont', 'icon-jiantou', styles.arrow)} />
                        </a>
                    </div>
                )}
            </div>
            {detailList.length !== 0 ? (
                <div className={styles.details_list_box}>
                    {billboardGroupList.map((groupItem, i: number) => (
                        <ul className={styles.details_list} key={groupItem.key}>
                            {groupItem.list.map((item: any, j: number) => {
                                const { id } = item;
                                return (
                                    <li
                                        key={id}
                                        className={c({
                                            [styles.no_bottom]: j === groupItem.list.length - 1,
                                        })}
                                    >
                                        <BrokerItemA rank={i === 0 && j < 3} index={j} record={item} />
                                    </li>
                                );
                            })}
                        </ul>
                    ))}
                </div>
            ) : (
                <div className={styles.null_data}>
                    <NoContent />
                </div>
            )}
        </div>
    );
};

export default Billboard;
