import React, { useContext, useMemo, useState } from 'react';
import c from 'classnames';
import intl from 'react-intl-universal';

import { DropDownBox } from '@/reconstruction/components/public';

import { AllBrokerContext } from '../../../../context';
import styles from './index.module.less';

function BrokerCurrentFilter() {
    const {
        sortActiveIndex,
        currentFilter,
        clearFilterHandler,
        filterClickHandler,
        sortClickHandler,
        sortData,
        yearData,
        countryData,
    } = useContext(AllBrokerContext);

    /** 是否展示筛选内容 */
    const isShowFilter = useMemo(() => {
        return currentFilter.length > 0;
    }, [currentFilter]);

    /** 已选择的成立时间 */
    const yearSelectList = useMemo(() => {
        return currentFilter.filter((item) => item.filterKey === 'year').map((item) => item.value) || [];
    }, [currentFilter]);

    /** 已选择的国家地区 */
    const countSelectList = useMemo(() => {
        return currentFilter.filter((item) => item.filterKey === 'country').map((item) => item.value) || [];
    }, [currentFilter]);

    return (
        <div className={styles.filter}>
            {/* 筛选项 */}
            {isShowFilter && (
                <div className={styles.selected}>
                    {currentFilter.map((item, index) => {
                        const { label, name, filterKey, type } = item;
                        const text = label || name || '';
                        return (
                            <div className={styles.selected__value} key={index}>
                                <span className={styles.text}>{text}</span>
                                <span
                                    onClick={() => {
                                        filterClickHandler(item, filterKey || '', type || 'checkbox');
                                    }}
                                    className={c('iconfont', 'icon-fork', styles.icon)}
                                />
                            </div>
                        );
                    })}
                    <div className={styles.reset} onClick={clearFilterHandler}>
                        <span>{intl.get('Activity.ResetFilter').d('清空条件')}</span>
                        <span className={c('iconfont', 'icon-clear')} />
                    </div>
                </div>
            )}

            {/* 排序 */}
            <div className={styles.header}>
                <div className={styles.header__left}>
                    {sortData.map((item, index) => {
                        const { value, label } = item;
                        const isActive = index === sortActiveIndex;
                        return (
                            <div
                                key={value}
                                onClick={() => sortClickHandler(index)}
                                className={c(styles.sort, { [styles.active]: isActive })}
                            >
                                {label}
                            </div>
                        );
                    })}
                </div>
                <div className={styles.header__right}>
                    {/* 成立时间 */}
                    {(yearData?.key || []).length > 0 && (
                        <DropDownBox
                            option={(yearData?.key || []) as any}
                            selectedList={yearSelectList}
                            placeholder={intl.get('Broker.EstablishedTime').d('成立时间')}
                            onChange={(record) => {
                                filterClickHandler(record as any, yearData?.value || '', 'checkbox');
                            }}
                        />
                    )}
                    {/* 国家地区 */}
                    {(countryData?.key || []).length > 0 && (
                        <DropDownBox
                            selectedList={countSelectList}
                            option={(countryData?.key || []) as any}
                            placeholder={intl.get('Broker.CountriesAndRegions').d('国家地区')}
                            onChange={(record) => {
                                filterClickHandler(record as any, countryData?.value || '', 'country');
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default BrokerCurrentFilter;
