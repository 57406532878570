import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PCSwiper from './pc';
import MSwiper from '../MSwiper';
import type { SwiperProps } from '../types';

const SecondSwiper: React.FC<SwiperProps> = (props) => {
    const { swiperList = [] } = props;
    return (
        <TransferCom
            pc={<PCSwiper {...props} />}
            m={<MSwiper {...props} swiperList={swiperList[0] || []} />}
            ipad={<PCSwiper {...props} />}
        />
    );
};

export default SecondSwiper;
