import React from 'react';
import { Billboard, Contrast, Recommend } from '../components';
import type { HomeBrokerPros } from '../types';
import styles from './index.module.less';

const HomeBroker: React.FC<HomeBrokerPros> = (props) => {
    const { serviceData, moreInfo } = props || {};
    const { billboard = [], billboardDetails = [], contrast = [], recommend = [] } = serviceData || {};

    return (
        <div className={styles.wrapper}>
            <Billboard moreInfo={moreInfo} billboard={billboard} billboardDetails={billboardDetails} />
            <Contrast contrast={contrast} />
            <Recommend recommend={recommend} />
        </div>
    );
};

export default HomeBroker;
