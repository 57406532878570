import React, { useState, useEffect, useRef } from 'react';
import { Affix } from 'antd';
import AnalystList from '../../AnalystList';
import { useAppSelector } from '@/store/hook';
import { resizeObserverFun } from '@/utils';
import { Tabs } from '@/components';
import NavLineItem from '@/components/public/Tabs/NavLineItem';
import InfiniteScrollForAnalyst from '../components/InfiniteScrollForAnalyst';
import SearchItems from '../../SearchItems';
import type { FormDataProps } from '../types';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    options: any[];
    elementId: string;
    serviceData: {
        list: any[];
        hasMore: boolean;
    };
    detail: any;
    locale: string;
}

const AnalystIndex: React.FC<Props> = ({ locale, elementId, options = [], serviceData, detail }) => {
    const analystConRef = useRef<any>(null);
    const normalTab = { title: intl.get('Analyze.AllCapital').d('全部'), value: 'All', index: 0 };

    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);

    const InfiniteScrolleRef = useRef<any>(null);
    const [activeItem, setActiveItem] = useState<any>(normalTab);
    const [isInit, setIsInit] = useState<boolean>(true);
    const [searchVal, setSearchVal] = useState<FormDataProps>({});

    const [listFilters, setListFilters] = useState<FormDataProps | undefined>(searchVal);

    /**
     * tabs高度
     */
    const tabsRef = useRef<any>(null);
    const [tabsHeight, setTabsHeight] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(tabsRef.current, setTabsHeight, 'clientHeight');
    }, []);

    /**
     * 筛选高度
     */
    const shRef = useRef<any>(null);
    const [shHeight, setShHeight] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(shRef.current, setShHeight, 'clientHeight');
    }, []);

    /**
     * tabs 改变
     * @param _key
     * @param data
     */
    const onTabChange = (_key: number, data: any) => {
        setActiveItem(data);
    };

    /**
     * 筛选条件发生改变、重新请求接口
     */
    useEffect(() => {
        const { value: symbolType } = activeItem;
        if (symbolType && symbolType !== 'All') {
            setIsInit(false);
            setListFilters({ ...searchVal, symbol_type: [symbolType] });
        } else {
            setIsInit(false);
            setListFilters(searchVal);
        }
    }, [searchVal, activeItem]);

    useEffect(() => {
        if (!isInit) {
            InfiniteScrolleRef.current?.resetList();
        }
    }, [locale, isInit, listFilters]);

    return (
        <div className={styles.analyst}>
            <div ref={tabsRef}>
                <Tabs
                    wrapperClass={styles.analystTabs}
                    elementId={elementId}
                    isFixed
                    list={[normalTab, ...options]}
                    defaultActiveTab={0}
                    onTabChange={onTabChange}
                    itemRender={(data) => <NavLineItem data={data} activeTab={activeItem.index} />}
                />
            </div>

            {/** 筛选 */}
            <Affix offsetTop={topHeight + tabsHeight}>
                <div ref={shRef}>
                    <SearchItems onChange={setSearchVal} />
                </div>
            </Affix>

            {/** 列表 */}
            <div
                className={styles.analystList}
                style={{ height: `calc(100vh - ${topHeight + tabsHeight + shHeight + 200}px)` }}
            >
                <InfiniteScrollForAnalyst
                    method="post"
                    onRef={InfiniteScrolleRef}
                    initialLoad={false}
                    serviceData={!isInit ? undefined : serviceData}
                    api="/japi/analyst/list"
                    parentRef={analystConRef}
                    extraParams={{ pageSize: 20, ...listFilters }}
                    renderList={(list, loader) => <AnalystList list={list} defaultId={detail?._id} loader={loader} />}
                />
            </div>

            <div className={styles.botTip}>
                {intl
                    .get('Common.Disclaimer')
                    .d(
                        '免责声明：本文观点来自原作者，不代表Hawk Insight的观点和立场。文章内容仅供参考、交流、学习，不构成投资建议。如涉及版权问题，请联系我们删除。',
                    )}
            </div>
        </div>
    );
};

export default AnalystIndex;
