import React from 'react';
import ListItem from '../components/ListItem';
import { InfiniteScroll } from '@/reconstruction/components/public';
import type { BrokerListProps } from '../type';
import styles from './index.module.less';

const BrokerList: React.FC<BrokerListProps> = (props) => {
    const { serviceData } = props;

    return (
        <div className={styles.warpper}>
            <InfiniteScroll
                initialLoad={false}
                serviceData={serviceData}
                api="/japi/broker/billboard/list"
                renderList={(list) => {
                    return (
                        <div className={styles.wrapper__list}>
                            {list.map((item: any) => {
                                return <ListItem key={item?.id} item={item} />;
                            })}
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default BrokerList;
