import React, { useMemo } from 'react';
import { formatDomUnit } from '@/utils';
import BrokerTop from './BrokerTop';
import BrokerSwiper from './BrokerSwiper';
import BrokerNav from './BrokerNav';
import BrokerContrast from './BrokerContrast';
import styles from './index.module.less';

interface BrokerProps {
    elementId: string;
    styleType: string | number;
    navs?: any[];
    contrastList?: any[];
    groupTitle?: string;
    groupTitleMts?: {
        zh_cns?: string;
        en_us?: string;
    };
    customStyle?: any;
    hasArrow?: boolean;
    serviceData?: {
        list: any[];
        hasMore: boolean;
    };
}

const Broker: React.FC<BrokerProps> = ({
    elementId,
    styleType,
    groupTitle,
    groupTitleMts,
    customStyle,
    serviceData,
    hasArrow,
    navs = [],
    contrastList = [],
}) => {
    const Element = useMemo(() => {
        switch (styleType) {
            case '2': // 置顶
                return <BrokerTop options={serviceData} />;
            case '1': // 推荐
                const propsForSwiper = { options: serviceData, elementId, groupTitle, groupTitleMts, hasArrow };
                return <BrokerSwiper {...propsForSwiper} />;
            case '3': // 榜单
                const propsForNav = { options: serviceData, elementId, navs, groupTitle, groupTitleMts, hasArrow };
                return <BrokerNav {...propsForNav} />;
            case '4': // 对比
                const propsForContrast = {
                    elementId,
                    list: contrastList,
                    groupTitle,
                    groupTitleMts,
                    hasArrow,
                    options: serviceData,
                };
                return <BrokerContrast {...propsForContrast} />;
            default:
                return null;
        }
    }, [styleType, elementId, groupTitle, groupTitleMts, navs, contrastList, hasArrow, serviceData]);

    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }} className={styles.brokerBox}>
            <div className={styles.brokerList}>{Element}</div>
        </div>
    );
};

export default Broker;
