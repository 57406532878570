import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import c from 'classnames';

import styles from './index.module.less';
import { statusList } from '../../../config';
import { ActivityContent } from '../../content';
import { useAppSelector } from '@/store/hook';
import intl from 'react-intl-universal';

const HeaderFilter: React.FC = () => {
    const locale = intl.getInitOptions().currentLocale; // 多语言

    const { currentStatus, currentCity, filterChangeHandler, cityListData, cityOption, citySumList } =
        useContext(ActivityContent);
    const [activeIndex, setActiveIndex] = useState<number>(0); // 当前下标
    const filterRef = useRef<any>(null);

    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);

    /** 转换状态数据 */
    const statusListData = useMemo(() => {
        return statusList.map((i) => ({
            label: intl.get(i.mts).d(i.label),
            value: i.value,
            mts: i.mts,
            id: i.id,
        }));
    }, [locale]);

    /** 导航点击 */
    const tabClickHandler = (i: number) => {
        if (activeIndex === i) {
            setActiveIndex(0);
            return;
        }
        setActiveIndex(i);
    };

    useEffect(() => {
        if (!filterRef.current) {
            return;
        }
        // 获取目标元素的位置信息
        const elementRect = filterRef.current?.getBoundingClientRect();
        // 计算滚动的目标位置
        const targetScrollPosition = window.scrollY + (elementRect?.top || 0) - topHeight;

        if (targetScrollPosition === 0) {
            return;
        }

        if (activeIndex !== 0) {
            window.scrollTo({
                top: targetScrollPosition,
                behavior: 'smooth',
            });
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [activeIndex]);

    // 列表渲染
    const listRender = (i: 1 | 2 | 3) => {
        let list: {
                value: string;
                label: string;
                mts?: string;
                id: string;
            }[],
            currentList: string[],
            type: 'status' | 'city';

        switch (i) {
            case 1:
                list = statusListData;
                currentList = currentStatus;
                type = 'status';
                break;
            case 2:
                list = cityListData;
                currentList = currentCity;
                type = 'city';
                break;
            case 3:
                list = citySumList;
                currentList = currentCity;
                type = 'city';
                break;
        }

        return (
            <div className={styles.list}>
                <div
                    onClick={() => filterChangeHandler(type, 'all')}
                    className={c(styles.value, { [styles.active]: currentList.length === 0 })}
                >
                    <span>{intl.get('Common.All').d('全部')}</span>
                    <span className={c('iconfont', 'icon-dui')}></span>
                </div>
                {list.map((item) => {
                    const { value, id, label } = item;
                    const activeCls = currentList.includes(value) ? styles.active : '';
                    return (
                        <div
                            onClick={() => filterChangeHandler(type, id)}
                            className={c(styles.value, activeCls)}
                            key={value}
                        >
                            <span>{label}</span>
                            <span className={c('iconfont', 'icon-dui')}></span>
                        </div>
                    );
                })}
            </div>
        );
    };

    /** 获取当前筛选的文案 */
    const getFilterCurrentText = useCallback(
        (type: 'status' | 'city' | 'more', index: 1 | 2 | 3) => {
            const result: any[] = [];
            switch (type) {
                case 'status':
                    currentStatus.reduce((init, item) => {
                        const label = statusListData.filter((s) => s.value === item)?.[0]?.label;
                        if (label) {
                            init.push(label);
                        }
                        return init;
                    }, result);
                    break;
                case 'city':
                    currentCity.reduce((init, item) => {
                        const label = cityListData.filter((s) => s.value === item)?.[0]?.label;
                        if (label) {
                            init.push(label);
                        }
                        return init;
                    }, result);
                    break;
                case 'more':
                    currentCity.reduce((init, item) => {
                        const label = cityOption.filter((s) => s.value === item)?.[0]?.label;
                        if (label) {
                            init.push(label);
                        }
                        return init;
                    }, result);
                    break;
            }

            let defaultText = '';
            switch (index) {
                case 1:
                    defaultText = intl.get('Activity.ActiveState').d('活动状态');
                    break;
                case 2:
                    defaultText = intl.get('Activity.OwningRegion').d('所属区域');
                    break;
                case 3:
                    defaultText = intl.get('Activity.MoreOtherCountries').d('更多其他国家');
                    break;
            }

            return result.length > 0 ? result.join('/') : defaultText;
        },
        [currentStatus, statusList, currentCity, citySumList, cityListData, cityOption],
    );

    return (
        <>
            <div ref={filterRef} className={styles.filter} style={{ top: topHeight }}>
                {/* 活动状态 */}
                <div className={c(styles.filter__item, { [styles.active]: activeIndex === 1 })}>
                    <div className={styles.label} onClick={() => tabClickHandler(1)}>
                        <span>{getFilterCurrentText('status', 1)}</span>
                        <em />
                    </div>
                    {listRender(1)}
                </div>
                {/* 所属区域 */}
                <div className={c(styles.filter__item, { [styles.active]: activeIndex === 2 })}>
                    <div className={styles.label} onClick={() => tabClickHandler(2)}>
                        <span>{getFilterCurrentText('city', 2)}</span>
                        <em />
                    </div>

                    {listRender(2)}
                </div>
                {/* 更多国家 */}
                <div className={c(styles.filter__item, { [styles.active]: activeIndex === 3 })}>
                    <div className={styles.label} onClick={() => tabClickHandler(3)}>
                        <span>{getFilterCurrentText('more', 3)}</span>
                        <em />
                    </div>
                    {listRender(3)}
                </div>
            </div>
            {activeIndex !== 0 && <em className={styles.mask} onClick={() => setActiveIndex(0)} />}
        </>
    );
};

export default HeaderFilter;
