import React from 'react';
import { formatMts } from '@/utils/mts';
import classnames from 'classnames';
import styles from './index.module.less';

interface Props {
    data: any;
    activeTab: number;
    titleKey?: string;
    titleMtsKey?: string;
}

const NavTabItem: React.FC<Props> = ({ data = {}, activeTab, titleKey = 'title', titleMtsKey = 'titleMts' }) => {
    const title = formatMts(data[titleMtsKey]) || data[titleKey];
    return (
        <span
            className={classnames(
                styles.seriesItem,
                { [styles.active]: activeTab === data.index },
                activeTab === data.index && '_active-seriesItem',
            )}
        >
            {title}
        </span>
    );
};

export default NavTabItem;
