import React, { useEffect } from 'react';
import c from 'classnames';
import { cloneDeep } from 'lodash';
import intl from 'react-intl-universal';
import { App } from 'antd';

import { NextImage } from '@/components';
import useCustomModal from '@/reconstruction/components/public/CustomModal';
import { focusAddPost, focusDelete } from '@/services/focus';
import { currentLocaleUrl } from '@/utils';
import type { UgcListType } from '@/type/ugc';
import { HomeColumnArticleList } from '@/reconstruction/components/listItem';

import styles from './index.module.less';

function RenderItem({ dataSource }: { dataSource: UgcListType }) {
    const { message } = App.useApp();
    const { article_list = [] } = dataSource;
    const [ugcData, setUgcData] = React.useState<UgcListType>(dataSource);

    const { id, is_focus, name, avatar, introduction } = ugcData || {};

    const [customModal, CustomModal] = useCustomModal(); // 弹窗

    useEffect(() => {
        setUgcData(dataSource);
    }, [dataSource]);

    /** 取消/关注回调 */
    const handleFocus = () => {
        const fun = is_focus === 1 ? focusDelete : focusAddPost;
        fun(id).then((res: any) => {
            if (res?.state === 1) {
                message.success(
                    is_focus === 1
                        ? intl.get('PersonalCenter.UnfollowSuc').d('取消关注成功！')
                        : intl.get('Article.FollowedSuc').d('关注成功！'),
                );
                const data = cloneDeep(ugcData);
                data.is_focus = is_focus === 1 ? 0 : 1;

                setUgcData(data);
            }
        });
    };

    /**
     * 关注/取消关注
     */
    const onFocusUgc = (event: any) => {
        event?.preventDefault();
        if (id) {
            if (is_focus === 1) {
                customModal.confirm({
                    open: true,
                    title: intl.get('Article.WantToUnfollow', { field: name }).d(`确定要取消对 ${name} 的关注吗？`),
                    onOk: () => {
                        handleFocus();
                        customModal.close();
                    },
                });
            } else {
                handleFocus();
            }
        }
    };

    return (
        <div className={styles.list_item} key={id}>
            <a href={currentLocaleUrl(`/ugc/${id}`)} target="_blank" className={styles.list_item_left}>
                <div className={styles.img_wrapper}>
                    <NextImage src={avatar} layout="fill" place="ugc_b" alt={name} />
                </div>
                <div className={c(styles.title)}>{name || '-'}</div>
                {introduction ? <div className={styles.introduction}>{introduction}</div> : null}

                <div className={c(styles.focus, { [styles.not]: is_focus !== 1 })} onClick={onFocusUgc}>
                    {is_focus === 1 ? (
                        intl.get('Article.AlreadyFollowed').d('已关注')
                    ) : (
                        <React.Fragment>
                            <NextImage src={'/h5/attention.png'} alt="attention" needPrefix width={14} height={14} />
                            <span>{intl.get('Article.Followed').d('关注')}</span>
                        </React.Fragment>
                    )}
                </div>
            </a>
            <div className={styles.list_item_right}>
                <HomeColumnArticleList options={article_list} />
            </div>
            {CustomModal}
        </div>
    );
}

export default RenderItem;
