import TransferCom from '@/components/public/TransferCom';
import MRecommend from './m';
import PCRecommend from './pc';
import type { RecommendPros } from '../../types';

const Recommend: React.FC<RecommendPros> = (props) => {
    return (
        <TransferCom m={<MRecommend {...props} />} ipad={<PCRecommend {...props} />} pc={<PCRecommend {...props} />} />
    );
};

export default Recommend;
