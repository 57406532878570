import React from 'react';
import { NextImage } from '@/components';
import { currentLocaleUrl, formatBrokerBusiness } from '@/utils';
import intl from 'react-intl-universal';
import type { BrokerItemProps } from '../type';
import styles from './index.module.less';

const BrokerItem: React.FC<BrokerItemProps> = ({ record }) => {
    const currentLocale = intl.getInitOptions().currentLocale;
    const { id, logo, name_abbreviation, score, business_name, seo_url } = record;

    return (
        <a href={currentLocaleUrl(`/broker/${seo_url || id}`)} target="_blank" className={styles.li}>
            <div className={styles.logo}>
                <NextImage src={logo} alt={name_abbreviation} place="logo_a_new" width={64} height={64} />
            </div>
            <div className={styles.info}>
                <p className={styles.name}>{name_abbreviation}</p>
                <div className={styles.score}>
                    <span>
                        <strong>{Number(score || 0).toFixed(1)}</strong>
                    </span>
                    <span>{intl.get('Broker.Score').d('评分')}</span>
                </div>
            </div>

            <span className={styles.business}>{formatBrokerBusiness(business_name, currentLocale)}</span>
        </a>
    );
};

export default BrokerItem;
