import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import TransferCom from '@/components/public/TransferCom';
import MBrokerList from './m';
import PcTopBanner from './pc';
import type { TopBannerProps } from './type';

const TopBanner: React.FC<TopBannerProps> = (props) => {
    const { bannerData } = props || {};

    /**
     * 判断是否展示 banner
     */
    const showBanenr = useMemo(() => {
        const { imgLink, displayTime = [] } = bannerData || {};
        const currentDay = dayjs();
        return (
            imgLink &&
            ((displayTime && currentDay.isAfter(dayjs(displayTime[0])) && currentDay.isBefore(dayjs(displayTime[1]))) ||
                !displayTime[0])
        );
    }, [bannerData]);

    return showBanenr ? (
        <TransferCom m={<MBrokerList {...props} />} ipad={<PcTopBanner {...props} />} pc={<PcTopBanner {...props} />} />
    ) : null;
};

export default TopBanner;
