import React, { useMemo } from 'react'
import { formatDomUnit, filters } from '@/utils'
import { nanoid } from 'nanoid'
import { FlashNews } from '../index'
import { ArticleWaterfall, InfiniteScroll } from '@/components'
import c from 'classnames'
import styles from './index.module.less'
import { topArticleRender } from './renderItems'

interface ArticleProps {
  elementId: string
  styleType: string | number
  videoType?: string
  sourceType?: string
  articleList?: any[]
  blockApi?: string
  blockId?: any
  showCount?: string | number
  groupTitle?: string
  groupTitleImg?: string
  customStyle?: any
  serviceData?: {
    list: any[]
    hasMore: boolean
  }
}

const Article: React.FC<ArticleProps> = ({
  elementId,
  styleType,
  sourceType,
  articleList,
  blockApi,
  // showCount,
  customStyle,
  serviceData
}) => {

  const getHref = (row: any) => {
    const { open_info, openInfo, code, seo_url } = row || {}
    const { type, web } = open_info || openInfo || {}
    switch (type) {
      case 'article':
        return `/article/${seo_url || code}`
      case 'web':
        const { url } = web || {}
        return url
      default:
        return ''
    }
  }

  // 转化数据
  const transformData = (res: any, first?: boolean) => {
    const { data } = res
    const { list: resList = [], top_list = [] } = data
    const topList = top_list.map((e: any) => ({ ...e, is_topping: 1 }))

    const clist = [
      ...(first ? topList : []),
      ...resList.map((e: any) => {
        return { ...e, nanoid: nanoid() };
      })].filter(item => {
        return item.pk;
      })
    return {
      list: clist,
      total: data.total
    }
  }

  const list = useMemo(() => {
    if (sourceType === '1') {
      const arr = (articleList || []).map((e: any) => {
        const { openInfo } = e || {}
        const { article } = openInfo || {}
        return { ...article, ...filters(e), nanoid: nanoid() }
      })
      return styleType === 'flash' ? arr : arr.filter(item => { return item.pk; })
    } else {
      return []
    }
  }, [articleList, sourceType, styleType])


  return (
    <div style={{ ...formatDomUnit(customStyle || {}) }} className={c(styles.articleBox, styleType === 'top' ? styles.articleBoxNull : null)}>
      <div className={styles.articleList}>
        <>
          <div>
            { // 全部 
              styleType === 'all' ? <InfiniteScroll
                initialLoad={false}
                transformData={transformData}
                serviceData={serviceData}
                api={blockApi || ''}
                renderList={(list) => { return <ArticleWaterfall options={list} /> }}
              /> : null
            }

            { // 置顶
              styleType === 'top' && topArticleRender(list, getHref, customStyle)
            }

            { // 快讯
              styleType === 'flash' ? <FlashNews options={list} elementId={elementId} /> : null
            }


          </div>
        </>
      </div>
    </div >
  )

}

export default Article
