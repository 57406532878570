import React from 'react';
import { FirstAdvertise, SecondAdvertise } from '@/components/public/CustomAdvertise';
import { PAGE_CENTER_NORMAL, PAGE_CENTER_WA } from '@/utils';
import styles from './index.module.less';

interface SwiperProps {
    elementId: string | number;
    styleType: number | string;
    canClose: boolean;
    interval?: number;
    customStyle: any;
    adCode: string;
    adList: any[];
    isRight?: boolean; // 是否是侧边广告
    showRightData?: boolean; // 是否展示侧边栏
}

const Advertise: React.FC<SwiperProps> = ({
    elementId,
    styleType,
    customStyle,
    interval = 5,
    adCode,
    canClose,
    adList = [],
    isRight,
    showRightData,
}) => {
    const { width, widthUnit, height, heightUnit, ...restStyle } = customStyle || {};

    return (
        <div className={styles.swiperContainBox}>
            {styleType === '1' && (
                <FirstAdvertise
                    elementId={elementId}
                    adCode={adCode}
                    adStyle={restStyle}
                    moreStyle={{ width: showRightData ? PAGE_CENTER_WA : PAGE_CENTER_NORMAL }}
                />
            )}
            {styleType === '2' && (
                <SecondAdvertise
                    elementId={elementId}
                    interval={interval}
                    canClose={canClose}
                    customStyle={customStyle}
                    swiperList={adList}
                />
            )}
        </div>
    );
};

export default Advertise;
