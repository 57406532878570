import React, { useEffect, useMemo, useState, useRef } from 'react';
import { currentLocaleUrl, formatDomUnit, formatMts } from '@/utils';
import SwiperCore, { Controller, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import c from 'classnames';
import styles from './index.module.less';
import { KeyWordProps } from '../types';

SwiperCore.use([Controller, Navigation]);

const MKeyWord: React.FC<KeyWordProps> = ({ elementId, keyWords, customStyle }) => {
    const swiperRef = useRef<any>(null);

    const arr = useMemo(() => {
        return (keyWords || []).map((item, index) => {
            return { ...item, index };
        });
    }, [keyWords]);

    return (
        <div style={{ ...formatDomUnit(customStyle || {}), width: 'auto' }}>
            <div className={styles.keyWordBox}>
                <Swiper
                    observer
                    resizeObserver
                    grabCursor
                    setWrapperSize
                    slidesPerView="auto"
                    className={c(styles.keyWords)}
                    ref={swiperRef}
                >
                    {arr.map((item: any) => (
                        <SwiperSlide className={styles.keyWordItem} key={item.index}>
                            <a
                                target="_blank"
                                href={currentLocaleUrl(`/keyword?tags=${formatMts(item.titleMts) || item.title}`)}
                            >
                                {formatMts(item.titleMts) || item.title}
                            </a>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default MKeyWord;
