import React, { useMemo } from 'react';
import c from 'classnames';

import { NextImage } from '@/components';
import { currentLocaleUrl } from '@/utils';

import styles from './index.module.less';
import ArticlesList from './ArticlesList';

function SwiperSlideItem({ showType, dataSource = {} }: { showType: 'topic' | 'ugc'; dataSource: any }) {
    const HeaderRender = useMemo(() => {
        switch (showType) {
            case 'ugc':
                return (
                    <a href={currentLocaleUrl(`/ugc/${dataSource?.id}`)} target="_blank" className={styles.ugc_header}>
                        <div className={styles.img_con}>
                            <NextImage src={dataSource.avatar} layout="fill" alt={dataSource?.name} place="ugc" />
                        </div>
                        <div className={styles.name}>{dataSource.name || ''}</div>
                    </a>
                );
            case 'topic':
            default:
                return (
                    <a
                        href={currentLocaleUrl(`/topic/${dataSource?.seo_info?.seo_url || dataSource?._id}`)}
                        target="_blank"
                        className={styles.topic_header}
                    >
                        <div className={styles.img_con}>
                            <NextImage
                                src={dataSource?.cover?.url}
                                place="normal_b"
                                layout="fill"
                                alt={dataSource?.title}
                            />
                        </div>
                        <div className={styles.text_content}>
                            <div className={c(styles.title)}>{dataSource?.title || ''}</div>
                            {dataSource?.introduction && (
                                <div className={c(styles.introduction)}>{dataSource?.introduction || ''}</div>
                            )}
                        </div>
                    </a>
                );
        }
    }, [dataSource]);

    return (
        <div className={styles.list_item}>
            {/* 头部 */}
            {HeaderRender}
            {/* 稿件列表 */}
            <ArticlesList list={dataSource?.article_list?.slice(0, 3) || []} />
        </div>
    );
}

export default SwiperSlideItem;
