import React, { useContext, useEffect, useState } from 'react';
import c from 'classnames';
import intl from 'react-intl-universal';

import { useAppSelector } from '@/store/hook';

import styles from './index.module.less';
import { AllBrokerContext } from '../../context';
import FilterItemList from '../FilterItemList';

function BrokerDrawer() {
    const locale = intl.getInitOptions().currentLocale;
    const { businessList, businessClickHandler, businessActiveIndex } = useContext(AllBrokerContext);
    const [show, setShow] = useState<boolean>(false);
    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
            return;
        }
        document.body.style.overflow = 'auto';
    }, [show]);

    return (
        <div
            className={c(styles.wrapper, { [styles.active]: show })}
            style={{ top: topHeight, height: 'calc(100vh - ' + topHeight + 'px)' }}
        >
            {/* 业务列表 */}
            <div className={styles.business}>
                {businessList.map((item, index: number) => {
                    const { business, id } = item;

                    if (!business) {
                        return <></>;
                    }

                    return (
                        <div
                            className={c(styles.business__item, { [styles.active]: businessActiveIndex === index })}
                            key={id}
                            onClick={() => {
                                businessClickHandler(item, index);
                            }}
                        >
                            {item.business}
                        </div>
                    );
                })}
            </div>
            {/* 筛选列表 */}
            <div className={styles.filter}>
                <FilterItemList />
            </div>
            {/* 抽屉开关 */}
            <div className={c(styles.switch, { [styles.active]: show })} onClick={() => setShow(!show)}>
                {show ? (
                    <span className={c('iconfont', 'icon-jiantou')} />
                ) : locale === 'zh-CN' ? (
                    <span>筛选</span>
                ) : (
                    <span className={c('iconfont', 'icon-shaixuan')} />
                )}
            </div>
        </div>
    );
}

export default BrokerDrawer;
