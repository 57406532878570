import React from 'react';

import { useAppSelector } from '@/store/hook';

import styles from './index.module.less';
import { BusinessHeader, FilterItemList } from './components';

const FilterList = () => {
    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);

    return (
        <div className={styles.filter__wrapper} style={{ maxHeight: `calc(100vh - ${topHeight}px  - 16px)` }}>
            {/* 业务列表 */}
            <div className={styles.business}>
                <BusinessHeader />
            </div>
            {/* 筛选内容 */}
            <div className={styles.filter__list}>
                <FilterItemList />
            </div>
        </div>
    );
};

export default FilterList;
