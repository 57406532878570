import React from 'react';
import intl from 'react-intl-universal';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import c from 'classnames';

import { formatDomUnit } from '@/utils';
import { NoMore, NormalTitle } from '@/reconstruction/components/public';
import { currentLocaleUrl } from '@/utils';
import { NoContent } from '@/components/public';

import styles from './index.module.less';
import RenderItem from './RenderItem';
import type { NormalProps } from '../type';

const MTopic: React.FC<NormalProps> = ({
    customStyle,
    isShowNew,
    isShowHot,
    showType,
    setShowType,
    setActiveIndex,
    currentList,
    articleList,
}) => {
    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            <div className={styles.topicBox}>
                <div className={styles.top_box}>
                    <NormalTitle title={intl.get('Home.RecommendedColumn').d('推荐专栏')} />
                    <a href={currentLocaleUrl('/topic/list')} className={styles.wrapper__more} target="_blank">
                        <span className={styles.text__more}>{intl.get('Common.SeeMore').d('查看更多')}</span>
                        <span className={c(styles.arrow__more, 'iconfont', 'icon-arrow-right')} />
                    </a>
                </div>

                <div className={styles.tab_wrapper}>
                    {isShowNew && (
                        <div
                            onClick={() => setShowType('new')}
                            className={c(styles.tab_item, { [styles.active]: showType === 'new' })}
                        >
                            {intl.get('Common.New').d('最新')}
                        </div>
                    )}
                    {isShowHot && (
                        <div
                            onClick={() => setShowType('hot')}
                            className={c(styles.tab_item, { [styles.active]: showType === 'hot' })}
                        >
                            {intl.get('Common.Hot').d('最热')}
                        </div>
                    )}
                </div>

                <div className={styles.swiper_list}>
                    <div className={styles.swiper_box_item}>
                        <Swiper
                            pagination
                            onSlideChange={(swiper: SwiperCore) => {
                                setActiveIndex(swiper.activeIndex);
                            }}
                        >
                            {currentList.map((item: any, i) => {
                                return (
                                    <SwiperSlide className={styles.swiperSlide} key={`${item._id}_${i}`}>
                                        <RenderItem dataSource={item} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>
                <div className={styles.article_list}>
                    {articleList.map((item, index) => {
                        const { _id, seo_url, code, title } = item;
                        return (
                            <a
                                href={currentLocaleUrl(`/article/${seo_url || code}`)}
                                target="_blank"
                                className={c(styles.article_item, { [styles.line]: index !== articleList.length - 1 })}
                                key={`${code || _id}_${index}`}
                            >
                                <div className={styles.article_item_title}>{title}</div>
                            </a>
                        );
                    })}
                    {articleList.length === 0 && <NoContent minHeight={104} />}
                    {articleList.length !== 0 && articleList.length < 3 && <NoMore top={12} />}
                </div>
            </div>
        </div>
    );
};

export default MTopic;
