import TransferCom from '@/components/public/TransferCom';

import MHomeBroker from './m';
import PCHomeBroker from './pc';
import type { HomeBrokerPros } from './types';

const HomeBroker = (props: HomeBrokerPros) => {
    return (
        <TransferCom
            m={<MHomeBroker {...props} />}
            ipad={<PCHomeBroker {...props} />}
            pc={<PCHomeBroker {...props} />}
        />
    );
};

export default HomeBroker;
