import React from 'react';

import { NormalUgc, RecommendUgc } from './components';

import type { VisualUgcProps } from '../type';

const Ugc: React.FC<VisualUgcProps> = (props) => {
    const { styleType } = props;

    return (
        <>
            {styleType === '1' && <NormalUgc {...props} />}
            {styleType === '2' && <NormalUgc {...props} />}
            {styleType === '3' && <RecommendUgc {...props} />}
        </>
    );
};

export default Ugc;
