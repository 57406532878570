import React, { useEffect, useMemo, useState, useRef } from 'react';
import { currentLocaleUrl, formatDomUnit, formatMts } from '@/utils';
import SwiperCore, { Controller, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import c from 'classnames';
import styles from './index.module.less';
import { KeyWordProps } from '../types';

SwiperCore.use([Controller, Navigation]);

const PcKeyWord: React.FC<KeyWordProps> = ({ elementId, keyWords, customStyle }) => {
    const swiperRef = useRef<any>(null);
    const [hasButton, setHasButton] = useState<boolean>(true);

    const arr = useMemo(() => {
        return (keyWords || []).map((item, index) => {
            return { ...item, index };
        });
    }, [keyWords]);

    useEffect(() => {
        const btn = swiperRef?.current?.querySelector('.swiper-button-prev');
        const classnames = btn?.getAttribute('class') || '';
        if (classnames.includes('swiper-button-lock')) {
            setHasButton(false);
        }
    }, []);

    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            <div className={styles.keyWordBox}>
                <Swiper
                    observer
                    resizeObserver
                    grabCursor
                    setWrapperSize
                    slidesPerView="auto"
                    className={c(styles.keyWords, hasButton ? styles.keyWordsHasBtn : null)}
                    navigation={{
                        nextEl: `.swiper-${elementId} .swiper-button-next`,
                        prevEl: `.swiper-${elementId} .swiper-button-prev`,
                    }}
                    ref={swiperRef}
                >
                    {arr.map((item: any) => (
                        <SwiperSlide className={styles.keyWordItem} key={item.index}>
                            <a
                                target="_blank"
                                href={currentLocaleUrl(`/keyword?tags=${formatMts(item.titleMts) || item.title}`)}
                            >
                                {formatMts(item.titleMts) || item.title}
                            </a>
                        </SwiperSlide>
                    ))}
                    <div className={`swiper-${elementId}`}>
                        <div className={c(styles.swiperBtnNext, 'swiper-button-next')}>
                            <div className={styles.swiperBackNext} />
                        </div>
                        <div className={c(styles.swiperBtnPrev, 'swiper-button-prev')}>
                            <div className={styles.swiperBackPrev} />
                        </div>
                    </div>
                </Swiper>
            </div>
        </div>
    );
};

export default PcKeyWord;
