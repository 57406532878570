import React, { useMemo } from 'react';

import TransferCom from '@/components/public/TransferCom';

import { PcNewsFlash, MNewsFlash } from '@/reconstruction/components/public';
import type { IProps } from './type';

const Index: React.FC<IProps> = (props) => {
    const { customStyle } = props;
    const isRightNewsFlash = useMemo(() => customStyle?.blockType === 'right', [customStyle]);

    const rcProps = { ...props, isRightNewsFlash };

    return (
        <TransferCom
            pc={<PcNewsFlash {...rcProps} />}
            m={<MNewsFlash {...rcProps} />}
            ipad={<PcNewsFlash {...rcProps} />}
        />
    );
};

export default Index;
