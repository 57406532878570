import videoJs from 'video.js';
import 'video.js/dist/video-js.css';

interface Props {
    selector: string;
    width: string;
    height: string;
}

export default class CustomVideos {
    selector: string;
    width: string;
    height: string;
    players: any[];
    constructor(props: Props) {
        this.selector = props.selector;
        this.width = props.width;
        this.height = props.height;
        this.players = [];
        this.init();
    }
    initVideo(videoDom: any, src: string) {
        const poster: any = videoDom.poster || '';
        const player: any = videoJs(
            videoDom,
            {
                controls: true, // 是否显示控制条
                preload: 'auto', // 预加载
                aspectRatio: '834:625', // 像素比，解决黑边
                autoplay: false,
                notSupportedMessage: '该视频暂时无法播放，请稍后再试',
                loop: false,
                language: 'zh-CN', // 设置语言
                playbackRates: [2.0, 1.5, 1, 0.5], // 倍速
                controlBar: {
                    children: [
                        'ProgressControl', // 进度条
                        'PlayToggle', // 播放按钮

                        // ---- 时间 Start ----
                        'CurrentTimeDisplay',
                        'timeDivider',
                        'DurationDisplay',
                        // ---- 时间 End ----

                        // {
                        //   name: 'RemainingTimeDisplay', // 时间
                        //   displayNegative: false,
                        // },
                        // 'PlaybackRateMenuButton', // 倍速
                        // 'VolumePanel', // 音量
                        'FullscreenToggle', // 全屏
                    ],
                },
                userActions: {
                    doubleClick: false,
                },
                // preferFullWindow: true, // 全屏使用自定义控件
            },
            () => {
                player.src(src);
                player.on('play', () => {
                    this.players.forEach((item) => {
                        // 多个视频暂停前一个播放
                        if (item !== player) {
                            item.pause();
                        }
                    });
                });
            },
        );

        // 替换封面
        // player.poster(poster);
        // 插入自定义倍率按钮
        player.getChild('controlBar');
        // .addChild('definePlaybackRateMenuButton', {}, 4);
        this.players.push(player);
    }
    // 设置videojs需要的属性
    init() {
        const videos: any = document.querySelector(`${this.selector}`)?.querySelectorAll('video');

        for (const item of videos) {
            item.className = 'video-js vjs-big-play-centered';
            item.setAttribute('playsInline', 'true');
            item.setAttribute('webkit-playsinline', 'true');

            // 可能存在src不在video标签上的情况
            const sources = item.querySelectorAll('source');
            const srcs = Array.from(sources).map((source: any) => source.getAttribute('src'));
            const src = srcs[0] || item.src;

            if (src) {
                this.initVideo(item, src);
            }
        }
    }
    // 销毁video
    dispose() {
        this.players.forEach((player) => {
            if (player) {
                player.dispose();
            }
        });
    }
}
