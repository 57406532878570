import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PCHotNews from './pc';
import MHotNews from './m';
import { HotNewsProps } from './type';

const HotNews: React.FC<HotNewsProps> = (props) => {
    return <TransferCom m={<MHotNews {...props} />} pc={<PCHotNews {...props} />} ipad={null} />;
};

export default HotNews;
