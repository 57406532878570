import React, { useContext, useMemo, useState } from 'react';
import c from 'classnames';
import intl from 'react-intl-universal';

import type { BrokerFilterType } from '@/type/broker';
import { NextImage } from '@/components';

import styles from './index.module.less';
import { AllBrokerContext } from '../../../../context';

interface Props {
    type: 'checkbox' | 'other' | 'country';
    dataSource: BrokerFilterType | BrokerFilterType[];
}

function FilterCheckbox({ type, dataSource }: Props) {
    const { filterClickHandler, currentFilter } = useContext(AllBrokerContext);

    const [showMore, setShowMore] = useState<boolean>(false);

    /** 头部标题 */
    const headerTitle = useMemo(() => {
        switch (type) {
            case 'checkbox':
            case 'country':
                return (dataSource as BrokerFilterType)?.name || '--';
            case 'other':
                return intl.get('Common.Other').d('其他');
        }
    }, [dataSource, type]);

    /** 列表数据 */
    const list = useMemo(() => {
        switch (type) {
            case 'checkbox':
            case 'country':
                return (dataSource as BrokerFilterType).key || [];
            case 'other':
                return (dataSource as BrokerFilterType[]) || [];
        }
    }, [dataSource, type]);

    if (list.length === 0) {
        return null;
    }

    return (
        <div className={styles.checkbox}>
            <div className={styles.header}>
                <em />
                <span className={styles.title}>{headerTitle}</span>
                {list.length > 6 && (
                    <div
                        className={c(styles.more, { [styles.active]: showMore })}
                        onClick={() => setShowMore(!showMore)}
                    >
                        <span>
                            {showMore ? intl.get('Common.PackUp').d('收起') : intl.get('Common.Unfold').d('展开')}
                        </span>
                        <span className={c('iconfont', 'icon-jiantou')} />
                    </div>
                )}
            </div>
            <div className={styles.list}>
                {list.slice(0, showMore ? list.length : 6).map((item: any, index) => {
                    const { value, name = '', label = '' } = item;

                    /** 筛选value */
                    const filterValue = type === 'checkbox' ? (dataSource as BrokerFilterType).value : value;

                    const isActive =
                        currentFilter.findIndex((i) => {
                            return i.filterKey === filterValue && i.value === value;
                        }) !== -1;

                    return (
                        <div
                            key={`${value}__${index}`}
                            onClick={() => filterClickHandler(item, filterValue, type)}
                            className={styles.item}
                        >
                            {isActive ? (
                                <div className={styles.checked}>
                                    <NextImage src="/icon/yigouxuan.webp" layout="fill" needPrefix />
                                </div>
                            ) : (
                                <span className={styles.frame} />
                            )}
                            <div className={styles.label}>{name || label}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default FilterCheckbox;
