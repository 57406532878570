import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Affix } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { resizeObserverFun, PAGE_CENTER_NEW } from '@/utils';
import { NextImage } from '@/components';
import { TopBanner, TopBroker, ColumnGroup } from '../components';
import { useAppSelector } from '@/store/hook';
import c from 'classnames';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import { cloneDeep } from 'lodash';
import type { BrokerContrastProps } from '../type';

const Contrast: React.FC<BrokerContrastProps> = ({
    locale,
    dataSource,
    contrastList = [],
    firstNavs = [],
    bannerData,
    businessList,
    onToContrast,
    onChangeBrokerList,
}) => {
    // 头部高度
    const { topHeaderHeight: topHeight, activeNavKeys = [] } = useAppSelector((state: any) => state.config);

    // 页面内容宽度
    const domRef = useRef<any>(null);
    const [domWidth, setDomWidth] = useState<number>(PAGE_CENTER_NEW);
    useEffect(() => {
        resizeObserverFun(domRef.current, setDomWidth);
    }, []);

    // banner 高度
    const banRef = useRef<any>(null);
    const [banHeight, setBanHeight] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(banRef.current, setBanHeight, 'clientHeight');
    }, []);

    // 一级导航高度
    const [firstAnchor, setFirstAnchor] = useState<string>('part-1');
    const firstNavRef = useRef<any>(null);
    const [firstNavHeight, setFirstNavHeight] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(firstNavRef.current, setFirstNavHeight, 'clientHeight');
    }, []);

    // 二级导航
    const [secondAnchor, setSecondAnchor] = useState<string>('part-1-1');
    const [showSecondNavs, setShowSecondNavs] = useState<boolean>(false);

    const { current: page } = useRef({ timer: null as any, scroll: null as any, isclick: false });
    const [targetOffset, setTargetOffset] = useState<number>(220);

    useEffect(() => {
        setTargetOffset(360 + banHeight + 10);

        page.timer = setTimeout(() => {
            setTargetOffset(192);
        }, 500);

        return () => {
            if (page.timer) {
                clearTimeout(page.timer);
            }
        };
    }, [page, domWidth, banHeight]);

    // 顶部broker tab
    const brokerNum = useMemo(() => {
        return (contrastList || []).filter((item: any) => item?._id).length;
    }, [contrastList]);

    // ------------------------- banner

    const scrollToAnchor = (anchorName: string, first?: boolean, second?: boolean) => {
        if (page.scroll) {
            clearTimeout(page.scroll);
        }
        page.isclick = true;
        if (anchorName) {
            if (first) {
                setShowSecondNavs(true);
                setFirstAnchor(anchorName);
                page.scroll = setTimeout(() => {
                    page.isclick = false;
                }, 1000);
            } else {
                // 找到锚点

                if (second) {
                    setSecondAnchor(anchorName);
                } else {
                    setFirstAnchor(anchorName);
                }
                setShowSecondNavs(false);
                setTimeout(() => {
                    const anchorElement: any = document.getElementById(anchorName);
                    const bodyRect = document.body.getBoundingClientRect().top;
                    const elementRect = anchorElement?.getBoundingClientRect().top;
                    const elementPosition = elementRect - bodyRect;
                    const offsetPosition = elementPosition - targetOffset;
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    });
                }, 50);
                page.scroll = setTimeout(() => {
                    page.isclick = false;
                }, 1000);
            }
        }
    };

    const secondNavs = useMemo(() => {
        const activeItem = (firstNavs || []).find((item: any) => item?.key === firstAnchor);
        const { children: sNavs = [] } = activeItem || {};
        return sNavs || [];
    }, [firstNavs, firstAnchor]);

    const onScroollFun = useCallback(() => {
        if (page.isclick) {
            return;
        }
        const cH = document.documentElement.clientHeight;

        firstNavs.forEach((item: any) => {
            const { key } = item;
            const anchorElement: any = document.getElementById(key);
            const eleTopRect = anchorElement?.getBoundingClientRect().top;
            const eleBotRect = anchorElement?.getBoundingClientRect().bottom;
            if (eleTopRect <= 128 || eleBotRect < cH + 128) {
                setFirstAnchor(key);
            }
        });

        secondNavs.forEach((item: any) => {
            const { key } = item;
            const anchorElement: any = document.getElementById(`${firstAnchor}.${key}`);
            const eleTopRect = anchorElement?.getBoundingClientRect().top;
            if (eleTopRect <= 128 && eleTopRect > -200) {
                setSecondAnchor(`${firstAnchor}.${key}`);
            }
        });
    }, [firstAnchor, firstNavs, page.isclick, secondNavs]);

    useEffect(() => {
        window.addEventListener('scroll', onScroollFun, { passive: true });

        return () => {
            window.removeEventListener('scroll', onScroollFun);
        };
    }, [onScroollFun]);

    /**
     * 交易商切换
     * @param i
     * @param j
     */
    const onBrokerMove = (i: number, j: number, disabled?: boolean) => {
        if (disabled) {
            return;
        }
        const cloneList = cloneDeep(contrastList || []);
        cloneList[i] = (contrastList || [])[j];
        cloneList[j] = (contrastList || [])[i];
        onToContrast(cloneList);
        onChangeBrokerList(cloneList);
    };

    return (
        <div className={c(styles.wrapper, { [styles.margin_top]: (activeNavKeys || []).length < 2 })}>
            <div className={styles.banner} ref={banRef}>
                <TopBanner locale={locale} bannerData={bannerData} />
            </div>

            <div className={styles.wrapper_main} ref={domRef}>
                <Affix offsetTop={topHeight}>
                    <div className={styles.first_navs_box}>
                        <Swiper
                            observer
                            observeParents
                            mousewheel
                            resizeObserver
                            grabCursor
                            spaceBetween={40}
                            slidesPerView="auto"
                            className={styles.first_navs}
                            ref={firstNavRef}
                        >
                            {firstNavs.map((item: any) => {
                                const { key, title, title_mts, children = [] } = item;

                                return (
                                    <SwiperSlide
                                        key={key}
                                        className={c(styles.first_item, {
                                            [styles.active]: key === firstAnchor,
                                            [styles.more_active]:
                                                key === firstAnchor &&
                                                children.length !== 0 &&
                                                showSecondNavs &&
                                                secondNavs?.length !== 0,
                                        })}
                                        onClick={() => scrollToAnchor(key, children.length !== 0)}
                                    >
                                        {intl.get(title_mts).d(title)}
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </Affix>

                {secondNavs?.length !== 0 && showSecondNavs ? (
                    <Affix offsetTop={topHeight + firstNavHeight}>
                        <Swiper
                            observer
                            observeParents
                            mousewheel
                            resizeObserver
                            grabCursor
                            spaceBetween={40}
                            slidesPerView="auto"
                            className={styles.second_navs}
                        >
                            {secondNavs.map((item: any) => {
                                const { key, title, title_mts } = item;

                                return (
                                    <SwiperSlide
                                        key={`${firstAnchor}.${key}`}
                                        className={c(styles.second_item, {
                                            [styles.active]: `${firstAnchor}.${key}` === secondAnchor,
                                        })}
                                        onClick={() => scrollToAnchor(`${firstAnchor}.${key}`, false, true)}
                                    >
                                        {intl.get(title_mts).d(title)}
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </Affix>
                ) : null}

                <div className={styles.wrapper_con}>
                    <ul className={styles.top_tabs}>
                        <li>
                            <p>{intl.get('Broker.Chosen').d('已选择')}</p>
                            <p>
                                {brokerNum}
                                {intl.get('Broker.CompareInThree').d('/3交易商进行对比')}
                            </p>
                        </li>
                        {contrastList.map((item: any, index: number) => {
                            const disabled = !(item?._id && contrastList[index + 1]?._id);

                            return (
                                <li key={item.key} className={c({ [styles.no_right_border]: index < 2 })}>
                                    <TopBroker
                                        index={index}
                                        record={item}
                                        contrastList={contrastList}
                                        businessList={businessList}
                                        onChange={onChangeBrokerList}
                                        onToContrast={onToContrast}
                                    />

                                    {index < 2 && (
                                        <span
                                            className={c(styles.switch, { [styles.switch_dis]: disabled })}
                                            onClick={() => onBrokerMove(index, index + 1, disabled)}
                                        >
                                            <NextImage
                                                src="/broker/icon/switch.webp"
                                                needPrefix
                                                alt="switch"
                                                width={34}
                                                height={34}
                                            />
                                        </span>
                                    )}
                                </li>
                            );
                        })}
                    </ul>

                    <ColumnGroup dataSource={dataSource} brokerList={contrastList} />
                </div>
            </div>
        </div>
    );
};

export default Contrast;
