import TransferCom from '@/components/public/TransferCom';
import MAllBroker from './m';
import PCAllBroker from './pc';
import type { AllBrokerPros } from './types';

const AllBroker: React.FC<AllBrokerPros> = (props) => {
    return (
        <TransferCom m={<MAllBroker {...props} />} ipad={<PCAllBroker {...props} />} pc={<PCAllBroker {...props} />} />
    );
};

export default AllBroker;
