import TransferCom from '@/components/public/TransferCom';
import { MBrokerAll, PCBrokerAll } from '@/reconstruction/components/pages/broker/all';
import { getBrokerValues, getBusinessList, getScreenList } from '@/services/broker';
import { getVisualPage } from '@/services/manage';
import { formatNavSeoMts, redirectFun } from '@/utils';

interface Props {
    businessList: any[];
    /** 服务端数据 */
    serviceData: {
        list: any[];
        hasMore: boolean;
    };
    /** 首屏筛选数据 */
    filterList: any[];
    sortList: any[];
    globalData: any;
}

const Page: React.FC<Props> = (props) => {
    return <TransferCom pc={<PCBrokerAll {...props} />} ipad={null} m={<MBrokerAll {...props} />} />;
};

const getServerSideProps = async (params: any, globalData: any, navId: string, pageId: string, metaData: any) => {
    const { locale } = params || {};
    const { baseConfig = {} } = globalData;
    const { showNewLayout = false } = baseConfig;

    const businessRes = (await getBusinessList(locale)) || {}; // 获取业务
    const { data, state } = businessRes || {};
    if (Number(state) !== 1) {
        return redirectFun(businessRes);
    }
    const businessList = data || [];
    const { id } = businessList[0] || {};
    const { pageSize }: any = process.env.NEXT_PUBLIC_SETTING;
    const brokerValues = (await getBrokerValues({ type: id }, locale)) || {}; // 获取筛选项
    const { sort = [], filter = [] } = brokerValues.data || {};
    const param = {
        // 列表参数
        sort_field: Object.keys(sort[0] || {})[0] || '',
        sort_type: -1,
        broker_screen: [{ dict_type: 'type', dict_name: [], dict_key: id }],
        is_all_country: 1,
        country: [],
    };

    // 根据页面类型获取数据
    const [listRes, visualRes] = await Promise.all([getScreenList(param, locale), getVisualPage(pageId, locale)]);

    const { list = [], total } = listRes?.data || {};

    if (visualRes?.state !== 1) {
        visualRes.data = {};
    }
    const { state: pageState } = visualRes.data;

    return {
        props: {
            metaData,
            businessList,
            showNewLayout,
            filterList: filter,
            navId,
            sortList: sort,
            serviceData: { list, hasMore: total > pageSize },
            pageData: { auth: pageState === 1 },
        },
    };
};

export default {
    Page,
    getServerSideProps,
};
