import React from 'react';
import c from 'classnames';

import type { ArticleType } from '@/type/article';
import { NextImage } from '@/components';
import { NoContent } from '@/components/public';
import { NoMore } from '@/reconstruction/components/public';
import { currentLocaleUrl } from '@/utils';

import styles from './index.module.less';

function ArticlesList({ list = [] }: { list: ArticleType[] }) {
    return (
        <div className={styles.article_list}>
            {list.map((item, index) => {
                const { _id, union_id, seo_url, code, title, cover } = item || {};

                return (
                    <a
                        href={currentLocaleUrl(`/article/${seo_url || code}`)}
                        target="_blank"
                        key={code || union_id || _id}
                        className={c(styles.article_list_item, { [styles.one]: index === 0 })}
                    >
                        <div className={styles.article_list_title}>{title}</div>
                        <div className={styles.article_list_cover}>
                            <NextImage layout="fill" src={cover?.url || ''} />
                        </div>
                    </a>
                );
            })}
            {list.length === 0 && <NoContent />}
            {list.length !== 0 && list.length < 3 && <NoMore top={12} />}
        </div>
    );
}

export default ArticlesList;
