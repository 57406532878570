import React, { useContext, useMemo } from 'react';
import { Slider } from 'antd';
import intl from 'react-intl-universal';

import { BrokerFilterType } from '@/type/broker';

import styles from './index.module.less';
import { AllBrokerContext } from '../../../../context';

interface Props {
    dataSource: BrokerFilterType;
}

function FilterSlider({ dataSource }: Props) {
    const { currentFilter, sliderFilterChangeHandler } = useContext(AllBrokerContext);

    const rangeList: [number, number] = useMemo(() => {
        const record = currentFilter.find((item) => {
            return item.filterKey === dataSource.value;
        });
        const startIndex = record?.rangeList?.[0] || 0;
        const endIndex = record?.rangeList?.[1] || 0;

        return [startIndex, endIndex];
    }, [currentFilter]);

    /** 列表数据 */
    const list = useMemo(() => {
        return dataSource.key.filter((item) => item.label !== intl.get('Broker.OtherFilters').d('其他'));
    }, [dataSource]);

    /** 刻度 */
    const marks = useMemo(() => {
        const result: any = {};

        const maxLen = list.length - 1;

        list.reduce((init, item, index) => {
            const { value, label } = item;
            init[value] = {
                tooltip: label,
                label: null,
            };

            if ([0, maxLen].includes(index)) {
                init[value].label = label;
            }
            return init;
        }, result);

        return result;
    }, [dataSource]);

    /** 头部标题 */
    const headerTitle = useMemo(() => {
        if (rangeList[0] === rangeList[1]) {
            return dataSource.name;
        }
        const minText = marks[rangeList[0]]?.tooltip || '';
        const maxText = marks[rangeList[1]]?.tooltip || '';
        return `${dataSource.name} ${minText}-${maxText}`;
    }, [dataSource, marks, rangeList]);

    /** 最大值 */
    const max = useMemo(() => {
        return list[list.length - 1].value;
    }, [dataSource]);

    /** 最小值 */
    const min = useMemo(() => {
        return list[0].value;
    }, [dataSource]);

    const onSliderChange = (value: [number, number]) => {
        if (value[0] === value[1]) {
            return;
        }

        sliderFilterChangeHandler(dataSource, value);
    };

    return (
        <div className={styles.slider__wrapper}>
            <div className={styles.header}>
                <em />
                <span className={styles.title}>{headerTitle}</span>
            </div>
            <div className={styles.slider}>
                <Slider
                    min={min}
                    max={max}
                    value={rangeList}
                    tooltip={{
                        formatter: (value) => {
                            return value ? marks[value].tooltip : '';
                        },
                    }}
                    onChange={onSliderChange as any}
                    range={true}
                    marks={marks}
                />
            </div>
        </div>
    );
}

export default FilterSlider;
