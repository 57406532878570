import React, { useState, useMemo, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import intl from 'react-intl-universal';
import c from 'classnames';

import { formatDomUnit, currentLocaleUrl } from '@/utils';
import { NormalTitle } from '@/reconstruction/components/public';

import styles from './index.module.less';
import SwiperSlideItem from './SwiperSlideItem';
import { type TopicAttentionProps } from '../../../type';

const MTopic: React.FC<TopicAttentionProps> = ({ customStyle, serviceData }) => {
    // 当前显示页
    const [topicList, setTopicList] = useState(serviceData?.topic?.list || []);
    const [ugcList, setUgcList] = useState(serviceData?.ugc?.list || []);
    const [showType, setShowType] = useState<'topic' | 'ugc'>('topic'); // 展示类别

    useEffect(() => {
        setTopicList(serviceData?.topic?.list || []);
        setUgcList(serviceData?.ugc?.list || []);
    }, [serviceData]);

    // 当前展示列表
    const currentList = useMemo(() => {
        switch (showType) {
            case 'topic':
                return topicList.slice(0, 5);
            case 'ugc':
                return ugcList.slice(0, 5);
        }
    }, [showType, topicList, ugcList]);

    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <NormalTitle title={intl.get('Home.MyFocus').d('我的关注')} />

                    <a href={currentLocaleUrl('/center?type=focus')} className={styles.wrapper__more} target="_blank">
                        <span className={styles.text__more}>{intl.get('Common.SeeMore').d('查看更多')}</span>
                        <span className={c(styles.arrow__more, 'iconfont', 'icon-arrow-right')} />
                    </a>
                </div>

                <div className={styles.tab_wrapper}>
                    {topicList.length > 0 && (
                        <div
                            onClick={() => setShowType('topic')}
                            className={c(styles.tab_item, { [styles.active]: showType === 'topic' })}
                        >
                            {intl.get('Common.Topic').d('专栏')}
                        </div>
                    )}
                    {ugcList.length > 0 && (
                        <div
                            onClick={() => setShowType('ugc')}
                            className={c(styles.tab_item, { [styles.active]: showType === 'ugc' })}
                        >
                            {intl.get('Common.Ugc').d('创作者')}
                        </div>
                    )}
                </div>

                <Swiper className={styles.swiper_list} pagination spaceBetween={12}>
                    {currentList.map((item: any, i: number) => {
                        return (
                            <SwiperSlide key={item.code || item._id || item.name}>
                                <SwiperSlideItem dataSource={item} showType={showType} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
};

export default MTopic;
