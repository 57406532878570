import request from '@/utils/axios';

/* 获取活动列表数据 */
export const getActivityList = (data: any, locale?: string) => {
    return request.post('/japi/activity/multiple/select/activity', data, { locale });
};

/* 获取活动城市 */
export const getActivityCity = (locale?: string) => {
    return request.get('/japi/activity/get/activity/city', {}, { locale });
};

/* 活动根据时间分组 */
export const getActivityTimeGroup = (
    data: {
        startTime: string;
        endTime: string;
    },
    locale?: string,
) => {
    return request.post('/japi/activity/group/activity/time', data, { locale });
};
