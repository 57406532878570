import TransferCom from '@/components/public/TransferCom';
import MBillboard from './m';
import PCBillboard from './pc';
import type { BillboardPros } from '../../types';

const Billboard: React.FC<BillboardPros> = (props) => {
    return (
        <TransferCom m={<MBillboard {...props} />} ipad={<PCBillboard {...props} />} pc={<PCBillboard {...props} />} />
    );
};

export default Billboard;
