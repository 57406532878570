import React, { useContext, useEffect, useRef, useState } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import c from 'classnames';
import intl from 'react-intl-universal';
import type { SwiperRef } from 'swiper/react';

import styles from './index.module.less';
import { AllBrokerContext } from '../../../../context';

SwiperCore.use([Navigation]);

function BusinessHeader() {
    const { businessList, businessClickHandler, businessActiveIndex } = useContext(AllBrokerContext);
    const locale = intl.getInitOptions().currentLocale;
    // swiper
    const [swiperRef, setSwiperRef] = useState<any>(undefined);
    const isInit = useRef<boolean>(true);

    useEffect(() => {
        if (isInit.current) {
            isInit.current = false;
            return;
        }
        swiperRef?.slideTo(0);
    }, [locale]);

    return (
        <Swiper
            observer
            resizeObserver
            grabCursor
            spaceBetween={24}
            onSwiper={setSwiperRef}
            controller={{ control: swiperRef }}
            slidesPerView="auto"
            className={c(styles.business)}
            navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }}
        >
            {businessList.map((item, index: number) => {
                const { business, id } = item;

                if (!business) {
                    return null;
                }

                return (
                    <SwiperSlide
                        className={c(styles.business__item, { [styles.active]: businessActiveIndex === index })}
                        key={id}
                        onClick={() => {
                            businessClickHandler(item, index);
                        }}
                    >
                        {item.business}
                    </SwiperSlide>
                );
            })}
            <div>
                <div className={c(styles.swiperBtnPrev, 'swiper-button-prev')}>
                    <span className={c('iconfont', 'icon-arrow-left', styles.button)} />
                </div>

                <div className={c(styles.swiperBtnNext, 'swiper-button-next')}>
                    <span className={c('iconfont', 'icon-arrow-right', styles.button)} />
                </div>
            </div>
        </Swiper>
    );
}

export default BusinessHeader;
