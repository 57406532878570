import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import c from 'classnames';
import intl from 'react-intl-universal';

import { Normal } from '@/components/visual';
import NavTabItem from '@/reconstruction/components/public/Tabs/NavTabItem';
import { ArticlesList, ActivityList } from '@/components';
import { Tabs } from '@/reconstruction/components/public';
import { formatDomUnit, formatMts, currentLocaleUrl, getVisualSkipPage } from '@/utils';
import { useAppSelector } from '@/store/hook';
import TransferCom from '@/components/public/TransferCom';

import FlashNewsList from '@/components/visual/FlashNews/list';
import styles from './index.module.less';

interface Props {
    elementId: string;
    navs: any[];
    customStyle: any;
    defaultActiveTab?: number;
    hasArrow?: boolean;
    hasUnderline?: boolean;
    groupTitle?: string;
    groupTitleMts?: {
        zh_cns?: string;
        en_us?: string;
    };
    serviceData?: {
        list: any[];
        hasMore: boolean;
    };
    lineNum: number;
    flashFailarmy: any;
    flashTitle: string;
    flashTitleUrl: string;
    groupTitleUrl: string;
    /** 容器类型 page-页面 box-容器组件 */
    containerType?: 'page' | 'box' | string;
}

const Nav: React.FC<Props> = (props) => {
    const {
        elementId,
        navs,
        customStyle,
        defaultActiveTab = 0,
        hasArrow,
        hasUnderline,
        groupTitle,
        groupTitleMts,
        serviceData,
        lineNum,
        groupTitleUrl,
        containerType = 'page',
        ...rest
    } = props;

    const [params, setParams] = useState<any>({ api: '', extraParams: {} }); // 滚动列表参数

    const [activeItem, setActiveItem] = useState<any>(navs[defaultActiveTab].openInfo); // 当前tab 数据

    const [activeTab, setActiveTab] = useState<number>(defaultActiveTab); // 当前tab 索引

    const InfiniteScrolleRef = useRef<any>(); // 滚动列表 ref

    const { imgPrefix, showBarkly } = useAppSelector((state: any) => {
        return state.config;
    });
    // 设置参数
    const setNavParams = useCallback((item: any) => {
        const { type, blockApi, activitys } = item || {};
        const { pageSize }: any = process.env.NEXT_PUBLIC_SETTING;
        if (type) {
            switch (type) {
                case 'block':
                case 'blocks':
                case 'videos':
                    if (blockApi) {
                        const apiUrl = blockApi.split('?')[0];
                        const apiParamsArr = blockApi.split('?')[1].split('&');
                        const apiParams: any = { pageSize };
                        apiParamsArr.forEach((item: string) => {
                            const key = item.split('=')[0];
                            const value = item.split('=')[1];
                            apiParams[key] = value;
                        });
                        setParams({ api: apiUrl, extraParams: apiParams });
                    }
                    break;
                case 'activitys':
                    setParams({
                        api: '/japi/activity/list/channel',
                        extraParams: { pageSize, activityChannel: activitys.id },
                    });
                    break;
                case 'page':
                    break;
                default:
                    console.log('未找到该类型：', type);
            }
        }
    }, []);

    useEffect(() => {
        const openInfo = navs[activeTab].openInfo;
        setActiveItem(openInfo);
        setNavParams(openInfo);
    }, [activeItem, activeTab, navs, setNavParams]);

    const ENUM_COMPONENTS: any = {
        quick: FlashNewsList,
        block: ArticlesList,
        blocks: ArticlesList,
        activitys: ActivityList,
        videos: ArticlesList,
    };

    const ListComponents: any = ENUM_COMPONENTS[activeItem?.blocks?.isQuick ? 'quick' : activeItem.type];

    /** 导航 */
    const tabsRender = useMemo(() => {
        return (
            <Tabs
                isLink
                isFixed={false}
                className={styles.tabs}
                elementId={elementId}
                hasUnderline={hasUnderline}
                list={navs}
                defaultActiveTab={defaultActiveTab}
                onTabChange={(tab) => {
                    setActiveTab(tab);
                }}
                itemRender={(data, activeTab) => (
                    <NavTabItem data={data} hasUnderline={hasUnderline} activeTab={activeTab} />
                )}
            />
        );
    }, [setActiveTab, defaultActiveTab, navs, elementId]);

    /** 是否文章类型 */
    const isArticle = useMemo(() => {
        return ['block', 'blocks'].includes(activeItem.type);
    }, [activeItem]);

    const headerRender = useMemo(() => {
        const showTitle = hasArrow && (formatMts(groupTitleMts) || groupTitle);
        return (
            <TransferCom
                m={
                    <div className={styles.wrapper__header__h5}>
                        <h2 className={styles.wrapper__h2}>
                            <div style={{ minWidth: 10 }}>
                                {showTitle && <span>{formatMts(groupTitleMts) || groupTitle}</span>}
                            </div>
                            {groupTitleUrl && (
                                <a
                                    href={currentLocaleUrl(getVisualSkipPage(groupTitleUrl))}
                                    target="_blank"
                                    className={styles.top_link}
                                >
                                    {intl.get('Common.SeeMore').d('查看更多')}
                                </a>
                            )}
                        </h2>
                        {tabsRender}
                    </div>
                }
                ipad={null}
                pc={
                    <div
                        className={c(styles.wrapper__header, {
                            [styles.barkly_container]: showBarkly,
                        })}
                    >
                        <div className={c(styles.wrapper__header_line, hasUnderline && styles.has_underline)}>
                            {showTitle && (
                                <div className={styles.wrapper__title}>
                                    <h2>{formatMts(groupTitleMts) || groupTitle}</h2>
                                </div>
                            )}
                            {tabsRender}
                            {groupTitleUrl && (
                                <a
                                    href={currentLocaleUrl(getVisualSkipPage(groupTitleUrl))}
                                    target="_blank"
                                    className={styles.top_link}
                                >
                                    {intl.get('Common.SeeMore').d('查看更多')}
                                </a>
                            )}
                        </div>
                    </div>
                }
            />
        );
    }, [hasArrow, groupTitle, groupTitleMts, groupTitleUrl, imgPrefix, tabsRender, showBarkly]);

    /** 列表的列数 */
    const listColumn = useMemo(() => {
        switch (containerType) {
            case 'box':
                return 3;
            case 'page':
            default:
                return 4;
        }
    }, [containerType]);

    /** 行数类别 */
    const lineType = useMemo(() => {
        switch (lineNum) {
            case 0:
                return 'infinite'; // 无线
            case 3:
            default:
                return 'three'; // 三行
        }
    }, [lineNum]);

    return (
        <div
            className={c(
                styles.wrapper__nav,
                {
                    [styles.wrapper__article]: isArticle,
                    [styles.barkly_container]: showBarkly,
                },
                styles[`column__${listColumn}`],
            )}
            style={{ ...formatDomUnit(customStyle || {}) }}
        >
            <div className={styles.tabsConBox}>
                {headerRender}

                {isArticle ? (
                    <ListComponents
                        serviceData={serviceData}
                        blockApi={activeItem?.blockApi || ''}
                        list={serviceData?.list}
                        lineType={lineType}
                        listColumn={listColumn}
                        options={serviceData?.list}
                        type={activeItem.type}
                    />
                ) : (
                    <div className={styles.tabsCon}>
                        {
                            /**活动、视频*/
                            ['activitys', 'videos'].includes(activeItem.type) && (
                                <ListComponents
                                    serviceData={serviceData}
                                    blockApi={activeItem?.blockApi || ''}
                                    list={serviceData?.list}
                                    lineType={lineType}
                                    listColumn={listColumn}
                                    options={serviceData?.list}
                                    type={activeItem.type}
                                />
                            )
                        }

                        {
                            /**页面*/
                            ['page'].includes(activeItem.type) && (
                                <>
                                    <Normal pageData={serviceData} />
                                </>
                            )
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default Nav;
