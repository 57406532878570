import React, { useState, useMemo } from 'react';
import { App } from 'antd';
import { NextImage } from '@/components';
import { MBrokerSelect } from '@/reconstruction/components/public';
import BusinessTag from '@/reconstruction/components/public/BusinessTag';
import cloneDeep from 'lodash/cloneDeep';
import { nanoid } from 'nanoid';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import c from 'classnames';
import type { TopBrokerProps } from '../type';

const TopBroker: React.FC<TopBrokerProps> = ({
    index,
    record,
    contrastList = [],
    businessList = [],
    onChange,
    onToContrast,
}) => {
    const { message } = App.useApp();
    const { _id, logo, name_abbreviation, business_name } = record || {};

    // 更换交易商
    const [brokerOpen, setBrokerOpen] = useState<boolean>(false);

    /**
     * 更换交易商
     * @param v
     * @returns
     */
    const onBrokerChange = (v: any) => {
        const cloneList = cloneDeep(contrastList);
        const hasItem = cloneList.find((item: any, i: number) => item._id === v.id && i !== index);

        const nowItem = cloneList.find((item: any, i: number) => item._id === v.id && i === index);

        if (hasItem) {
            message.warning(intl.get('Broker.BrokerLWarnMsg').d('该交易商已在对比列表'));
            return;
        }

        if (nowItem) {
            return;
        }

        cloneList.splice(index || 0, 1, { ...v, edit: false });
        const newList = cloneList.map((item: any) => ({ ...item, type: v?.type || item?.type }));
        onChange?.(newList);
        onToContrast?.(newList);
    };

    /**
     * 清除交易商
     */
    const onClearItem = () => {
        const cloneList = cloneDeep(contrastList || []);
        cloneList[index] = { key: nanoid() };
        onChange(cloneList);
        // onToContrast?.(cloneList);
    };

    /**
     * 当前 业务类型
     */
    const activeType = useMemo(() => {
        const { type } = contrastList.find((item: any, i: number) => item.type && i !== index) || {};
        return type;
    }, [contrastList]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.main}>
                <div className={styles.logo}>
                    {_id ? (
                        <div className={styles.img}>
                            <NextImage
                                layout="fill"
                                src={_id ? logo : '/img/place/img_logo_b_new.webp'}
                                alt={name_abbreviation || 'broker-logo'}
                                place="img_logo_b_new"
                            />
                        </div>
                    ) : (
                        <div className={styles.add_btn} onClick={() => setBrokerOpen(true)}>
                            <span className={c('iconfont', 'icon-jiahao', styles.add_icon)} />
                            <span className={styles.add_text}>{intl.get('Broker.AddTrader').d('添加交易商')}</span>
                        </div>
                    )}
                </div>
                <BusinessTag name={business_name} site="leftTop" />

                {_id ? (
                    <span className={styles.close} onClick={onClearItem}>
                        <NextImage src="/broker/icon/broker-clear.webp" needPrefix alt="clear" width={16} height={16} />
                    </span>
                ) : null}
            </div>

            <MBrokerSelect
                open={brokerOpen}
                activeIndex={index}
                activeType={activeType}
                businessList={businessList}
                onChange={onBrokerChange}
                onClose={() => setBrokerOpen(false)}
            />
        </div>
    );
};

export default TopBroker;
