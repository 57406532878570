import React, { useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { Share } from '@/components';
import {
    formaTimerLetter,
    getHtmlPlainText,
    getTagsLen,
    sourceUrlHandler,
    currentLocaleUrl,
    filterSpace,
} from '@/utils';
import c from 'classnames';
import styles from './list.module.less';
import intl from 'react-intl-universal';

interface Props {
    type?: string;
    keyword?: string;
    options: any[];
}

const FlashNewsList: React.FC<Props> = (props) => {
    const { options = [], type, keyword } = props;

    // useEffect(() => {
    //     addHrefEventListener();
    // }, [options]);

    const newOptions = useMemo(() => {
        return formaTimerLetter([...options]) || {};
    }, [options]);

    // 搜索替换
    const replaceTitle = (tit: string, val: string) => {
        return (tit || '').replace(new RegExp('' + val + '', 'g'), `<span>${val}</span>`);
    };

    // 搜索时 用到
    const formatTitle = useCallback(
        (tit: string) => {
            switch (type) {
                case 'tag':
                    return tit;
                case 'title':
                    return keyword ? replaceTitle(tit, keyword) : tit;
                default:
                    return tit;
            }
        },
        [type, keyword],
    );

    return (
        <div className={styles.flashNewsContain}>
            {Object.keys(newOptions).map((date: any) => {
                const dateTimer = Number(date);
                const yearDate = dayjs(dateTimer).format('YYYY.MM.DD');

                const newlist = newOptions[date] || [];

                return (
                    <div key={date} className={styles.flashNewsList}>
                        {[...newlist].map((item: any, i: number) => {
                            const {
                                pk,
                                code,
                                seo_url,
                                title = '',
                                datetime,
                                source_url,
                                introduction = '',
                                source_id,
                                source_code,
                                tag_info = [],
                                thumbnail_pics = [],
                            } = item;

                            const tags = getTagsLen(tag_info, 3, keyword);
                            const href = `/article/${seo_url || code}`;
                            const timer = datetime ? dayjs(datetime).format('HH:mm') : '';
                            const cover = thumbnail_pics[0]?.url;
                            const introdu = filterSpace(getHtmlPlainText(introduction));

                            const sourceLink = sourceUrlHandler(source_code, source_id, source_url);

                            return (
                                <div key={pk}>
                                    {i === 0 ? <div className={styles.flashNewsDate}>{yearDate}</div> : null}

                                    <div key={pk} className={styles.flashNewsItem}>
                                        <div className={styles.flashNewsTimer}>{timer}</div>
                                        <div className={styles.flashNewsCon}>
                                            <a
                                                className={styles.flashNewsTitle}
                                                href={currentLocaleUrl(href)}
                                                target="_blank"
                                                dangerouslySetInnerHTML={{ __html: formatTitle(title) }}
                                            />
                                            {introdu ? (
                                                <div
                                                    className={styles.flashNewsIntrod}
                                                    dangerouslySetInnerHTML={{ __html: introdu }}
                                                />
                                            ) : null}
                                            <div
                                                className={c(
                                                    styles.sourceUrl,
                                                    !sourceLink ? styles.sourceUrlNull : null,
                                                )}
                                            >
                                                <a target="_blank" href={currentLocaleUrl(sourceLink)}>
                                                    {intl.get('PersonalCenter.OriginalLink').d('[原文链接]')}
                                                </a>
                                            </div>

                                            <div className={styles.flashNewsMsg}>
                                                <div className={styles.flashNewsTag}>
                                                    {tags.map((tag: any) => {
                                                        return (
                                                            <a
                                                                key={tag}
                                                                target="_blank"
                                                                href={currentLocaleUrl(`/keyword?tags=${tag}`)}
                                                                className={
                                                                    keyword !== '原创' &&
                                                                    keyword === tag &&
                                                                    type === 'tag'
                                                                        ? styles.searchOriginal
                                                                        : undefined
                                                                }
                                                            >
                                                                {tag}
                                                            </a>
                                                        );
                                                    })}
                                                </div>
                                                <div className={styles.flashNewsIcon}>
                                                    <Share
                                                        type="twitter"
                                                        size={20}
                                                        title={title}
                                                        url={href}
                                                        cover={cover}
                                                        style={{ marginRight: 20 }}
                                                    />
                                                    <Share
                                                        type="facebook"
                                                        size={20}
                                                        title={title}
                                                        url={href}
                                                        cover={cover}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default FlashNewsList;
