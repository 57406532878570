import React, { useContext, useMemo } from 'react';
import { Calendar } from 'antd';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
import c from 'classnames';
import type { Dayjs } from 'dayjs';

import { useAppSelector } from '@/store/hook';

import styles from './index.module.less';
import { CalendarContext } from '../../context';
import { CalendarItem } from '../../type';
import intl from 'react-intl-universal';

dayjs.extend(isBetween);

const CalendarWatch = () => {
    const { imgPrefix } = useAppSelector((state: any) => {
        return state.config;
    });

    const { currentMon, currentData, setCurrentData, switchMon, list } = useContext(CalendarContext);

    /** 头部渲染 */
    const headerRender = () => {
        return (
            <div className={styles.header__wrapper}>
                <div className={c(styles.switch_btn, styles.disabled)} onClick={() => switchMon('down')}>
                    <span className={c('iconfont', 'icon-jiantou', styles.icon, styles.left)} />
                </div>
                <div className={styles.header__time}>{currentMon.format('YYYY/MM')}</div>
                <div className={c(styles.switch_btn, styles.disabled)} onClick={() => switchMon('up')}>
                    <span className={c('iconfont', 'icon-jiantou', styles.icon, styles.right)} />
                </div>
            </div>
        );
    };

    /** 展示范围 */
    const validRange: [Dayjs, Dayjs] = useMemo(() => {
        const start = currentMon.startOf('month');
        const end = currentMon.endOf('month');
        return [start, end];
    }, [currentMon]);

    /** 单元格渲染 */
    const dateFullCellRender = (date: Dayjs) => {
        const isShow = date.isBetween(...validRange, 'day', '[]');

        if (!isShow) {
            return null;
        }

        const isToday = date.isSame(dayjs(), 'day'); // 是否今天
        const showActive = date.isSame(currentData, 'day'); // 是否选中

        const activityNum = list.reduce((init: number, item: CalendarItem) => {
            if (dayjs(item.data).isSame(date, 'day')) {
                return item.count;
            }
            return init;
        }, 0); // 活动数量

        const showActivity = activityNum > 0;

        return (
            <div className={c(styles.time__wrapper, isToday && styles.today, { [styles.active]: showActive })}>
                <div className={styles.time__day}>{date.get('date')}</div>
                {showActivity && (
                    <div className={styles.time__activity}>
                        {intl.get('Activity.ActivityNum', { activityNum: activityNum }).d(`${activityNum}个活动`)}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.wrapper} style={{ backgroundImage: `url('${imgPrefix}/activity/calendar-bg.webp')` }}>
            <Calendar
                value={currentMon}
                headerRender={headerRender}
                onSelect={(date) => setCurrentData(date)}
                validRange={validRange}
                fullscreen={false}
                dateFullCellRender={dateFullCellRender}
            />
        </div>
    );
};

export default CalendarWatch;
