import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, Select, Input, Divider } from 'antd';
import { NextImage } from '@/components';
import { getBrokerList, getBrokerTopOrRecList } from '@/services/broker';
import c from 'classnames';
import debounce from 'lodash/debounce';
import styles from './index.module.less';
import formUtils from '@/utils/form';
import { formatBrokerBusiness } from '@/utils/mts';
import intl from 'react-intl-universal';

const { pleaseEnter } = formUtils;
interface Props {
    prop?: string;
    value?: any;
    index: number;
    placeholder?: string;
    dataList: any[];
    onChange: (a?: any) => void;
}

const { Option } = Select;

const BrokerSelect: React.FC<Props> = (props) => {
    const locale = intl.getInitOptions().currentLocale;

    const [form] = Form.useForm();
    const { placeholder, dataList = [], onChange } = props;
    const boxRef = useRef<any>(null);
    const inpRef = useRef<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [searchVal, setSearchValue] = useState<string>('');
    // 交易商下拉选择
    const [brokerList, setBrokerList] = useState<any[]>([]);
    const { current: page } = useRef({ pageSize: 20, total: 0, current: 1, loading: false });

    // 自定义搜索
    const onSearchChange = debounce((e: any) => {
        const { value } = e.target;
        setSearchValue(value);
        if (value) {
            getBrokerFun(1, value);
        } else {
            getRecommendList();
        }
    }, 200);

    // 数据改变
    const onValueChange = (v: any) => {
        setSearchValue('');
        if (v) {
            form.resetFields();
            const item = brokerList.find((item: any) => item.value === v);
            onChange?.(item);
        }
    };

    const getRecommendList = useCallback(() => {
        getBrokerTopOrRecList({
            state: 1,
            is_top_or_recommend: '1',
        }).then((res: any) => {
            const { state, data } = res || {};
            if (state === 1) {
                const { list = [] } = data || {};
                const newList = (list || [])
                    .map((item: any) => {
                        return { ...item, value: item?.id, label: item?.name_abbreviation };
                    })
                    .slice(0, 10);
                setBrokerList(newList);
                page.total = newList.length;
                page.current = 1;
            }
        });
    }, [page]);

    // 获取交易商列表
    const getBrokerFun = (current: number, shName?: string) => {
        const item = (dataList || []).find((item: any) => item.id);
        const { openInfo } = item || {};

        getBrokerList({
            page: current,
            pageSize: page.pageSize,
            type: openInfo?.broker.type || '',
            name_abbreviation: shName,
        })
            .then((res: any) => {
                const { state, data } = res || {};
                if (state === 1) {
                    const { list = [], total } = data || {};
                    const newList = current === 1 ? list || [] : [...brokerList, ...(list || [])];
                    setBrokerList(
                        newList.map((item: any) => {
                            return { ...item, value: item?.id, label: item?.name_abbreviation };
                        }),
                    );
                    page.total = total;
                    page.current = current;
                }
            })
            .finally(() => {
                page.loading = false;
            });
    };

    useEffect(() => {
        getRecommendList();
    }, [getRecommendList, locale]);

    //
    const onPopupScroll = (event: any) => {
        const { target } = event;
        // 滚动 触底 看接口是否还有剩余的值没传过来
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (brokerList.length < page.total && !page.loading) {
                page.loading = true;
                // 每滚动一次，多加载20条数据
                page.current = page.current + 1;
                getBrokerFun(page.current, searchVal);
            }
        }
    };

    const onDropdownVisibleChange = (v: boolean) => {
        setSearchValue('');
        setOpen(v);
    };

    // 为空时
    const notFoundContent = (
        <div className={styles.nullData}>
            <NextImage src="/img/place/null_data.webp" width={150} height={120} alt="empty" />
            <p className={styles.nullTxt}>{intl.get('Broker.NoDealerMsg').d('暂无交易商～')}</p>
        </div>
    );

    return (
        <div ref={boxRef} className={styles.brokerBox}>
            <Form form={form} className={styles.form} initialValues={{ selVal: undefined }}>
                <Form.Item noStyle name="selVal">
                    <Select
                        placeholder={placeholder || intl.get('Broker.AddTrader').d('添加交易商')}
                        notFoundContent={notFoundContent}
                        className={c(styles.brokerSelect, open ? styles.brokerSelectOpen : null)}
                        onChange={onValueChange}
                        onPopupScroll={onPopupScroll}
                        onDropdownVisibleChange={onDropdownVisibleChange}
                        listHeight={480}
                        getPopupContainer={() => boxRef?.current}
                        dropdownStyle={{ padding: 0, borderRadius: 0 }}
                        suffixIcon={<span className={c(styles.suffix, 'iconfont', 'icon-arrow-down')} />}
                        dropdownRender={(menu) => (
                            <div className={styles.selectDropdown}>
                                <Input
                                    ref={inpRef}
                                    bordered={false}
                                    className={styles.searchInput}
                                    onInput={onSearchChange}
                                    placeholder={pleaseEnter(intl.get('Search.Tab3').d('请输入交易商'))}
                                    prefix={<span className={c(styles.prefix, 'iconfont', 'icon-sousuo')} />}
                                />
                                <Divider className={styles.divider} />
                                {menu}
                            </div>
                        )}
                    >
                        {brokerList.map((item: any) => {
                            const { value, label, logo, business_name } = item || {};
                            return (
                                <Option
                                    value={value}
                                    label={label}
                                    key={value}
                                    className={styles.selOption}
                                    title={label}
                                >
                                    <div className={c(styles.logo, 'logo')}>
                                        <NextImage
                                            src={logo}
                                            place="logo_a"
                                            alt={business_name}
                                            width={28}
                                            height={28}
                                        />
                                    </div>
                                    <span className={c(styles.text, 'text')}>{label}</span>
                                    <span className={styles.business__name}>
                                        {formatBrokerBusiness(business_name, locale)}
                                    </span>
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </div>
    );
};

export default BrokerSelect;
