import React, { useCallback, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Mousewheel, Navigation } from 'swiper';
import intl from 'react-intl-universal';

import { formatDomUnit } from '@/utils';
import useCustomModal from '@/reconstruction/components/public/CustomModal';
import { SwitchBtn, TagTitle } from '@/reconstruction/components/public';
import { useAppSelector } from '@/store/hook';

import styles from './index.module.less';
import RenderItem from './RenderItem';
import type { VisualUgcProps } from '../../../type';

SwiperCore.use([Autoplay, Mousewheel, Navigation]);

const RightStyle: React.FC<VisualUgcProps> = (props) => {
    const { imgPrefix } = useAppSelector((state: any) => {
        return state.config;
    });
    const { customStyle, serviceData } = props || {};
    const [customModal, CustomModal] = useCustomModal(); // 弹窗
    const [normalSwiper, setNormalSwiper] = useState<any>(null); // 轮播图
    const [activeIndex, setActiveIndex] = useState<number>(0); // 当前下标

    const { list: swiperList = [] } = serviceData;

    /** 取消关注的弹窗 */
    const onUnfollowModal = useCallback(
        ({
            callbackFn,
            title,
        }: {
            /** 回调函数 */
            callbackFn: () => void;
            /** 标题 */
            title: string;
        }) => {
            customModal.confirm({
                open: true,
                title,
                onOk: () => {
                    callbackFn();
                    customModal.close();
                },
            });
        },
        [customModal],
    );

    if (swiperList.length === 0) {
        return null;
    }

    /** 切换按钮 */
    const onSlideClick = (type: 'l' | 'r') => {
        switch (type) {
            case 'l':
                if (activeIndex !== 0) {
                    normalSwiper?.slideTo?.(activeIndex - 1);
                }
                break;
            case 'r':
                if (activeIndex !== swiperList.length - 1) {
                    normalSwiper?.slideTo?.(activeIndex + 1);
                }
                break;
        }
    };

    return (
        <>
            <div style={{ ...formatDomUnit(customStyle || {}) }}>
                <div className={styles.ugc} style={{ backgroundImage: `url('${imgPrefix}/ugc/visual/ugc-bg.webp')` }}>
                    <div className={styles.ugc__header}>
                        <TagTitle
                            iconType="ugc"
                            text={[
                                intl.get('Ugc.RightStyleTitle1').d('推荐'),
                                intl.get('Ugc.RightStyleTitle2').d('创作者'),
                            ]}
                        />
                        {swiperList.length > 1 && (
                            <SwitchBtn
                                noShadow
                                activeIndex={activeIndex}
                                max={swiperList.length - 1}
                                onClickBtn={onSlideClick}
                            />
                        )}
                    </div>
                    <Swiper
                        onSlideChange={(swiper) => {
                            setActiveIndex(swiper?.activeIndex);
                        }}
                        onSwiper={setNormalSwiper}
                        navigation={false}
                        slidesPerView="auto"
                        spaceBetween={16}
                        loop={false}
                        className={styles.ugc__swiper}
                    >
                        {swiperList.map((item, index) => {
                            return (
                                <SwiperSlide className={styles.list__item} key={index}>
                                    <RenderItem onUnfollowModal={onUnfollowModal} dataSource={item} />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
            {CustomModal}
        </>
    );
};

export default RightStyle;
