import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { Share } from '@/components';
import { formaTimerLetter, getTagsLen, getHtmlPlainText, sourceUrlHandler, currentLocaleUrl } from '@/utils';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    options: any[];
}

const FlashNewsList: React.FC<Props> = (props) => {
    const { options = [] } = props;

    const newOptions = useMemo(() => {
        return formaTimerLetter([...options]) || {};
    }, [options]);

    return (
        <div className={styles.flashNewsContain}>
            {Object.keys(newOptions).map((date: any) => {
                const dateTimer = Number(date);
                const yearDate = dayjs(dateTimer).format('YYYY.MM.DD');

                const newlist = newOptions[date] || [];

                return (
                    <div key={date} className={styles.flashNewsList}>
                        <div className={styles.flashNewsDate}>{yearDate}</div>
                        {[...newlist].map((item: any, i: number) => {
                            const {
                                pk,
                                code,
                                seo_url,
                                source_url,
                                title,
                                datetime,
                                source_id,
                                source_code,
                                introduction,
                                tag_info = [],
                                thumbnail_pics = [],
                            } = item || {};

                            const tags = getTagsLen(tag_info);
                            const href = `/article/${seo_url || code}`;
                            const timer = datetime ? dayjs(datetime).format('HH:mm') : '';
                            const cover = (thumbnail_pics || [])[0]?.url;
                            const introdu = getHtmlPlainText(introduction || '');
                            const sourceLink = sourceUrlHandler(source_code, source_id, source_url);
                            return (
                                <div key={pk} className={styles.flashNewsItem}>
                                    <div className={styles.flashNewsTimer}>{timer}</div>
                                    <div className={styles.flashNewsCon}>
                                        <a href={currentLocaleUrl(href)} target="_blank">
                                            <h2 className={styles.flashNewsTitle}>{title}</h2>
                                            {introdu ? (
                                                <div
                                                    className={styles.flashNewsIntrod}
                                                    dangerouslySetInnerHTML={{ __html: introdu }}
                                                />
                                            ) : null}
                                            {sourceLink ? (
                                                <div className={styles.sourceUrl}>
                                                    <a target="_blank" href={currentLocaleUrl(sourceLink)}>
                                                        {intl.get('PersonalCenter.OriginalLink').d('[原文链接]')}
                                                    </a>
                                                </div>
                                            ) : null}
                                        </a>

                                        <div className={styles.flashNewsMsg}>
                                            <div className={styles.flashNewsTag}>
                                                {tags.map((tag: any) => {
                                                    return (
                                                        <a
                                                            key={tag}
                                                            href={currentLocaleUrl(`/keyword?tags=${tag}`)}
                                                            target="_blank"
                                                        >
                                                            {tag}
                                                        </a>
                                                    );
                                                })}
                                            </div>
                                            <div
                                                className={styles.flashNewsIcon}
                                                onClick={(event: any) => {
                                                    event?.preventDefault();
                                                    event?.stopPropagation();
                                                    event?.nativeEvent.stopImmediatePropagation();
                                                }}
                                            >
                                                <Share
                                                    type="twitter"
                                                    size={20}
                                                    title={title}
                                                    url={href}
                                                    cover={cover}
                                                    style={{ marginRight: 20 }}
                                                />
                                                <Share
                                                    type="facebook"
                                                    size={20}
                                                    title={title}
                                                    url={href}
                                                    cover={cover}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default FlashNewsList;
