import React from 'react';
import { formatMts } from '@/utils/mts';
import classnames from 'classnames';
import styles from './index.module.less';
import { useAppSelector } from '@/store/hook';

interface Props {
    data: any;
    activeTab: number;
    titleKey?: string;
    titleMtsKey?: string;
    hasUnderline?: boolean;
}

const NavTabItem: React.FC<Props> = ({
    data = {},
    activeTab,
    titleKey = 'title',
    titleMtsKey = 'titleMts',
    hasUnderline,
}) => {
    const { showBarkly } = useAppSelector((state: any) => {
        return state.config;
    });
    const title = formatMts(data[titleMtsKey]) || data[titleKey];
    return (
        <span
            className={classnames(
                styles.seriesItem,
                { [styles.active]: activeTab === data.index, [styles.barkly_container]: showBarkly },
                activeTab === data.index && '_active-seriesItem',
                hasUnderline && styles.has_underline,
            )}
        >
            {title}
        </span>
    );
};

export default NavTabItem;
