import React from 'react';

import TransferCom from '@/components/public/TransferCom';
import { formatDomUnit } from '@/utils';

import PCDealersTool from './pc';
import MDealersTool from './m';

interface Props {
    customStyle?: any;
}

const DealersTool: React.FC<Props> = ({ customStyle }) => {
    return (
        <TransferCom
            pc={
                <div style={{ ...formatDomUnit(customStyle || {}) }}>
                    <PCDealersTool />
                </div>
            }
            m={<MDealersTool />}
        />
    );
};

export default DealersTool;
