import React, { useEffect, useMemo, useState } from 'react';
import c from 'classnames';
import { Skeleton } from 'antd';
import intl from 'react-intl-universal';

import brokerTool from '@/reconstruction/config/brokerTool';
import { NormalTitle } from '@/reconstruction/components/public';
import { currentLocaleUrl } from '@/utils';

import styles from './style.module.less';

const DealersTool: React.FC = () => {
    const currentLocale: string = intl.getInitOptions().currentLocale || 'en';
    const [loading, setLoading] = useState(true);
    const [toolIndex, setToolIndex] = useState(0);

    /** 当前工具配置 */
    const toolConfig = useMemo(() => {
        return brokerTool(currentLocale)[toolIndex] || {};
    }, [toolIndex, currentLocale]);

    /** 加载js */
    const loadScript = (src: string, content?: string) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            setLoading(false);
            (window as any)?.eval?.(content);
        };
        document.body?.insertBefore?.(script, document.body?.firstChild);
    };

    /** 加载工具代码 */
    useEffect(() => {
        if (!toolConfig.src) {
            return;
        }
        const src = toolConfig.src || ''; // src内容
        const content = toolConfig.mContent || ''; // srcipt内容
        loadScript(src, content);
    }, [toolConfig]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.tool_top}>
                <NormalTitle title={intl.get('Broker.TradeToolBannerText').d('交易工具')} />
                <div
                    className={styles.wrapper__more}
                    onClick={() => window.open(currentLocaleUrl('/broker/tradeTool'))}
                >
                    <span className={styles.text__more}>{intl.get('Common.SeeMore').d('查看更多')}</span>
                    <span className={c(styles.arrow__more, 'iconfont', 'icon-arrow-right')} />
                </div>
            </div>
            <div className={styles.tool_wrapper}>
                <div className={styles.tabs}>
                    {brokerTool(currentLocale).map((item, index) => (
                        <span
                            onClick={() => {
                                if (index === toolIndex) return;
                                setLoading(true);
                                setToolIndex(index);
                            }}
                            className={index === toolIndex ? styles.active : ''}
                            key={item.title}
                        >
                            {intl.get(item.mts).d(item.title)}
                        </span>
                    ))}
                </div>
                <div className={styles.tool}>
                    <div style={{ display: loading ? 'none' : 'block' }} id={toolConfig.mId} />
                    {loading && <Skeleton />}
                </div>
            </div>
        </div>
    );
};

export default DealersTool;
