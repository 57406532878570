import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MColumnGroup from './m';
import PcColumnGroup from './pc';
import type { ColumnGroupProps } from './type';

const ColumnGroup: React.FC<ColumnGroupProps> = (props) => {
    return (
        <TransferCom
            m={<MColumnGroup {...props} />}
            ipad={<PcColumnGroup {...props} />}
            pc={<PcColumnGroup {...props} />}
        />
    );
};

export default ColumnGroup;
