import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import c from 'classnames';
import intl from 'react-intl-universal';
import SwiperCore, { Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { formatDomUnit, currentLocaleUrl, PAGE_CENTER_NEW } from '@/utils';
import { NormalTitle, SwitchBtn } from '@/reconstruction/components/public';

import ArticlesList from './ArticlesList';
import styles from './index.module.less';
import SwiperSlideItem from './SwiperSlideItem';
import type { TopicAttentionProps } from '../../../type';
import { isNumber } from 'lodash';

SwiperCore.use([Controller]);

const PcTopic: React.FC<TopicAttentionProps> = ({ customStyle, serviceData }) => {
    const [swiperRef, setSwiperRef] = useState<any>(null);
    // 当前显示页
    const [topicList, setTopicList] = useState(serviceData?.topic?.list || []);
    const [ugcList, setUgcList] = useState(serviceData?.ugc?.list || []);
    const [activeIndex, setActiveIndex] = useState<number>(0); // 当前选中
    const [showType, setShowType] = useState<'topic' | 'ugc'>(topicList.length > 0 ? 'topic' : 'ugc'); // 展示类别

    // 当前展示元素的宽度
    const currentDomW = useMemo(() => {
        switch (showType) {
            case 'topic':
                return 198;
            case 'ugc':
                return 158;
        }
    }, [showType]);

    const [topIconLeft, setTopIconLeft] = useState<number>(currentDomW / 2 - 13 - 16);

    useEffect(() => {
        setTopicList(serviceData?.topic?.list || []);
        setUgcList(serviceData?.ugc?.list || []);
    }, [serviceData]);

    // 当前展示列表
    const currentList = useMemo(() => {
        switch (showType) {
            case 'topic':
                return topicList;
            case 'ugc':
                return ugcList;
        }
    }, [showType, topicList, ugcList]);

    /** 切换开关 */
    const switchShowType = (type: 'topic' | 'ugc') => {
        setShowType(type);
        setActiveIndex(0);
    };

    /** 稿件列表 */
    const articleList = useMemo(
        () => (currentList[activeIndex]?.article_list || []).slice(0, 3),
        [activeIndex, currentList],
    );

    // 点击切换
    const onClickBtn = (type: 'l' | 'r') => {
        switch (type) {
            case 'l':
                if (activeIndex > 0) {
                    setActiveIndex(activeIndex - 1);
                }
                break;
            case 'r':
                if (activeIndex < currentList.length - 1) {
                    setActiveIndex(activeIndex + 1);
                }
                break;
            default:
                break;
        }
    };

    /** 获取轮播图信息 */
    const getSwiperInfo = useCallback(
        (index?: number) => {
            const init = currentDomW / 2 - 13 - 16;

            const childElement = swiperRef?.slides?.[isNumber(index) ? index : activeIndex];

            if (!childElement)
                return {
                    newLeft: init,
                    grandparentWidth: PAGE_CENTER_NEW,
                };

            // 获取祖父元素
            const grandparentElement = childElement.parentNode.parentNode;

            // 获取祖父元素的边缘位置信息
            const grandparentRect = grandparentElement.getBoundingClientRect();

            // 获取元素的位置信息
            const parentRect = childElement.getBoundingClientRect();

            // 计算元素与祖父元素左边缘的距离，去除padding值和图标一半宽度，增加选中内容的一半高度
            const diff = parentRect.left - grandparentRect.left;

            // 获取祖父元素的宽度
            const grandparentWidth = childElement.parentNode.parentNode.getBoundingClientRect().width;
            return {
                newLeft: diff + init,
                grandparentWidth,
            };
        },
        [activeIndex, swiperRef, currentDomW],
    );

    /** 计算当前箭头图标所定位的位置 */
    useEffect(() => {
        const { newLeft, grandparentWidth } = getSwiperInfo() || {};

        if (newLeft > grandparentWidth) {
            swiperRef?.slideNext();
            return;
        }
        if (newLeft < 0) {
            swiperRef?.slidePrev();
            return;
        }

        setTopIconLeft(newLeft);
    }, [activeIndex, swiperRef]);

    const onSlideChange = (swiper: SwiperCore) => {
        const { newLeft, grandparentWidth } = getSwiperInfo() || {};

        setTopIconLeft(newLeft);
        if (newLeft > grandparentWidth || newLeft < 0) {
            setActiveIndex(swiper.activeIndex);
            return;
        }
    };

    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div className={styles.header_left}>
                        <NormalTitle title={intl.get('Home.MyFocus').d('我的关注')} />
                        <div className={styles.tab_wrapper}>
                            {topicList.length > 0 && (
                                <div
                                    onClick={() => switchShowType('topic')}
                                    className={c(styles.tab_item, { [styles.active]: showType === 'topic' })}
                                >
                                    {intl.get('Common.Topic').d('专栏')}
                                </div>
                            )}
                            {ugcList.length > 0 && (
                                <div
                                    onClick={() => switchShowType('ugc')}
                                    className={c(styles.tab_item, { [styles.active]: showType === 'ugc' })}
                                >
                                    {intl.get('Common.Ugc').d('创作者')}
                                </div>
                            )}
                        </div>
                        {currentList.length > 1 && (
                            <SwitchBtn
                                activeIndex={activeIndex}
                                max={(currentList.length as number) - 1}
                                onClickBtn={onClickBtn}
                            />
                        )}
                    </div>
                    <a href={currentLocaleUrl('/center?type=focus')} className={styles.wrapper__more} target="_blank">
                        <span className={styles.text__more}>{intl.get('Common.SeeMore').d('查看更多')}</span>
                        <span className={c(styles.arrow__more, 'iconfont', 'icon-arrow-right')} />
                    </a>
                </div>

                <Swiper
                    className={styles.swiper_list}
                    onSwiper={setSwiperRef}
                    slidesPerView="auto"
                    spaceBetween={24}
                    // onSlideChange={onSlideChange}
                    onSlideChangeTransitionEnd={onSlideChange}
                >
                    {currentList.map((item: any, i: number) => {
                        return (
                            <SwiperSlide
                                className={c(
                                    styles[showType === 'ugc' ? 'ugc_swiperSlideBox' : 'topic_swiperSlideBox'],
                                    { [styles.active]: activeIndex === i },
                                )}
                                onClick={() => setActiveIndex(i)}
                                key={item.code || item._id || item.name}
                            >
                                <SwiperSlideItem dataSource={item} showType={showType} />
                            </SwiperSlide>
                        );
                    })}
                    {currentList.length === 10 && (
                        <SwiperSlide
                            className={c(
                                styles[showType === 'ugc' ? 'ugc_swiperSlideBox' : 'topic_swiperSlideBox'],
                                styles.more_swiperSlideBox,
                            )}
                        >
                            <a target="_blank" href={currentLocaleUrl('/center?type=focus')} className={styles.go_more}>
                                <span className={c('iconfont', 'icon-Union', styles.more_icon)} />
                                <span className={styles.more_text}>{intl.get('Common.SeeMore').d('查看更多')}</span>
                            </a>
                        </SwiperSlide>
                    )}
                </Swiper>
                <ArticlesList list={articleList} topIconLeft={topIconLeft} />
            </div>
        </div>
    );
};

export default PcTopic;
