import React from 'react';
import intl from 'react-intl-universal';
import dayjs from 'dayjs';

import { ArticleVideoInfoType } from '@/type/article';
import { currentLocaleUrl } from '@/utils';

import styles from './index.module.less';

const VideoPreview = ({ dataSource }: { dataSource: ArticleVideoInfoType }) => {
    const { video_info, title, datetime, seo_url, code } = dataSource || {};

    return (
        <div className={styles.preview}>
            <div className={styles.video__title}>
                <h2>{intl.get('Home.Video').d('视频')}</h2>
            </div>
            <div className={styles.video__content}>
                <video src={video_info?.url || ''} />
            </div>

            <div className={styles.video__footer}>
                <a
                    target="_blank"
                    href={currentLocaleUrl(`/article/${seo_url || code}`)}
                    className={styles.video__footer__title}
                >
                    {title || ''}
                </a>
                <div className={styles.video__footer__time}>{dayjs(datetime || '').format('YYYY-MM-DD HH:mm')}</div>
            </div>
        </div>
    );
};

export default VideoPreview;
