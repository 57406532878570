import React, { useRef } from 'react';

import { useAppSelector } from '@/store/hook';
import BrokerContrast from '@/reconstruction/components/public/BrokerContrast';

import styles from './index.module.less';
import { FilterList, List } from './components';
import { AllBrokerContext } from './context';
import useFilterItem from '../hook/useFilterItem';
import c from 'classnames';

interface Props {
    businessList: any[];
    /** 服务端数据 */
    serviceData: {
        list: any[];
        hasMore: boolean;
    };
    /** 首屏筛选数据 */
    filterList: any[];
    sortList: any[];
    globalData: any;
}

const PCBrokerAll: React.FC<Props> = (props) => {
    const { businessList, serviceData, globalData } = props;
    const { imageRootHost } = globalData || {};
    // 对比
    const contrastRef = useRef<any>(null);

    const { topHeaderHeight: topHeight, activeNavKeys = [] } = useAppSelector((state: any) => state.config);

    /** 获取数据 */
    const contentValues = useFilterItem({
        initFilterList: props.filterList,
        initSortList: props.sortList,
        businessList,
    });

    // 前往对比
    const handleContrast = (record: any) => {
        const { id, logo, type, name_abbreviation, score, broker_seo } = record;
        const { url: seo_url } = broker_seo || {};
        const newItem = { id, logo, type, name_abbreviation, score, seo_url };
        contrastRef?.current?.addItem(newItem);
    };

    return (
        <AllBrokerContext.Provider
            value={{
                ...contentValues,
                handleContrast,
                businessList,
                serviceData,
            }}
        >
            <div className={c(styles.all__wrapper, { [styles.margin_top]: (activeNavKeys || []).length < 2 })}>
                {/* 筛选列表 */}
                <div style={{ top: topHeight }} className={styles.left__wrapper}>
                    <FilterList />
                </div>
                {/* 列表 */}
                <div className={styles.right__wrapper}>
                    <List />
                </div>
            </div>

            <BrokerContrast imgPrefix={imageRootHost?.imgPrefix} onRef={contrastRef} />
        </AllBrokerContext.Provider>
    );
};

export default PCBrokerAll;
