import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PCUgc from './pc';
import MUgc from './m';
import type { VisualUgcProps } from './type';

const Ugc: React.FC<VisualUgcProps> = (props) => {
    return <TransferCom pc={<PCUgc {...props} />} m={<MUgc {...props} />} ipad={null} />;
};

export default Ugc;
