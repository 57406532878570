import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import MTopic from './m';
import PcTopic from './pc';
import { type TopicHomeProps } from '../../type';

const Topic: React.FC<TopicHomeProps> = (props: TopicHomeProps) => {
    return <TransferCom m={<MTopic {...props} />} ipad={<PcTopic {...props} />} pc={<PcTopic {...props} />} />;
};

export default Topic;
