import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.less';
import { NextImage } from '@/components';
import { Skeleton } from 'antd';
import c from 'classnames';
import intl from 'react-intl-universal';

const Broker: React.FC = () => {
    const wrapRef = useRef<any>(null);
    const [twJsLoading, setTwJsLoading] = useState<boolean>(true);
    const [twJsError, setTwJsError] = useState<boolean>(false);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
        script.async = true;
        script.innerHTML = `{
            "colorTheme": "light",
            "dateRange": "12M",
            "showChart": true,
            "locale": "en",
            "width": "100%",
            "height": "100%",
            "largeChartUrl": "",
            "isTransparent": false,
            "showSymbolLogo": true,
            "showFloatingTooltip": false,
            "plotLineColorGrowing": "rgba(10, 205, 185, 1)",
            "plotLineColorFalling": "rgba(204, 0, 0, 1)",
            "gridLineColor": "rgba(240, 243, 250, 0)",
            "scaleFontColor": "rgba(66, 66, 66, 1)",
            "belowLineFillColorGrowing": "rgba(10, 205, 185, 0.12)",
            "belowLineFillColorFalling": "rgba(224, 102, 102, 0.12)",
            "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
            "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
            "symbolActiveColor": "rgba(10, 205, 185, 0.12)",
            "tabs": [
                {
                    "title": "Forex",
                    "symbols": [
                        {
                        "s": "FX:EURUSD",
                        "d": "EUR/USD"
                        },
                        {
                        "s": "FX:GBPUSD",
                        "d": "GBP/USD"
                        },
                        {
                        "s": "FX:USDJPY",
                        "d": "USD/JPY"
                        },
                        {
                        "s": "FX:USDCHF",
                        "d": "USD/CHF"
                        },
                        {
                        "s": "FX:AUDUSD",
                        "d": "AUD/USD"
                        },
                        {
                        "s": "FX:USDCAD",
                        "d": "USD/CAD"
                        },
                        {
                        "s": "OANDA:NZDUSD",
                        "d": "NZD/USD"
                        }
                    ],
                    "originalTitle": "Forex"
                }
            ]
            }`;
        script.onload = () => {
            setTwJsLoading(false);
        };
        script.onerror = () => {
            setTwJsLoading(false);
            setTwJsError(true);
        };
        wrapRef.current?.insertBefore?.(script, wrapRef.current?.childNodes?.[0]);
    }, []);

    return (
        <div className={styles.ugcRec} ref={wrapRef}>
            {twJsLoading && (
                <div className={styles.skeleton}>
                    <Skeleton />
                    <Skeleton style={{ marginTop: 24 }} />
                    <Skeleton style={{ marginTop: 24 }} />
                </div>
            )}
            {twJsError && (
                <div className={c('load-empty', styles.empty)}>
                    <NextImage width={380} height={305} src="/img/place/null_data.webp" alt="empty" />
                    <span>{intl.get('Common.NoData').d('暂无数据')}</span>
                </div>
            )}
            <div className="tradingview-widget-container">
                <div className="tradingview-widget-container__widget" />
            </div>
        </div>
    );
};

export default Broker;
