import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Skeleton } from 'antd';
import { NextImage } from '@/components';
import { getAnalystDetail } from '@/services/analyst';
import { currentLocaleUrl, resizeObserverFun } from '@/utils';
import dayjs from 'dayjs';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    recordId?: string | number;
    detail: any;
    locale: string;
}

const { Input: SkeletonInput } = Skeleton;

const AnalyzeDetails: React.FC<Props> = ({ locale, recordId, detail }) => {
    // public
    const [loading, setLoading] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<any>(detail || {});

    const [nowLocale, setNowLocale] = useState<string>(locale);

    useEffect(() => {
        setDataSource(detail);
    }, [detail]);

    /**
     * 详情高度
     */
    const imgRef = useRef<any>(null);
    const [imgWidth, setImgWidth] = useState<number>(490);
    useEffect(() => {
        resizeObserverFun(imgRef.current, setImgWidth);
    }, []);

    const skeletonList = useMemo(() => {
        return Array.from({ length: 4 }, (_v, i) => {
            return { key: `more${i}` };
        });
    }, []);

    // 分析详情
    const refreshDetail = useCallback(() => {
        if (loading) {
            return;
        }
        if (recordId && recordId !== dataSource?._id) {
            setLoading(true);
            getAnalystDetail({ _id: recordId })
                .then((res: any) => {
                    const { state, data } = res || {};
                    if (state === 1) {
                        setDataSource({ ...data });
                        setNowLocale(locale);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [dataSource?._id, loading, recordId, locale]);

    useEffect(() => {
        refreshDetail();
    }, [refreshDetail, locale, dataSource]);

    // 时间处理
    const formatTimer = (v: any) => {
        return v ? dayjs(v).format('YYYY-MM-DD HH:mm') : '-';
    };

    const { title, image, paragraph, ts } = dataSource || {};

    const formatParagraph = (str: string) => {
        // const regex = new RegExp('^' + 'TRADING CENTRAL');

        const strArr = (str || '').split('\n');
        const newList: string[] = [];
        let nowStr = '';
        strArr.forEach((item) => {
            if (!item.includes('TRADING CENTRAL')) {
                if (item.length != 0) {
                    nowStr = item;
                    newList.push(item);
                } else {
                    if (nowStr.length != 0) {
                        nowStr = item;
                        newList.push(item);
                    }
                }
            }
        });

        return newList.join('\n');
    };

    return (
        <div className={styles.container}>
            {!dataSource?._id && <SkeletonInput active block style={{ marginBottom: 24 }} />}
            <Skeleton title active loading={!dataSource?._id}>
                <div className={styles.tip}>{intl.get('Analyze.WeekTip').d('每周图表还显示了看跌行情。')}</div>
                <h1>{title}</h1>
                <p className={styles.tiemr}>{formatTimer(ts * 1000)}</p>
            </Skeleton>

            <div className={styles.content}>
                <a
                    className={styles.img}
                    href={currentLocaleUrl(image || '')}
                    ref={imgRef}
                    target="_blank"
                    data-type="file"
                    style={{ height: imgWidth * 0.6775 }}
                >
                    <NextImage src={image} layout="fill" objectFit="fill" alt={title} />
                </a>
                <Skeleton className={styles.skeleton} active loading={!dataSource?._id}>
                    <div className={styles.paragraph}>{formatParagraph(paragraph)}</div>
                </Skeleton>
            </div>

            {skeletonList.map((item) => (
                <Skeleton key={item.key} className={styles.skeleton} active loading={!dataSource?._id} />
            ))}
        </div>
    );
};

export default AnalyzeDetails;
