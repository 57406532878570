import React, { useContext, useEffect, useRef } from 'react';

import styles from './index.module.less';
import { AllBrokerContext } from '../../../../context';
import { BrokerItemC, InfiniteScroll } from '@/reconstruction/components/public';
import intl from 'react-intl-universal';

function BrokerListMain() {
    const currentLocale = intl.getInitOptions().currentLocale;
    const { serviceData, extraParams, handleContrast } = useContext(AllBrokerContext);
    const InfiniteScrolleRef = useRef<any>(null);
    const isInit = useRef<boolean>(true);

    /** 重新请求 */
    useEffect(() => {
        if (isInit.current) {
            isInit.current = false;
            return;
        }
        InfiniteScrolleRef.current?.resetList();
    }, [extraParams]);

    return (
        <div className={styles.main}>
            <InfiniteScroll
                api="/japi/broker/switch/screen/list"
                method="post"
                onRef={InfiniteScrolleRef}
                serviceData={serviceData}
                extraParams={extraParams}
                initialLoad={false}
                noDataHeight={790}
                renderList={(list) => {
                    return (
                        <>
                            {list.map((item, index) => {
                                return (
                                    <div key={item.id} className={styles.list__item}>
                                        <BrokerItemC
                                            locale={currentLocale}
                                            showComparedBtn={true}
                                            showSlogan={true}
                                            onCompared={handleContrast}
                                            key={index}
                                            record={item}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    );
                }}
            />
        </div>
    );
}

export default BrokerListMain;
