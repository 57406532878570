import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { FirstSwiper, SecondSwiper } from '@/components/public/CustomSwiper';
import { formatDomUnit, resizeObserverFun, PAGE_CENTER_NORMAL } from '@/utils';
import c from 'classnames';
import styles from './index.module.less';

interface SwiperProps {
    elementId: string | number;
    styleType: number | string;
    isSwitch: boolean;
    interval?: number;
    customStyle: any;
    visualSwiperList: any[];
    visualSwiperList0: any[];
    visualSwiperList1: any[];
    visualSwiperList2: any[];
    visualSwiperList3: any[];
    visualSwiperList4: any[];
}

const Swiper: React.FC<SwiperProps> = ({
    elementId,
    styleType,
    isSwitch,
    customStyle,
    interval = 3,
    visualSwiperList = [],
    visualSwiperList0 = [],
    visualSwiperList1 = [],
    visualSwiperList2 = [],
    visualSwiperList3 = [],
    visualSwiperList4 = [],
}) => {
    const domRef = useRef<any>(null);
    const [domWidth, setDomWidth] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(domRef.current, setDomWidth);
    }, []);

    const swiperType = useMemo(() => {
        return domWidth === PAGE_CENTER_NORMAL;
    }, [domWidth]);

    // 获取跳转地址
    const getUrl = useCallback((row: any) => {
        const { type, web = {}, article, block, blocks, navPageID } = row || {};
        let url = '';
        switch (type) {
            case 'article':
                const { code, seo_url } = article || {};
                url = `/article/${seo_url || code}`;
                break;
            case 'web':
                url = web.url;
                break;
            case 'block':
                const { blockId: blockId } = block || {};
                url = `/block/${blockId}`;
                break;
            case 'blocks':
                const { blockIds } = blocks || {};
                url = `/block/${blockIds?.map((item: any) => item.block_id).join(',')}`;
                break;
            case 'page':
                url = `/page/${navPageID}`;
                break;
            default:
                break;
        }
        return url;
    }, []);

    const formList = useCallback(
        (l: any[]) => {
            return l.map((item, index) => {
                return { ...item, openUrl: getUrl(item.openInfo), key: index };
            });
        },
        [getUrl],
    );

    const swiperData = useMemo(() => {
        if (swiperType) {
            if (styleType === '2') {
                const fList = formList(visualSwiperList0);
                const sList = [
                    {
                        key: 1,
                        list: formList(visualSwiperList1),
                    },
                    {
                        key: 2,
                        list: formList(visualSwiperList2),
                    },
                    {
                        key: 3,
                        list: formList(visualSwiperList3),
                    },
                    {
                        key: 4,
                        list: formList(visualSwiperList4),
                    },
                ];

                return [fList, sList];
            } else {
                return formList(visualSwiperList);
            }
        } else {
            if (styleType === '2') {
                const sList = [
                    ...visualSwiperList0,
                    ...visualSwiperList1,
                    ...visualSwiperList2,
                    ...visualSwiperList3,
                    ...visualSwiperList4,
                ];

                return formList(sList);
            } else {
                return formList(visualSwiperList);
            }
        }
    }, [
        formList,
        styleType,
        swiperType,
        visualSwiperList,
        visualSwiperList0,
        visualSwiperList1,
        visualSwiperList2,
        visualSwiperList3,
        visualSwiperList4,
    ]);

    const { width, widthUnit, height, heightUnit, borderRadius, borderRadiusUnit, ...restStyle } = customStyle || {};

    return (
        <div
            style={{ ...formatDomUnit(restStyle || {}) }}
            className={c(styles.swiperContainBox, domWidth > 0 ? styles.swiperShow : null)}
            ref={domRef}
        >
            {(styleType === '1' || !swiperType) && (
                <FirstSwiper
                    elementId={elementId}
                    isSwitch={isSwitch || !swiperType}
                    interval={interval}
                    customStyle={customStyle}
                    imgWidth={domWidth < 200 ? 200 : domWidth}
                    swiperList={swiperData}
                />
            )}
            {styleType === '2' && swiperType && (
                <SecondSwiper
                    elementId={elementId}
                    interval={interval}
                    imgWidth={domWidth < 200 ? 200 : domWidth}
                    swiperData={swiperData}
                />
            )}
        </div>
    );
};

export default Swiper;
