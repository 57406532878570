import React from 'react';
import { formatDomUnit } from '@/utils';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import c from 'classnames';
import { currentLocaleUrl } from '@/utils';
import { NormalTitle } from '@/reconstruction/components/public';
import RenderItem from './RenderItem';
import type { VisualUgcProps } from '../../../type';

const PCUgc: React.FC<VisualUgcProps> = ({ customStyle, serviceData }) => {
    // 无数据直接返回空
    if ((serviceData?.list || []).length === 0) {
        return null;
    }

    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            <div className={styles.ugc}>
                <div className={styles.ugc_top}>
                    <NormalTitle title={intl.get('Home.RecommendedCreator').d('推荐创作者')} />
                    <a href={currentLocaleUrl('/ugc')} className={styles.wrapper__more} target="_blank">
                        <span className={styles.text__more}>{intl.get('Common.SeeMore').d('查看更多')}</span>
                        <span className={c(styles.arrow__more, 'iconfont', 'icon-arrow-right')} />
                    </a>
                </div>
                <div className={styles.list}>
                    {serviceData?.list
                        ?.slice(0, 3)
                        ?.map((item: any, i: number) => <RenderItem dataSource={item} key={`item._id_${i}`} />)}
                </div>
            </div>
        </div>
    );
};

export default PCUgc;
