import React, { useState, useEffect, useMemo } from 'react';
import { InfiniteScroll } from '@/components';
import FlashNewsList from './list';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getApiContain } from '@/services/api';
import 'swiper/swiper-bundle.css';
import c from 'classnames';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    options: any[];
    elementId: string;
}

SwiperCore.use([Navigation]);

const FlashNews: React.FC<Props> = ({
    options,
    elementId,
    // customStyle
}) => {
    const [_, setList] = useState<any[]>([]);
    const [activeKey, setActiveKey] = useState<number>(0);
    const [activeItem, setActiveItem] = useState<any>({});

    const arr = useMemo(() => {
        return options.map((item, index) => {
            return { ...item, index };
        });
    }, [options]);

    const onChangeTabs = (item: any) => {
        setActiveKey(item.index);
        setActiveItem(item.openInfo || {});
    };

    const getNavData = (item: any) => {
        const { type, blockApi } = item || {};
        if (type && blockApi) {
            switch (type) {
                case 'blocks':
                case 'flash':
                    getApiContain(`${blockApi}`).then((res: any) => {
                        const { data, state } = res || {};
                        const { list: resList = [] } = data || {};
                        if (state === 1) {
                            setList(resList.filter((e: any) => e.pk));
                        }
                    });
                    break;
                default:
                    setList([]);
                    break;
            }
        }
    };

    useEffect(() => {
        if (arr.length !== 0) {
            const obj = arr.find((e: any) => e.index === activeKey) || {};
            setActiveItem(obj.openInfo || {});
        }
    }, [activeKey, arr]);

    useEffect(() => {
        getNavData(activeItem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeItem.type, activeItem.blockId, activeItem.blockApi]);

    return (
        <>
            <div className={styles.flashNewsTit}>
                <span>7*24</span>
                <span>{intl.get('Search.Tab2').d('快讯')}</span>
            </div>
            <div className={styles.flashNewsBox}>
                <Swiper
                    observer
                    resizeObserver
                    grabCursor
                    slidesPerView="auto"
                    spaceBetween={48}
                    className={styles.flashNews}
                    // slideToClickedSlide
                    navigation={{
                        nextEl: `.swiper-${elementId} .swiper-button-next`,
                        prevEl: `.swiper-${elementId} .swiper-button-prev`,
                    }}
                >
                    {arr.map((item: any) => (
                        <SwiperSlide
                            onClick={() => {
                                onChangeTabs(item);
                            }}
                            className={c(styles.flashNewsItem, activeKey === item.index ? styles.active : null)}
                            key={item.index}
                        >
                            <span className={styles.swiperItemTitle}>{item.title}</span>
                        </SwiperSlide>
                    ))}

                    <div className={`swiper-${elementId}`}>
                        <div className={c(styles.swiperBtnNext, 'swiper-button-next')}>
                            <div className={styles.swiperBackNext} />
                        </div>
                        <div className={c(styles.swiperBtnPrev, 'swiper-button-prev')}>
                            <div className={styles.swiperBackPrev} />
                        </div>
                    </div>
                </Swiper>
            </div>
            {['flash', 'blocks'].includes(activeItem.type) ? (
                <InfiniteScroll
                    api={activeItem?.blockApi || ''}
                    renderList={(list: any[]) => <FlashNewsList options={list} />}
                />
            ) : null}
        </>
    );
};

export default FlashNews;
