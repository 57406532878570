import React from 'react';

import { formatDomUnit } from '@/utils';

import styles from './index.module.less';
import { ColBoxProp } from '../type';

const MColBox: React.FC<ColBoxProp> = (parmas) => {
    const { render, customStyle } = parmas;

    return (
        <div className={styles.colBox} style={{ ...formatDomUnit(customStyle || {}) }}>
            {render?.()}
        </div>
    );
};

export default MColBox;
