import React from 'react';

import { NormalStyle, RecommendStyle, RightStyle } from './components';

import type { VisualUgcProps } from '../type';

const Ugc: React.FC<VisualUgcProps> = (props) => {
    const { styleType } = props;

    return (
        <>
            {styleType === '1' && <NormalStyle {...props} />}
            {styleType === '2' && <RightStyle {...props} />}
            {styleType === '3' && <RecommendStyle {...props} />}
        </>
    );
};

export default Ugc;
