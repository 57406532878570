import React, { useEffect } from 'react';
import c from 'classnames';
import { cloneDeep } from 'lodash';
import { App } from 'antd';
import intl from 'react-intl-universal';

import { NextImage } from '@/components';
import useCustomModal from '@/reconstruction/components/public/CustomModal';
import { topicFocusAddPost, topicFocusDelete } from '@/services/focus';
import { currentLocaleUrl } from '@/utils';
import type { TopicDataType } from '@/type/topic';

import styles from './index.module.less';

function RenderItem({ dataSource }: { dataSource: TopicDataType }) {
    const { article_list = [] } = dataSource || {};
    const { message } = App.useApp();
    const [columnData, setColumnData] = React.useState<TopicDataType>(dataSource);

    const [customModal, CustomModal] = useCustomModal(); // 弹窗

    useEffect(() => {
        setColumnData(dataSource);
    }, [dataSource]);

    if (!article_list.length) {
        return null;
    }

    const { is_focus, _id, seo_info, cover, title, introduction } = columnData || {};

    /** 取消/关注回调 */
    const handleFocus = () => {
        const fun = is_focus === 1 ? topicFocusDelete : topicFocusAddPost;
        fun(_id).then((res: any) => {
            if (res?.state === 1) {
                message.success(
                    is_focus === 1
                        ? intl.get('Topic.UnfollowSuc').d('取消订阅成功！')
                        : intl.get('Topic.FollowedSuc').d('订阅成功！'),
                );
                const data = cloneDeep(columnData);
                data.is_focus = is_focus === 1 ? 0 : 1;

                setColumnData(data);
            }
        });
    };

    /**
     * 关注/取消关注
     */
    const onFocusColumn = (event: any) => {
        event?.preventDefault();
        if (_id) {
            if (is_focus === 1) {
                customModal.confirm({
                    open: true,
                    title: intl.get('Topic.WantToUnfollow').d(`确定要取消订阅吗？`),
                    onOk: () => {
                        handleFocus();
                        customModal.close();
                    },
                });
            } else {
                handleFocus();
            }
        }
    };

    return (
        <div className={styles.list_item} key={_id}>
            <a href={currentLocaleUrl(`/topic/${seo_info?.seo_url || _id}`)} target="_blank">
                <div className={styles.swiperSlideBox}>
                    <div className={styles.img_con}>
                        <NextImage src={cover?.url} place="normal_b" layout="fill" alt={title} />
                    </div>
                    <div className={styles.text_content}>
                        {title ? <div className={c(styles.swiperSlideTitleText)}>{title || ''}</div> : null}
                        <div className={styles.introduction}>{introduction}</div>
                        <div className={c(styles.focus, { [styles.not]: is_focus !== 1 })} onClick={onFocusColumn}>
                            {is_focus === 1 ? (
                                intl.get('Topic.AlreadyFollowed').d('已订阅')
                            ) : (
                                <React.Fragment>
                                    <NextImage
                                        src={'/h5/attention.png'}
                                        alt="attention"
                                        needPrefix
                                        width={14}
                                        height={14}
                                    />
                                    <span>{intl.get('Topic.Followed').d('订阅')}</span>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </a>
            {CustomModal}
        </div>
    );
}

export default RenderItem;
