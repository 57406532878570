import React from 'react';
import { Billboard, Contrast, Recommend } from '../components';
import type { HomeBrokerPros } from '../types';
import styles from './index.module.less';

const HomeBroker: React.FC<HomeBrokerPros> = (props) => {
    const { serviceData, moreInfo } = props || {};
    const { billboard = [], billboardDetails = [], contrast = [], recommend = [] } = serviceData || {};

    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapper_left}>
                <Billboard moreInfo={moreInfo} billboard={billboard} billboardDetails={billboardDetails} />
            </div>
            <div className={styles.wrapper_right}>
                <Contrast contrast={contrast} />
                <Recommend recommend={recommend} />
            </div>
        </div>
    );
};

export default HomeBroker;
