import React from 'react';

import Normal from './components/Normal';
import Calendar from './components/Calendar';
import All from './components/All';

const Activity: React.FC<any> = (props) => {
    const { styleType } = props;
    return (
        <>
            {styleType === '1' ? <Normal {...props} /> : null}
            {styleType === '2' ? <Calendar {...props} /> : null}
            {styleType === '3' ? <All {...props} /> : null}
        </>
    );
};

export default Activity;
