import React from 'react';
import { NextImage } from '@/components';
import SwiperCore, { Pagination, Autoplay, Controller, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatMts } from '@/utils/mts';
import 'swiper/swiper-bundle.css';
import c from 'classnames';
import styles from './index.module.less';
import { currentLocaleUrl } from '@/utils';
import type { SwiperProps } from '../types';

SwiperCore.use([Pagination, Autoplay, Controller, Navigation]);

const MSwiper: React.FC<SwiperProps> = ({ styleType, interval = 5, swiperList = [] }) => {
    return (
        <div className={styles.swiperContain}>
            <Swiper
                loop={swiperList.length > 1}
                observer
                resizeObserver
                autoplay={{
                    delay: (interval || 5) * 1000,
                    disableOnInteraction: false,
                }}
                grabCursor
                className={styles.swiperCon}
                pagination={
                    swiperList.length < 2
                        ? false
                        : {
                              clickable: true,
                              bulletClass: styles.swiperBullet,
                              bulletActiveClass: styles.swiperBulletActive,
                          }
                }
            >
                {swiperList.map((item: any, i: number) => {
                    const swiperItem = (
                        <div className={styles.swiperSlideBox}>
                            <NextImage src={item?.url} layout="fill" alt={item?.title} priority={i === 0} />
                            {item.title ? (
                                <div className={styles.swiperSlideTitle} title={item?.title || ''}>
                                    <div
                                        className={c(
                                            styles.swiperSlideTitleText,
                                            swiperList.length < 2 ? styles.swiperSlideTitleOne : null,
                                        )}
                                    >
                                        {formatMts(item.titleMts) || item.title}
                                    </div>
                                </div>
                            ) : null}

                            {styleType === '4' && (
                                <div className={styles.playBtn}>
                                    <NextImage src="/img/video/play.webp" width={90} height={90} alt="play" />
                                </div>
                            )}
                        </div>
                    );
                    return (
                        <SwiperSlide className={c(styles.swiperSlide, styles.typeOne)} key={item.key}>
                            {item.openUrl ? (
                                <a href={currentLocaleUrl(item.openUrl)} target="_blank">
                                    {swiperItem}
                                </a>
                            ) : (
                                swiperItem
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};

export default MSwiper;
