/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import styles from './index.module.less';
import type { ActivityProps } from '../../../type';
import { formatDomUnit, currentLocaleUrl, getVisualSkipPage } from '@/utils';
import intl from 'react-intl-universal';
import { NormalTitle } from '@/reconstruction/components/public';
import c from 'classnames';
import ActivityList from '../components/ActivityList';

const PcMActivity: React.FC<ActivityProps> = ({ customStyle, serviceData, moreUrl }) => {
    return (
        <div className={styles.active_con}>
            <div className={styles.top_box}>
                <NormalTitle title={intl.get('Common.Activity').d('活动')} />
                <a href={currentLocaleUrl(getVisualSkipPage(moreUrl))} className={styles.wrapper__more} target="_blank">
                    <span className={styles.text__more}>{intl.get('Common.SeeMore').d('查看更多')}</span>
                    <span className={c(styles.arrow__more, 'iconfont', 'icon-arrow-right')} />
                </a>
            </div>
            <div>
                <ActivityList list={(serviceData?.list || []) as any[]}></ActivityList>
            </div>
        </div>
    );
};

export default PcMActivity;
