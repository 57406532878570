import React, { useState, useEffect } from 'react';
import c from 'classnames';

import * as PreviewElement from '@/components/visual';
import { AuthMask } from '@/components';

import styles from './index.module.less';

const renderItem = (params: any) => {
    const { id, key, component, parentKey, children = [], props, serviceData = {} } = params;

    const Element: any = PreviewElement;
    const layoutElements = {
        component: Element[component] || null,
    };

    switch (id) {
        case 'SM_Visual_Page':
            return (
                <div key={key} className={styles.visualPage}>
                    {renderItem({ ...params })}
                </div>
            );
        case 'SM_Visual_L':
            return (
                <div key={key} className={styles[key]}>
                    {[...(children || [])].map((e: any) => {
                        return renderItem({ ...e });
                    })}
                </div>
            );
        case 'SM_Visual_H':
            return (
                <div key={key} className={styles.hanDom}>
                    {[...(children || [])].map((e: any) => {
                        return renderItem({ ...e });
                    })}
                </div>
            );

        default:
            let containerType = '';
            // 判断容器类型
            switch (parentKey) {
                case 'RowBox_left': // 左右布局
                case 'RowBox_right': // 左右布局
                    containerType = 'box';
            }
            return (
                <div key={key} className={styles.smVisualDom}>
                    <layoutElements.component {...props} containerType={containerType} serviceData={serviceData} />
                </div>
            );
    }
};

interface Props {
    pageData: any;
}

const Normal: React.FC<Props> = ({ pageData }) => {
    const { auth, children = [] } = pageData || {};
    const [isLogin, setIsLogin] = useState<boolean>(true);

    useEffect(() => {
        setIsLogin(!!localStorage?.getItem('Authorization'));
    }, []);

    return (
        <div className={styles.container}>
            <div className={c(styles.conBox, !(auth || isLogin) ? styles.conBoxNoAuth : null)}>
                <div className={styles.page}>
                    {pageData.key
                        ? [...children].map((e: any) => {
                              return renderItem({ ...e });
                          })
                        : null}
                </div>
            </div>
            <AuthMask auth={auth} />
        </div>
    );
};

export default Normal;
