import React, { useCallback, useEffect, useRef, useState } from 'react';
import { message } from 'antd';
import { cloneDeep } from 'lodash';
import c from 'classnames';
import intl from 'react-intl-universal';

import { NextImage } from '@/components';
import type { UgcListType } from '@/type/ugc';
import { focusAddPost, focusDelete } from '@/services/focus';
import { currentLocaleUrl } from '@/utils';

import styles from './index.module.less';
import { NoMore } from '@/reconstruction/components/public';

interface RenderItemProps {
    dataSource: UgcListType;
    onUnfollowModal: ({ callbackFn, title }: { callbackFn: () => void; title: string }) => void;
}

const RenderItem = (props: RenderItemProps) => {
    const { onUnfollowModal } = props || {};
    const isInit = useRef<boolean>(true);
    const [dataSource, setDataSource] = useState<UgcListType>(props.dataSource || {});

    useEffect(() => {
        if (isInit.current) {
            isInit.current = false;
            return;
        }
        setDataSource(props.dataSource || {});
    }, [props.dataSource]);

    const focusReqHandler = useCallback(() => {
        const { id, is_focus } = dataSource || {};
        if (id) {
            const fun = is_focus === 1 ? focusDelete : focusAddPost;
            fun(id).then((res: any) => {
                if (res?.state === 1) {
                    message.success(
                        is_focus === 1
                            ? intl.get('PersonalCenter.UnfollowSuc').d('取消关注成功！')
                            : intl.get('Article.FollowedSuc').d('关注成功！'),
                    );

                    const newData = cloneDeep(dataSource);
                    newData.is_focus = is_focus === 1 ? 0 : 1;
                    setDataSource(newData);
                }
            });
        }
    }, [dataSource]);

    /**
     * 关注/取消关注
     */
    const onFocusUgc = useCallback(() => {
        const { is_focus, name } = dataSource || {};
        if (is_focus === 1) {
            onUnfollowModal({
                callbackFn: focusReqHandler,
                title: intl.get('Ugc.UnfollowModelTitle', { name }).d(`确定要取消对${name}的关注吗？`),
            });

            return;
        }
        focusReqHandler();
    }, [dataSource]);
    const { avatar_url, name, introduction, id, is_focus } = dataSource;

    return (
        <a href={currentLocaleUrl(`/ugc/${id}`)} target="_blank" className={styles.item__wrapper}>
            <div className={styles.header}>
                <div className={styles.avatar}>
                    <NextImage src={avatar_url} layout="fill" objectFit="cover" place="ugc" />
                </div>
                <div className={styles.info}>
                    <div className={styles.name__wrapper}>
                        <div className={styles.name}>{name}</div>
                        <div
                            className={styles.focus}
                            onClick={(e) => {
                                e.preventDefault();
                                onFocusUgc();
                            }}
                        >
                            <NextImage
                                src={`/icon/${is_focus ? 'focus' : 'unfollow'}.webp`}
                                layout="fill"
                                objectFit="contain"
                                needPrefix
                            />
                        </div>
                    </div>
                </div>
            </div>
            {introduction ? <div className={styles.intro}>{introduction}</div> : <NoMore top={24} />}
        </a>
    );
};

export default RenderItem;
