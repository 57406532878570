import React, { useEffect, useState } from 'react'
import { formatDomUnit } from '@/utils'
import { getVideosNum } from '@/services/japi'
import { UnderLineTitle, VideoList, InfiniteScroll } from '@/components'

interface VideoProps {
  styleType: string | number
  sourceType?: string
  videoList?: any[]
  blockApi?: string
  groupTitle?: string
  showCount?: string | number
  customStyle?: any
  serviceData?: {
    list: any[]
    hasMore: boolean
  }
}

const Video: React.FC<VideoProps> = ({
  sourceType,
  videoList,
  blockApi,
  groupTitle,
  customStyle,
  serviceData
}) => {

  const [list, setList] = useState<any[]>([])


  useEffect(() => {
    if (sourceType === '1') {
      const arr = videoList || []
      const ids = arr.map((e: any) => { return e.blockId; }).join(',')
      getVideosNum({ ids }).then((res: any) => {
        const { state, data = [] } = res || {}
        if (state === 1) {
          const newList: any = []
          arr.forEach((item: any) => {
            const obj = (data || []).find((e: any) => e.app_id === item.blockId)
            if (obj) {
              newList.push({ ...item, total: obj.count, code: obj.code })
            }
          })
          setList([...newList])
        }
      })
    }
  }, [videoList, sourceType])

  return (
    <div style={{ ...formatDomUnit(customStyle || {}) }}>
      {
        groupTitle ? <UnderLineTitle title={groupTitle} /> : null
      }
      <div>
        {sourceType === '1' ? <VideoList list={list} /> : null}
        {
          sourceType === '2' ? <InfiniteScroll
            api={blockApi || ''}
            initialLoad={false}
            serviceData={serviceData}
            renderList={(list: any[]) => <VideoList list={list} />}
          /> : null
        }
      </div>
    </div >
  )
}

export default Video
