import React from 'react';
import { formatDomUnit } from '@/utils';
import AnalystIndex from '@/reconstruction/components/pages/analyst/AnalystIndex';
import styles from './index.module.less';

interface Props {
    customStyle?: any;
    serviceData: {
        navId: string;
        menus: any[];
        detail: any;
        locale: string;
        serviceData: {
            list: any[];
            hasMore: boolean;
        };
    };
}

const Analyst: React.FC<Props> = (props) => {
    const { customStyle, serviceData } = props;
    const { navId, menus, serviceData: data, detail, locale } = serviceData;
    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }} className={styles.liveBox}>
            <AnalystIndex elementId={navId} options={menus} serviceData={data} detail={detail} locale={locale} />
        </div>
    );
};

export default Analyst;
