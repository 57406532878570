import { createContext } from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { CalendarItem } from './type';
import { ActivityType } from '@/type/activity';

interface CalendarContextProps {
    currentMon: Dayjs;
    currentData: Dayjs;
    setCurrentData: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>;
    /** 切换月份 */
    switchMon: (type: 'up' | 'down') => void;
    /** 列表数据 */
    list: CalendarItem[];
    /** 活动列表 */
    activityList: ActivityType[];
}

export const CalendarContext = createContext<CalendarContextProps>({
    /* 当前月份 */
    currentMon: dayjs(),
    /* 当前时间 */
    currentData: dayjs(),
    /* 设置当前时间 */
    setCurrentData: () => {},
    /** 列表数据 */
    list: [],
    /** 活动列表 */
    activityList: [],
    /** 切换月份 */
    switchMon: () => {},
});
