import React, { useState, useEffect, useRef, useCallback } from 'react';
import { formatDomUnit, formatMts } from '@/utils';
import NavTabItem from '@/components/public/Tabs/NavTabItem';
import { Tabs, ArticleWaterfall, ActivityList, InfiniteScroll, VideoList } from '@/components';
import { Normal } from '@/components/visual';
import FlashNewsList from '../FlashNews/list';
import styles from './index.module.less';

interface Props {
    elementId: string;
    navs: any[];
    customStyle: any;
    activeTab?: number;
    hasArrow?: boolean;
    groupTitle?: string;
    groupTitleMts?: {
        zh_cns?: string;
        en_us?: string;
    };
    serviceData?: {
        list: any[];
        hasMore: boolean;
    };
}

const Nav: React.FC<Props> = ({
    elementId,
    navs,
    customStyle,
    activeTab = 0,
    hasArrow,
    groupTitle,
    groupTitleMts,
    serviceData,
}) => {
    // console.log(111111, 'http://192.168.10.16:3001/', acticveNav)

    const [params, setParams] = useState<any>({ api: '', extraParams: {} }); // 滚动列表参数

    const [activeItem, setActiveItem] = useState<any>(navs[activeTab].openInfo); // 当前tab 数据

    const [defaultActiveTab] = useState<number>(activeTab);

    const InfiniteScrolleRef = useRef<any>(); // 滚动列表 ref

    // 设置参数
    const setNavParams = useCallback((item: any) => {
        const { type, blockApi, activitys } = item || {};
        const { pageSize }: any = process.env.NEXT_PUBLIC_SETTING;
        if (type) {
            switch (type) {
                case 'block':
                case 'blocks':
                case 'videos':
                    if (blockApi) {
                        const apiUrl = blockApi.split('?')[0];
                        const apiParamsArr = blockApi.split('?')[1].split('&');
                        const apiParams: any = { pageSize };
                        apiParamsArr.forEach((item: string) => {
                            const key = item.split('=')[0];
                            const value = item.split('=')[1];
                            apiParams[key] = value;
                        });
                        setParams({ api: apiUrl, extraParams: apiParams });
                    }
                    break;
                case 'activitys':
                    setParams({
                        api: '/japi/activity/list/channel',
                        extraParams: { pageSize, activityChannel: activitys.id },
                    });
                    break;
                case 'page':
                    break;
                default:
                    console.log('未找到该类型：', type);
            }
        }
    }, []);

    useEffect(() => {
        const openInfo = navs[activeTab].openInfo;
        setActiveItem(openInfo);
        setNavParams(openInfo);
    }, [activeItem, activeTab, navs, setNavParams]);

    const ENUM_COMPONENTS: any = {
        quick: FlashNewsList,
        block: ArticleWaterfall,
        blocks: ArticleWaterfall,
        activitys: ActivityList,
        videos: VideoList,
    };

    const ListComponents: any = ENUM_COMPONENTS[activeItem?.blocks?.isQuick ? 'quick' : activeItem.type];

    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }} className={styles.tabsConBox}>
            {hasArrow ? (
                <h2>
                    <span>{formatMts(groupTitleMts) || groupTitle}</span>
                    {/* <Link href='/broker/all' ><a target='_blank' /></Link> */}
                </h2>
            ) : null}
            <Tabs
                isLink
                isFixed
                elementId={elementId}
                list={navs}
                defaultActiveTab={defaultActiveTab}
                itemRender={(data, activeTab) => <NavTabItem data={data} activeTab={activeTab} />}
            />

            <div className={styles.tabsCon}>
                {
                    /**合集、活动、视频*/
                    ['block', 'blocks', 'activitys', 'videos'].includes(activeItem.type) && (
                        <InfiniteScroll
                            onRef={InfiniteScrolleRef}
                            initialLoad={false}
                            serviceData={serviceData}
                            api={params.api}
                            extraParams={params.extraParams}
                            renderList={(list) => <ListComponents list={list} options={list} />}
                        />
                    )
                }

                {
                    /**页面*/
                    ['page'].includes(activeItem.type) && (
                        <>
                            <Normal pageData={serviceData} />
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default Nav;
