import React from 'react';

// import TransferCom from '@/components/public/TransferCom';

// import MBrokerList from './pc';
import PcBrokerList from './pc';
import type { SearchHeaderProps } from './type';

const SearchHeader: React.FC<SearchHeaderProps> = (props) => {
    return (
        // <TransferCom
        //     m={<MBrokerList {...props} />}
        //     ipad={<PcBrokerList {...props} />}
        //     pc={<PcBrokerList {...props} />}
        // />
        <PcBrokerList {...props} />
    );
};

export default SearchHeader;
