import BrokerAll from '@/reconstruction/pages/broker/all/index';
import BrokeList from '@/reconstruction/pages/broker/list';
import BrokeContrast from '@/reconstruction/pages/broker/contrast';

/** 系统创建列表 */
export const systemPageList = [
    'Pc_Broker_All', // 	全部交易商
    'Pc_Broker_Billboard', // 交易商榜单
    'Pc_Barterer_Comparison', // 交易商对比
];

/**
 * 系统创建页面配置
 *
 * 【注意事项】
 * 1、server的返回，必须携带navId，不然会导致找不到正确的Client
 * 2、server的外层不需要包裹withGlobalData，数据已经传入进入了
 */
export const systemPageConfig: any = {
    Pc_Broker_All: {
        server: BrokerAll.getServerSideProps,
        Client: BrokerAll.Page,
    },
    Pc_Broker_Billboard: {
        server: BrokeList.getServerSideProps,
        Client: BrokeList.Page,
    },
    Pc_Barterer_Comparison: {
        server: BrokeContrast.getServerSideProps,
        Client: BrokeContrast.Page,
    },
};
