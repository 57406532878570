import React, { useCallback } from 'react';
import ColumnItem from '../../ColumnItem';
import c from 'classnames';
import intl from 'react-intl-universal';
import type { ColumnGroupProps } from '../type';
import styles from './index.module.less';

const ColumnGroup: React.FC<ColumnGroupProps> = ({ dataSource = [], brokerList = [] }) => {
    const columnRnder = useCallback(
        (l: any = []) => {
            return (l || []).map((item: any, i: number) => {
                const {
                    id: partId,
                    title,
                    title_mts,
                    parent,
                    parent_mts,
                    key,
                    children = [],
                    columns = [],
                } = item || {};

                return (
                    <div key={key} id={key}>
                        {children.length !== 0 ? (
                            columnRnder(children)
                        ) : (
                            <div className={c(styles.partItem, styles[`partItem${i + 1}`])}>
                                <div className={styles.partName}>
                                    <span>
                                        {parent ? `${intl.get(parent_mts).d(parent)} - ` : ''}
                                        {intl.get(title_mts).d(title)}
                                    </span>
                                </div>
                                <div className={styles.partTable}>
                                    {columns.map((item: any, index: number) => {
                                        const { dict_name, dict_type } = item || {};
                                        // dict_name_mts
                                        // 由于是接口配配置的字段不改，1.14.0这一版只做替换标签文案
                                        return (
                                            <ColumnItem
                                                last={index === columns.length - 1}
                                                key={dict_type}
                                                prop={dict_type}
                                                label={dict_name}
                                                list={brokerList}
                                                partId={partId}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                );
            });
        },
        [brokerList],
    );

    return columnRnder(dataSource);
};

export default ColumnGroup;
