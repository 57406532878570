/** 状态列表 */
export const statusList = [
    {
        id: '1',
        value: '1',
        label: '未开始',
        mts: 'Activity.FilterStatus1',
    },
    {
        id: '2',
        value: '2',
        label: '进行中',
        mts: 'Activity.FilterStatus2',
    },
    {
        id: '3',
        value: '3',
        label: '已结束',
        mts: 'Activity.FilterStatus3',
    },
];

/** 状态列表 */
export const cityList = [
    {
        value: '新加坡',
        label: '新加坡',
        mts: 'City.Singapore',
    },
    {
        value: '印度尼西亚',
        label: '印度尼西亚',
        mts: 'City.Indonesia',
    },
    {
        value: '迪拜',
        label: '迪拜',
        mts: 'City.Dubai',
    },
    {
        value: '泰国',
        label: '泰国',
        mts: 'City.Thailand',
    },
];
