import React, { useMemo } from 'react';
import { NextImage } from '@/components';
import { currentLocaleUrl, getVisualSkipPage } from '@/utils';
import { getMultiInputLang } from '@/constants/mts';
import type { TopBannerProps } from '../type';
import styles from './index.module.less';

const TopBanner: React.FC<TopBannerProps> = ({ locale, bannerData }) => {
    // 顶部：banner
    const bannerRender = useMemo(() => {
        const { title, titleMts = {}, imgLink } = bannerData || {};
        const lo: any = locale || 'zh-CN';
        const key = getMultiInputLang[lo as 'zh-CN' | 'en'];
        const mtsTit = titleMts[key] || title;

        return (
            <>
                <NextImage src={imgLink} layout="fill" alt="broker_banner" />
                {mtsTit && (
                    <div className={styles.banenr_mask}>
                        <span className={styles.banenr_tip}>{mtsTit}</span>
                    </div>
                )}
            </>
        );
    }, [locale, bannerData]);

    return getVisualSkipPage(bannerData?.openInfo) ? (
        <a
            className={styles.banner_con}
            href={currentLocaleUrl(getVisualSkipPage(bannerData?.openInfo))}
            target="_blank"
        >
            {bannerRender}
        </a>
    ) : (
        <div className={styles.bannerCon}>{bannerRender}</div>
    );
};

export default TopBanner;
