/** 默认筛选项 */
export const defaultFilterValues: { type: 'slider' | 'checkbox'; value: string }[] = [
    {
        type: 'checkbox',
        value: 'supervise_state', // 监管状态
    },
    {
        type: 'checkbox',
        value: 'broker_datum.supervisory_licence.regulator', // 监管机构
    },
    {
        type: 'checkbox',
        value: 'broker_type.trading_software', // 交易软件
    },
    {
        type: 'slider',
        value: 'broker_industry.compensatory_balance', // 最低存款
    },
    {
        type: 'slider',
        value: 'broker_industry.maximum_lever', // 最大杠杆
    },
    {
        type: 'slider',
        value: 'broker_industry.minimum_position', // 最小头寸
    },
    {
        type: 'slider',
        value: 'broker_industry.warehouse_blowout', // 爆仓比例
    },
    {
        type: 'checkbox',
        value: 'broker_type.account_type', // 账户类型
    },
    {
        type: 'checkbox',
        value: 'broker_industry.promotion_word', // 点差类型
    },
    {
        type: 'checkbox',
        value: 'broker_type.order_type', // 订单执行类型
    },
    // ----------- 证券 Start -----------
    {
        type: 'checkbox',
        value: 'broker_other_info.data_upload.supervisory_licence.regulator', // 监管机构
    },
    {
        type: 'checkbox',
        value: 'broker_other_info.option.instrument_exchange', // 交易工具
    },
    {
        type: 'checkbox',
        value: 'broker_other_info.option.investment_market', // 投资市场
    },
    {
        type: 'checkbox',
        value: 'broker_other_info.option.investment_type', // 投资种类
    },
    {
        type: 'checkbox',
        value: 'broker_other_info.option.open_account_method', // 开户门槛及方法
    },
    {
        type: 'checkbox',
        value: 'broker_other_info.option.account_type', // 账户类型
    },
    {
        type: 'checkbox',
        value: 'broker_other_info.option.hk_order_type', // 港股订单类型
    },
    {
        type: 'checkbox',
        value: 'broker_other_info.option.us_order_type', // 美股订单类型
    },
    // ----------- 证券 End -----------
];

/** 响应式额外增加的筛选项 */
export const mExtraFilterValues: { type: 'slider' | 'checkbox' | 'country'; value: string }[] = [
    {
        type: 'checkbox',
        value: 'year', // 成立时间
    },

    {
        type: 'country',
        value: 'country', // 国家地区
    },
];

/** 其他筛选项 */
export const otherFilterValues = [
    'broker_type.state', // 锁仓
    'broker_type.ea', // ea交易
    'broker_type.scalp', // 超短线
    'broker_type.micro_account', // 微型账户
    'broker_type.mock_account', // 模拟账户
    // ----------- 证券
    'broker_other_info.option.swap', // 换汇
    'broker_other_info.option.leverage', // 杠杆
    'broker_other_info.option.warrant', // 新股认购
    'broker_other_info.option.mock_account', // 模拟账户
];
