import TransferCom from '@/components/public/TransferCom';
import MBroker from './m';
import PCBroker from './pc';
import type { BrokerProps } from './type';

const Broker: React.FC<BrokerProps> = (props) => {
    return <TransferCom m={<MBroker {...props} />} ipad={<PCBroker {...props} />} pc={<PCBroker {...props} />} />;
};

export default Broker;
