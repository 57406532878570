import React from 'react';
import { InfiniteScroll } from '@/components';
import { formatDomUnit } from '@/utils';
import LiveList from './LiveList';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    customStyle?: any;
    serviceData?: {
        list: any[];
        hasMore: boolean;
    };
}

const Live: React.FC<Props> = ({ customStyle, serviceData }) => {
    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }} className={styles.liveBox}>
            <div className={styles.listTitle}>{intl.get('Live.AllLive').d('全部直播')}</div>
            <InfiniteScroll
                api="/live/list"
                initialLoad={false}
                serviceData={serviceData}
                renderList={(list) => {
                    return <LiveList list={list} />;
                }}
            />
        </div>
    );
};

export default Live;
