import React from 'react';
import { NextImage } from '@/components';
import SwiperCore, { Pagination, Autoplay, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatMts } from '@/utils/mts';
import 'swiper/swiper-bundle.css';
import c from 'classnames';
import styles from './index.module.less';
import { currentLocaleUrl, formatDomUnit } from '@/utils';
import type { SwiperProps } from '../../types';
import intl from 'react-intl-universal';

SwiperCore.use([Pagination, Autoplay, Controller]);

const FourthSwiper: React.FC<SwiperProps> = ({ interval = 5, customStyle, swiperList = [] }) => {
    const { width, widthUnit, height, heightUnit, ...restProps } = customStyle || {};

    return (
        <div className={styles.swiperCenter} style={formatDomUnit(restProps)}>
            <div
                className={styles.swiperContain}
                style={{
                    width: `${width}${widthUnit}`,
                    height: `${height}${heightUnit}`,
                }}
            >
                <Swiper
                    loop={swiperList.length > 1}
                    observer
                    resizeObserver
                    observeParents
                    observeSlideChildren
                    autoplay={{
                        delay: (interval || 5) * 1000,
                        disableOnInteraction: false,
                    }}
                    grabCursor
                    className={styles.swiperCon}
                    pagination={
                        swiperList.length < 2
                            ? false
                            : {
                                  clickable: true,
                                  bulletClass: styles.swiperBullet,
                                  bulletActiveClass: styles.swiperBulletActive,
                              }
                    }
                >
                    {swiperList.map((item: any, i: number) => {
                        const swiperItem = (
                            <div className={styles.swiperSlideBox} title={item?.title || ''}>
                                <div className={styles.img_url_box}>
                                    <NextImage src={item?.url} layout="fill" alt={item?.title} priority={i === 0} />
                                    <div className={styles.left_mask}></div>
                                    <div className={styles.right_mask}></div>
                                </div>

                                <div className={styles.text_con}>
                                    <div className={styles.text_pre_con}>
                                        {item.title ? (
                                            <div className={styles.swiperSlideTitle}>
                                                <div
                                                    className={c(
                                                        styles.swiperSlideTitleText,
                                                        swiperList.length < 2 ? styles.swiperSlideTitleOne : null,
                                                    )}
                                                >
                                                    {formatMts(item.titleMts) || item.title}
                                                </div>
                                            </div>
                                        ) : null}

                                        <div className={styles.playBtn}>
                                            <i
                                                className="iconfont icon-bofang"
                                                style={{
                                                    fontSize: '14px',
                                                    marginRight: '10px',
                                                }}
                                            />
                                            <span className={styles.playBtn_text}>
                                                {intl.get('Article.PlayVideo').d('播放视频')}
                                            </span>
                                            {/* <NextImage
                                                src="/icon/swiper-video-play.webp"
                                                needPrefix
                                                width={90}
                                                height={90}
                                                alt="play"
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                        return (
                            <SwiperSlide className={c(styles.swiperSlide, styles.typeOne)} key={item.key}>
                                <div className={styles.swiper_slide_item}>
                                    {item.openUrl ? (
                                        <a href={currentLocaleUrl(item.openUrl)} target="_blank">
                                            {swiperItem}
                                        </a>
                                    ) : (
                                        swiperItem
                                    )}
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    );
};

export default FourthSwiper;
