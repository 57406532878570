import React, { useState, useEffect, useRef } from 'react';
import { Affix } from 'antd';
import AnalystList from '../../AnalystList';
import { useAppSelector } from '@/store/hook';
import { resizeObserverFun } from '@/utils';
import { Tabs } from '@/reconstruction/components/public';
import NavLineItem from '../components/NavLineItem';
import InfiniteScrollForAnalyst from '../components/InfiniteScrollForAnalyst';
import AnalystDetail from '../../AnalystDetail/pc';
import SearchItems from '../../SearchItems';
import type { FormDataProps } from '../types';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    options: any[];
    elementId: string;
    serviceData: {
        list: any[];
        hasMore: boolean;
    };
    detail: any;
    locale: string;
}

const AnalystIndex: React.FC<Props> = ({ locale, elementId, options = [], serviceData, detail }) => {
    const analystConRef = useRef<any>(null);
    const normalTab = { title: intl.get('Analyze.AllCapital').d('全部'), value: 'All', index: 0 };

    const { topHeaderHeight: topHeight } = useAppSelector((state: any) => state.config);

    const InfiniteScrolleRef = useRef<any>(null);
    const [activeItem, setActiveItem] = useState<any>(normalTab);
    const [isInit, setIsInit] = useState<boolean>(true);
    const [searchVal, setSearchVal] = useState<FormDataProps>({});

    /** 选择项 */
    const [recordItem, setRecordItem] = useState<any>(detail || {});

    const [listFilters, setListFilters] = useState<FormDataProps | undefined>(searchVal);

    /**
     * tabs高度
     */
    const tabsRef = useRef<any>(null);
    const [tabsHeight, setTabsHeight] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(tabsRef.current, setTabsHeight, 'clientHeight');
    }, []);

    /**
     * 详情高度
     */
    const detailRef = useRef<any>(null);
    const [detailHeight, setDetailHeight] = useState<number>(0);
    useEffect(() => {
        resizeObserverFun(detailRef.current, setDetailHeight, 'clientHeight');
    }, []);

    /**
     * tabs 改变
     * @param _key
     * @param data
     */
    const onTabChange = (_key: number, data: any) => {
        setActiveItem(data);
    };

    /**
     * 筛选条件发生改变、重新请求接口
     */
    useEffect(() => {
        const { value: symbolType } = activeItem;
        if (symbolType && symbolType !== 'All') {
            setIsInit(false);
            setListFilters({ ...searchVal, symbol_type: [symbolType] });
        } else {
            setIsInit(false);
            setListFilters(searchVal);
        }
    }, [searchVal, activeItem]);

    useEffect(() => {
        if (!isInit) {
            InfiniteScrolleRef.current?.resetList();
        }
    }, [locale, isInit, listFilters]);

    return (
        <div className={styles.analyst}>
            <Affix offsetTop={topHeight}>
                <div className={styles.tabsBox} ref={tabsRef}>
                    <Tabs
                        wrapperClass={styles.analystTabs}
                        elementId={elementId}
                        list={[normalTab, ...options]}
                        defaultActiveTab={0}
                        onTabChange={onTabChange}
                        itemRender={(data) => <NavLineItem data={data} activeTab={activeItem.index} />}
                    />

                    <SearchItems onChange={setSearchVal} />
                </div>
            </Affix>

            {/** 筛选 */}

            <div className={styles.analystCon}>
                {/** 列表 */}
                <div className={styles.analystList} style={{ height: detailHeight }} ref={analystConRef}>
                    <InfiniteScrollForAnalyst
                        method="post"
                        onRef={InfiniteScrolleRef}
                        initialLoad={false}
                        useWindow={false}
                        serviceData={!isInit ? undefined : serviceData}
                        api="/japi/analyst/list"
                        parentRef={analystConRef}
                        extraParams={{ pageSize: 20, ...listFilters }}
                        renderList={(list, loader) => (
                            <AnalystList list={list} defaultId={detail?._id} onSelect={setRecordItem} loader={loader} />
                        )}
                    />
                </div>
                {/** 详情 */}
                <div className={styles.analystDetail} ref={detailRef}>
                    <AnalystDetail recordId={recordItem?._id} detail={detail} locale={locale} />
                </div>
            </div>
            <div className={styles.botTip}>
                {intl
                    .get('Common.Disclaimer')
                    .d(
                        '免责声明：本文观点来自原作者，不代表Hawk Insight的观点和立场。文章内容仅供参考、交流、学习，不构成投资建议。如涉及版权问题，请联系我们删除。',
                    )}
            </div>
        </div>
    );
};

export default AnalystIndex;
