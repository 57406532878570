import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { App } from 'antd';
import { NextImage } from '@/components';
import c from 'classnames';
import { NormalTitle, PcBrokerSelect } from '@/reconstruction/components/public';
import intl from 'react-intl-universal';
import { nanoid } from 'nanoid';
import { cloneDeep } from 'lodash';
import { currentLocaleUrl } from '@/utils';
import { getBusinessList } from '@/services/broker';
import { formatMts } from '@/utils/mts';
import type { ContrastPros } from '../types';
import styles from './index.module.less';

const Contrast: React.FC<ContrastPros> = ({ serviceData, hasArrow, groupTitle, groupTitleMts }) => {
    const { contrast = [] } = serviceData || {};

    const { message } = App.useApp();
    const currentLocale = intl.getInitOptions().currentLocale;

    const parList = [useRef<any>(null), useRef<any>(null), useRef<any>(null)];
    const refList = [useRef<any>(null), useRef<any>(null), useRef<any>(null)];

    const parentMapRef = useRef<any>(parList);
    const onRef = useRef<any>(refList);

    // 业务列表
    const [businessList, setBusinessList] = useState<any>([]);

    // 对比的交易商
    const [contrastList, setContrastList] = useState<any[]>([]);

    /**
     * 初始化获取交易商类型
     */
    useEffect(() => {
        getBusinessList().then((res: any) => {
            const { state, data = [] } = res || {};
            if (state === 1) {
                setBusinessList(data || []);
            }
        });
    }, [currentLocale]);

    // 对比
    useEffect(() => {
        const newList: any[] = [];
        const brokerArr = Array.from({ length: 3 }, (_v, i) => i);
        const { type } = contrast.find((item: any) => item?.type) || {};
        brokerArr.forEach((_item: any, i: number) => {
            if (contrast[i]) {
                newList.push(contrast[i]);
            } else {
                newList.push({ id: nanoid(), edit: true, type });
            }
        });

        setContrastList(newList);
    }, [contrast]);

    // 判断是否可以对比
    const canContrast = useMemo(() => {
        return contrastList.find((item) => item?.id && !item?.edit);
    }, [contrastList]);

    // 前往对比
    const onToContrast = useCallback(() => {
        if (!canContrast) return;
        const ids = contrastList.map((item: any) => {
            if (item?.edit) {
                return '';
            } else {
                return item?.id;
            }
        });
        const idstr = ids.join('.');
        window.open(currentLocaleUrl(`/page/Pc_Barterer_Comparison?ids=${encodeURIComponent(idstr)}`));
    }, [canContrast, contrastList]);

    // 全部清除
    const onClearAll = () => {
        const cloneList = cloneDeep(contrastList || []);
        setContrastList(cloneList.map(() => ({ id: nanoid(), edit: true })));
    };

    // ------------------- 更换交易商
    // 点击更换onShowChange
    const onShowChange = (e: any, index: number) => {
        e?.preventDefault();

        const cloneList = cloneDeep(contrastList);
        const { type } = cloneList.find((item: any) => item?.type && !item.edit) || {};

        cloneList.splice(index, 1, { id: nanoid(), edit: true, type });
        const hasItem = cloneList.find((item: any) => item?.type && !item.edit);
        if (hasItem) {
            setContrastList(cloneList);
        } else {
            const newList = cloneList.map((item: any) => ({ ...item, type: undefined }));
            setContrastList(newList);
        }

        // setTimeout(() => {
        //     const refItem = onRef?.current[index];
        //     refItem?.current?.onOpen?.(true);
        // });
    };

    // 添加
    const onAddItem = (record: any, call?: any, index?: number) => {
        const cloneList = cloneDeep(contrastList);
        const hasItem = cloneList.find((item: any) => item.id === record.id);
        if (hasItem) {
            message.warning(intl.get('Broker.BrokerLWarnMsg').d('该交易商已在对比列表'));
        } else {
            cloneList.splice(index || 0, 1, { ...record, edit: false });
            const newList = cloneList.map((item: any) => ({ ...item, type: record?.type || item?.type }));
            setContrastList(newList);
        }
        call?.();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.contrast_top}>
                {hasArrow && <NormalTitle title={formatMts(groupTitleMts) || groupTitle || ''} />}
            </div>

            <ul className={styles.contrast_list}>
                {(contrastList || []).map((item: any, index: number) => {
                    const { id, logo, name_abbreviation, score, seo_url, edit } = item;

                    return (
                        <li key={id} className={c({ [styles.sel_broker]: edit })}>
                            <a
                                href={currentLocaleUrl(`/broker/${seo_url || id}`)}
                                target="_blank"
                                className={c({ [styles.hide]: edit })}
                            >
                                <div className={styles.logo}>
                                    <NextImage
                                        src={logo}
                                        alt={name_abbreviation}
                                        place="logo_a_new"
                                        width={68}
                                        height={68}
                                    />
                                </div>
                                <div className={styles.info}>
                                    <p className={styles.name}>
                                        <span>{name_abbreviation}</span>

                                        <span className={styles.change} onClick={(e: any) => onShowChange(e, index)}>
                                            <span>{intl.get('Broker.Replace').d('更换')}</span>
                                            <span> {intl.get('Broker.ChangeBroker').d('更换交易商')}</span>
                                        </span>
                                    </p>
                                    <p className={styles.score}>
                                        <span>
                                            <strong>{Number(score || 0).toFixed(1)}</strong>
                                        </span>
                                        <span>{intl.get('Broker.Score').d('评分')}</span>
                                    </p>
                                </div>
                            </a>

                            <div
                                ref={parentMapRef.current[index]}
                                className={c(styles.sel_box, { [styles.hide]: !edit })}
                            >
                                <PcBrokerSelect
                                    size="large"
                                    alignCenter={true}
                                    activeIndex={index}
                                    activeType={item?.type}
                                    businessList={businessList}
                                    onRef={onRef?.current[index]}
                                    parentRef={parentMapRef?.current[index]}
                                    onSelect={onAddItem}
                                />
                            </div>
                        </li>
                    );
                })}
            </ul>

            <div className={styles.to_contrast}>
                <span className={c({ [styles.btn_disabled]: !canContrast })} onClick={onToContrast}>
                    {intl.get('Broker.ToCompare').d('去对比')}
                </span>
            </div>

            <div className={styles.clear_list}>
                <span onClick={onClearAll} className={c(styles.clear_btn, { [styles.clear_btn_dis]: !canContrast })}>
                    <span className={c('iconfont', 'icon-clear', styles.clear_icon)} />
                    <span className={styles.clear_text}>{intl.get('Broker.ClearTraders').d('清空全部交易商')}</span>
                </span>
            </div>
        </div>
    );
};

export default Contrast;
