import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef } from 'react';
import intl from 'react-intl-universal';
import type { Dayjs } from 'dayjs';

import { getActivityTimeGroup } from '@/services/activity';

import { CalendarItem } from '../type';

const useGetTime = (serviceData: {
    /** 列表数据 */
    list: CalendarItem[];
    /** 多语言 */
    locale: string;
}) => {
    const locale = intl.getInitOptions().currentLocale; // 多语言

    const isInit = useRef<boolean>(true); // 是否初始状态
    const isMult = useRef<boolean>(false); // 是否切换多语言

    const [currentMon, setCurrentMon] = React.useState<Dayjs>(() => {
        if ((serviceData.list || []).length === 0) {
            return dayjs();
        }
        return dayjs(serviceData.list[0]?.data || '');
    }); // 当前月份
    const [currentData, setCurrentData] = React.useState<Dayjs>(dayjs()); // 当前选中时间
    const [list, setList] = React.useState<CalendarItem[]>(serviceData.list); // 列表数据

    /** 切换月份 */
    const switchMon = (type: 'up' | 'down') => {
        if (type === 'up') {
            setCurrentMon(currentMon.add(1, 'month'));
        } else {
            setCurrentMon(currentMon.subtract(1, 'month'));
        }
    };

    useEffect(() => {
        // 初始时，不做请求，使用服务端数据
        if (isInit.current) {
            isInit.current = false;
            return;
        }
        // 切换多语言时，不做请求，使用服务端数据
        if (isMult.current) {
            isMult.current = false;
            return;
        }

        const mon = currentMon.daysInMonth(); // 总天数
        getActivityTimeGroup(
            {
                startTime: currentMon.format('YYYY-MM-01 00:00:00'),
                endTime: currentMon.format(`YYYY-MM-${mon} 23:59:59`),
            },
            serviceData.locale,
        ).then((res: any) => {
            if (res.state !== 1) {
                return;
            }
            setList(res.data);
        });
    }, [currentMon]);

    /** 离当前天最近的日期 */
    useEffect(() => {
        if (isInit.current) {
            isInit.current = false;
            return;
        }

        // 筛选当日与之后的数据
        const afterMon = serviceData.list.filter((item) => dayjs(item.data).diff(dayjs(), 'day') >= 0) || [];

        // 根据时间排序
        const sortMon = afterMon.sort((a, b) => dayjs(a.data).diff(b.data, 'day')) || [];

        // 取最小的时间
        const nearestDate = sortMon[0]?.data || '';

        // 设置当前选中的时间
        setCurrentData(dayjs(nearestDate));
    }, [serviceData.list, locale]);

    useEffect(() => {
        if (isInit.current) {
            isInit.current = false;
            return;
        }

        setList(serviceData.list);

        isMult.current = true; // 切换多语言
        if ((serviceData.list || []).length === 0) {
            setCurrentMon(dayjs());
            return;
        }
        setCurrentMon(dayjs(serviceData.list[0]?.data || ''));
    }, [serviceData.list, locale]);

    const activityList = useMemo(() => {
        return (
            list.filter((item) => {
                const date = dayjs(item.data);
                return date.isSame(currentData, 'day');
            })[0]?.activity || []
        );
    }, [currentData, list]);

    return {
        /** 当前月份 */
        currentMon,
        /** 当前时间 */
        currentData,
        /** 设置当前选中时间 */
        setCurrentData,
        /** 切换月份 */
        switchMon,
        /** 列表数据 */
        list,
        /** 活动列表 */
        activityList,
    };
};

export default useGetTime;
