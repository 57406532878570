import React, { useEffect, useMemo, useState } from 'react';

import TransferCom from '@/components/public/TransferCom';
import { type ArticleType } from '@/type/article';

import MTopic from './m';
import PcTopic from './pc';
import { type TopicRecommendProps } from '../../type';

const Topic: React.FC<TopicRecommendProps> = (props: TopicRecommendProps) => {
    const { customStyle, serviceData } = props;

    // 无数据直接返回空
    if (
        ((serviceData?.newData?.list || []).length || 0) === 0 &&
        ((serviceData?.hotData?.list || []).length || 0) === 0
    ) {
        return null;
    }
    // 当前显示页
    const [newList, setNewList] = useState(serviceData?.newData?.list || []); // 最新
    const [hotList, setHotList] = useState(serviceData?.hotData?.list || []); // 最热

    const [activeIndex, setActiveIndex] = useState<number>(0); // 当前坐标
    const [showType, setShowType] = useState<'new' | 'hot'>('new'); // 展示类别

    useEffect(() => {
        setNewList(serviceData?.newData?.list || []);
        setHotList(serviceData?.hotData?.list || []);
    }, [serviceData]);

    // 当前展示列表
    const currentList = useMemo(() => {
        switch (showType) {
            case 'new':
                return newList;
            case 'hot':
                return hotList;
        }
    }, [showType, newList, hotList]);

    /** 稿件列表 */
    const articleList: ArticleType[] = useMemo(() => {
        if (!currentList[activeIndex]) {
            return [];
        }
        return currentList[activeIndex].article_list.slice(0, 3);
    }, [activeIndex, currentList]);

    /** 是否展示最新 */
    const isShowNew = useMemo(() => newList.length > 0, [newList]);

    /** 是否展示最热 */
    const isShowHot = useMemo(() => hotList.length > 0, [hotList]);

    const normalPorps = {
        customStyle,
        isShowNew,
        isShowHot,
        showType,
        setShowType,
        setActiveIndex,
        currentList,
        articleList,
    };

    return <TransferCom m={<MTopic {...normalPorps} />} pc={<PcTopic {...normalPorps} />} />;
};

export default Topic;
