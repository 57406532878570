import React from 'react';
import { SwiperSlide } from 'swiper/react';
import c from 'classnames';

import { NextImage } from '@/components';
import { formatMts } from '@/utils';

import styles from './index.module.less';

function SwiperSlideItem({ showType, dataSource }: { showType: 'topic' | 'ugc'; dataSource: any }) {
    switch (showType) {
        case 'ugc':
            return (
                <>
                    <div className={styles.img_con}>
                        <NextImage src={dataSource.avatar} layout="fill" alt={dataSource?.name} place="ugc" />
                    </div>
                    <div className={styles.text_content}>{dataSource.name || ''}</div>
                </>
            );
        case 'topic':
        default:
            return (
                <>
                    <div className={styles.img_con}>
                        <NextImage
                            src={dataSource?.cover?.url}
                            place="normal_b"
                            layout="fill"
                            alt={dataSource?.title}
                        />
                    </div>
                    <div className={styles.text_content}>
                        {dataSource.title ? (
                            <div className={c(styles.swiperSlideTitleText)}>
                                {formatMts(dataSource.title) || dataSource.title}
                            </div>
                        ) : null}
                    </div>
                </>
            );
    }
}

export default SwiperSlideItem;
