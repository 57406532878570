import React, { useCallback, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Mousewheel, Navigation } from 'swiper';
import { chunk } from 'lodash';

import { formatDomUnit } from '@/utils';
import useCustomModal from '@/reconstruction/components/public/CustomModal';
import { NormalTitle, SwitchBtn } from '@/reconstruction/components/public';

import styles from './index.module.less';
import RenderItem from './RenderItem';
import type { VisualUgcProps } from '../../../type';

SwiperCore.use([Autoplay, Mousewheel, Navigation]);

const NormalStyle: React.FC<VisualUgcProps> = (props) => {
    const { customStyle, serviceData } = props || {};
    const [customModal, CustomModal] = useCustomModal(); // 弹窗
    const [normalSwiper, setNormalSwiper] = useState<any>(null); // 轮播图
    const [activeIndex, setActiveIndex] = useState<number>(0); // 当前下标

    const swiperList = useMemo(() => {
        const { list } = serviceData || {};
        return chunk(list || [], 4) || [];
    }, [serviceData]);

    /** 取消关注的弹窗 */
    const onUnfollowModal = useCallback(
        ({
            callbackFn,
            title,
            index,
        }: {
            /** 回调函数 */
            callbackFn: (index: number) => void;
            /** 标题 */
            title: string;
            /** 数据下标 */
            index: number;
        }) => {
            customModal.confirm({
                open: true,
                title,
                onOk: () => {
                    callbackFn(index);
                    customModal.close();
                },
            });
        },
        [customModal],
    );

    if (swiperList.length === 0) {
        return null;
    }

    /** 切换按钮 */
    const onSlideClick = (type: 'l' | 'r') => {
        switch (type) {
            case 'l':
                if (activeIndex !== 0) {
                    normalSwiper?.slideTo?.(activeIndex - 1);
                }
                break;
            case 'r':
                if (activeIndex !== swiperList.length - 1) {
                    normalSwiper?.slideTo?.(activeIndex + 1);
                }
                break;
        }
    };

    return (
        <>
            <div style={{ ...formatDomUnit(customStyle || {}) }}>
                <div className={styles.ugc}>
                    <div className={styles.ugc__header}>
                        <NormalTitle title={intl.get('Common.Author').d('创作者')} />
                        {swiperList.length > 1 && (
                            <SwitchBtn
                                activeIndex={activeIndex}
                                max={swiperList.length - 1}
                                onClickBtn={onSlideClick}
                            />
                        )}
                    </div>
                    <div className={styles.ugc__swiper}>
                        <Swiper
                            onSlideChange={(swiper) => {
                                setActiveIndex(swiper?.activeIndex);
                            }}
                            onSwiper={setNormalSwiper}
                            navigation={false}
                            loop={false}
                        >
                            {swiperList.map((item, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <RenderItem onUnfollowModal={onUnfollowModal} list={item} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
            {CustomModal}
        </>
    );
};

export default NormalStyle;
