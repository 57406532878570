import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { FirstSwiper, SecondSwiper, ThirdSwiper, FourthSwiper } from '@/reconstruction/components/public/CustomSwiper';
import { formatDomUnit, PAGE_CENTER_NEW } from '@/utils';
import { getUrl } from '@/utils/url';
import { FirstAdvertise } from '@/components/public/CustomAdvertise';
import { useAppSelector } from '@/store/hook';
import c from 'classnames';
import styles from './index.module.less';
import { SwiperProps } from '../type';
import { Affix } from 'antd';

const Swiper: React.FC<SwiperProps> = ({
    elementId,
    styleType,
    isSwitch,
    customStyle,
    interval = 5,
    visualSwiperList = [],
    visualSwiperList0 = [],
    visualSwiperList1 = [],
    visualSwiperList2 = [],
    visualSwiperList3 = [],
    visualSwiperList4 = [],
}) => {
    // 侧边广告高度
    const adHeight = 686;
    const minWidth = 32;

    // 中心区域宽度
    const { slideAd, topHeaderHeight } = useAppSelector((state: any) => state.config);

    const formList = useCallback(
        (l: any[]) => {
            return l.map((item, index) => {
                return { ...item, openUrl: getUrl(item.openInfo), key: index };
            });
        },
        [getUrl],
    );

    const swiperData = useMemo(() => {
        if (styleType === '2') {
            const fList = formList(visualSwiperList0);
            const sList = [
                {
                    key: 1,
                    list: formList(visualSwiperList1),
                },
                {
                    key: 2,
                    list: formList(visualSwiperList2),
                },
                {
                    key: 3,
                    list: formList(visualSwiperList3),
                },
                {
                    key: 4,
                    list: formList(visualSwiperList4),
                },
            ];

            return [fList, sList];
        } else {
            return formList(visualSwiperList);
        }
    }, [
        formList,
        styleType,
        visualSwiperList,
        visualSwiperList0,
        visualSwiperList1,
        visualSwiperList2,
        visualSwiperList3,
        visualSwiperList4,
    ]);

    const { height, heightUnit, paddingTop = 0, paddingBottom = 0 } = customStyle || {};

    const normalProps: any = {
        elementId,
        interval,
        customStyle,
        styleType,
        swiperList: swiperData,
    };

    // 广告位置
    // 侧边宽度
    const [sideWidth, setSideWidth] = useState<number>(0);
    const handleResize = () => {
        const windowWidth = window.innerWidth || document.documentElement.clientWidth;
        setSideWidth((windowWidth - PAGE_CENTER_NEW) / 2);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, [elementId, slideAd]);

    useEffect(() => {
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div
            style={{ ...formatDomUnit({ height: height + paddingTop + paddingBottom, heightUnit }) }}
            className={c(styles.swiperContainBox)}
            id={String(elementId)}
        >
            {
                {
                    // 默认
                    '1': <FirstSwiper {...normalProps} isSwitch={isSwitch} />,
                    // 多图
                    '2': <SecondSwiper {...normalProps} />,
                    // 超版心
                    '3': <ThirdSwiper {...normalProps} />,
                    // 视频
                    '4': <FourthSwiper {...normalProps} />,
                }[styleType]
            }

            {slideAd?.showAdvertising && slideAd?.elementId === elementId && sideWidth > minWidth && (
                <Affix offsetTop={topHeaderHeight}>
                    <aside
                        className={c(styles.aside, styles.left_aside)}
                        style={{ width: sideWidth, left: -sideWidth }}
                    >
                        <FirstAdvertise
                            elementId="leftAdvertisingCode"
                            adCode={slideAd.leftAdvertisingCode}
                            moreStyle={{ width: sideWidth, height: adHeight }}
                        />
                    </aside>
                </Affix>
            )}

            {slideAd?.showAdvertising && slideAd?.elementId === elementId && sideWidth > minWidth && (
                <Affix offsetTop={topHeaderHeight}>
                    <aside
                        className={c(styles.aside, styles.right_aside)}
                        style={{ width: sideWidth, right: -sideWidth }}
                    >
                        <FirstAdvertise
                            elementId="rightAdvertisingCode"
                            adCode={slideAd.rightAdvertisingCode}
                            moreStyle={{ width: sideWidth, height: adHeight }}
                        />
                    </aside>
                </Affix>
            )}
        </div>
    );
};

export default Swiper;
