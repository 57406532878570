import React from 'react'
import { InfiniteScroll } from '@/components'
import { formatDomUnit } from '@/utils'
import TopicList from './TopicList'
import styles from './index.module.less'

interface Props {
    customStyle?: any
    serviceData?: {
        list: any[]
        hasMore: boolean
    }
}

const Topic: React.FC<Props> = ({ customStyle, serviceData }) => {
    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }} className={styles.topicBox}>
            <InfiniteScroll
                api="/japi/topic/topic_list"
                initialLoad={false}
                serviceData={serviceData}
                renderList={(list) => <TopicList list={list} />}
            />
        </div>
    )
}

export default Topic