import React, { useContext, useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import c from 'classnames';

import { BrokerItemD, InfiniteScroll } from '@/reconstruction/components/public';
import { currentLocaleUrl } from '@/utils';

import styles from './index.module.less';
import { AllBrokerContext } from '../../context';

function BrokerList() {
    const currentLocale = intl.getInitOptions().currentLocale;
    const { serviceData, extraParams, sortData, sortActiveIndex, sortClickHandler } = useContext(AllBrokerContext);
    const InfiniteScrolleRef = useRef<any>(null);
    const isInit = useRef<boolean>(true);

    /** 重新请求 */
    useEffect(() => {
        if (isInit.current) {
            isInit.current = false;
            return;
        }
        InfiniteScrolleRef.current?.resetList();
    }, [extraParams]);

    const handleContrast = (record: any) => {
        if (!record.id) {
            return;
        }
        const _url: string = currentLocaleUrl(`/page/Pc_Barterer_Comparison?ids=${encodeURIComponent(record.id)}`);
        window.open(_url);
    };

    return (
        <div className={styles.main}>
            <div className={styles.sort}>
                {sortData.map((item, index) => {
                    const { value, label } = item;
                    const isActive = index === sortActiveIndex;
                    return (
                        <div
                            key={value}
                            onClick={() => sortClickHandler(index)}
                            className={c(styles.sort__item, { [styles.active]: isActive })}
                        >
                            {label}
                        </div>
                    );
                })}
            </div>
            <InfiniteScroll
                api="/japi/broker/switch/screen/list"
                method="post"
                onRef={InfiniteScrolleRef}
                serviceData={serviceData}
                extraParams={extraParams}
                noDataHeight={380}
                initialLoad={false}
                renderList={(list) => {
                    return (
                        <>
                            {list.map((item, index) => {
                                return (
                                    <div key={item.id} className={styles.list__item}>
                                        <BrokerItemD
                                            locale={currentLocale}
                                            onCompared={handleContrast}
                                            key={index}
                                            record={item}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    );
                }}
            />
        </div>
    );
}

export default BrokerList;
