import React, { useMemo, useState } from 'react';
import { formatDomUnit } from '@/utils';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import c from 'classnames';
import { useAppSelector } from '@/store/hook';
import { HomeColumnArticleList } from '@/reconstruction/components/listItem';
import { NextImage } from '@/components';
import { formatMts } from '@/utils/mts';
import { currentLocaleUrl } from '@/utils';
import { type TopicHomeProps } from '../../../type';

const MTopic: React.FC<TopicHomeProps> = ({ customStyle, serviceData }) => {
    // 当前显示页
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const { imgPrefix } = useAppSelector((state: any) => {
        return state.config;
    });
    const articleList = useMemo(() => {
        if (!serviceData?.list[activeIndex]) {
            return [];
        }
        return serviceData?.list[activeIndex].article_list;
    }, [activeIndex, serviceData]);

    if (!articleList.length) {
        return null;
    }
    return (
        <div style={{ ...formatDomUnit(customStyle || {}) }}>
            <div className={styles.topicBox}>
                <div className={styles.top_box}>
                    <div className={styles.wrapper__title}>
                        <em
                            className={styles.top_bg_icon}
                            style={{ backgroundImage: `url('${imgPrefix}/icon/badge.webp')` }}
                        />
                        <div className={styles.top_title}>{intl.get('Common.Column').d('专栏')}</div>
                    </div>
                    <a href={currentLocaleUrl('/topic/list')} className={styles.wrapper__more} target="_blank">
                        <span className={styles.text__more}>{intl.get('Common.SeeMore').d('查看更多')}</span>
                        <span className={c(styles.arrow__more, 'iconfont', 'icon-arrow-right')} />
                    </a>
                </div>
                <div className={styles.swiper_list}>
                    <div className={styles.swiper_box_item}>
                        {/* | 'fraction' | 'progressbar' | 'custom'; */}
                        <Swiper
                            pagination
                            onSlideChange={(swiper: SwiperCore) => {
                                setActiveIndex(swiper.activeIndex);
                            }}
                        >
                            {serviceData?.list.map((item: any, i: number) => {
                                const swiperItem = (
                                    <div className={styles.swiperSlideBox}>
                                        <div className={styles.img_con}>
                                            <NextImage
                                                src={item.cover.url}
                                                layout="fill"
                                                alt={item?.title}
                                                priority={i === 0}
                                            />
                                        </div>
                                        <div className={styles.text_content}>
                                            {item.title ? (
                                                <div className={c(styles.swiperSlideTitleText)}>
                                                    {formatMts(item.title) || item.title}
                                                </div>
                                            ) : null}
                                            {item.introduction ? (
                                                <div className={styles.introduction}>{item.introduction}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                );
                                return (
                                    <SwiperSlide className={c(styles.swiperSlide, styles.typeOne)} key={item._id}>
                                        <a
                                            href={currentLocaleUrl(`/topic/${item.seo_info?.seo_url || item._id}`)}
                                            target="_blank"
                                        >
                                            {swiperItem}
                                        </a>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>
                <div className={styles.article_list}>
                    <HomeColumnArticleList options={articleList} />
                </div>
            </div>
        </div>
    );
};

export default MTopic;
