import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import { ColBoxProp } from './type';
import PCColBox from './pc';
import MColBox from './m';

const ColBox: React.FC<ColBoxProp> = (props) => {
    return <TransferCom pc={<PCColBox {...props} />} m={<MColBox {...props} />} ipad={null} />;
};

export default ColBox;
