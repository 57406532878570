import React from 'react';

import TransferCom from '@/components/public/TransferCom';

import PCSwiper from './pc';
import MSwiper from '../MSwiper';
import type { SwiperProps } from '../types';

const ThirdSwiper: React.FC<SwiperProps> = (props) => {
    return <TransferCom pc={<PCSwiper {...props} />} m={<MSwiper {...props} />} ipad={<PCSwiper {...props} />} />;
};

export default ThirdSwiper;
