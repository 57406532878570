import React from 'react';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import { ActivityListItem } from '@/reconstruction/components/listItem';

interface Props {
    list: any[];
}

const PcActivitys: React.FC<Props> = ({ list }) => {
    return (
        <div className={styles.list}>
            <div className={styles.listRow}>
                {list.map((item) => {
                    return (
                        <div key={item.id} className={styles.listCol}>
                            <ActivityListItem dataSource={item} />
                        </div>
                    );
                })}
            </div>
            {list.length < 5 && (
                <div className={styles.wrapper__noMore}>
                    <em />
                    <span>{intl.get('Common.MoreLostContent').d('没有更多内容')}</span>
                    <em />
                </div>
            )}
        </div>
    );
};

export default PcActivitys;
