import React, { useContext } from 'react';

import type { BrokerFilterType } from '@/type/broker';

import FilterSlider from './TypeItem/Slider';
import FilterCheckbox from './TypeItem/Checkbox';
import { AllBrokerContext } from '../../context';
import { defaultFilterValues, mExtraFilterValues, otherFilterValues } from '../../../config';

const FilterItemList = () => {
    const { filterList } = useContext(AllBrokerContext);

    const RenderItem = (value: string | string[], type: 'checkbox' | 'slider' | 'other' | 'country') => {
        let dataSource;
        switch (type) {
            case 'checkbox':
            case 'country':
            case 'slider':
                dataSource = filterList.find((item) => item.value === value);
                break;
            case 'other':
                dataSource = filterList.filter((item) => {
                    return item.value && value.includes(item.value);
                });
        }

        if (!dataSource) {
            return;
        }

        switch (type) {
            case 'checkbox':
            case 'other':
            case 'country':
                return <FilterCheckbox dataSource={dataSource} type={type} />;
            case 'slider':
                return <FilterSlider dataSource={dataSource as BrokerFilterType} />;
        }
    };

    return (
        <div
            style={{
                paddingBottom: '84px',
            }}
        >
            {[...defaultFilterValues, ...mExtraFilterValues].map((item, index) => {
                return <div key={index}>{RenderItem(item.value, item.type)}</div>;
            })}
            {RenderItem(otherFilterValues, 'other')}
        </div>
    );
};

export default FilterItemList;
